import React,{useState,useEffect} from 'react'
import {Form,FloatingLabel,Col, Button} from 'react-bootstrap'
import SelectSearch from 'react-select-search'



export default function VigenciaRJ({options,mayus,justNumbers,editing,solicitudes,guardarSolicitud}) {
    const [tipoVigencia, setTipoVigencia] = useState("VRJ");
    const [oficinaReg, setOficinaReg] = useState("");
    const [partida, setPartida] = useState("");
    const [asiento, setAsiento] = useState("");
    const [asientos, setAsientos] = useState([]);
    const [cargo, setCargo] = useState("APODERADO");
    const [nombre, setNombre] = useState("");
    const [apellidoP, setApellidoP] = useState("");
    const [apellidoM, setApellidoM] = useState("");

    const [isVibrating, setIsVibrating] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
          setIsVibrating((prev) => !prev); // Alternar entre verdadero y falso
        },1500); // Cambia cada 5 segundos
    
        return () => {
          clearInterval(intervalId); // Limpia el temporizador cuando el componente se desmonta
        };
      }, []);

    useEffect(()=>{        
        if(editing.onEdit){
            const solicitud = editing.item
            setOficinaReg(solicitud.oficinaReg)
            setPartida(solicitud.partida)
            setAsientos(solicitud.asientos)
            setCargo(solicitud.cargo)
            setNombre(solicitud.representante.nombre)
            setApellidoM(solicitud.representante.apellidoM)
            setApellidoP(solicitud.representante.apellidoP)         
        }
      },[editing])

    function cleanForm(){
        setOficinaReg("")
        setPartida("")
        setAsiento("")
        setAsientos([])
        setCargo("APODERADO")
        setNombre("")
        setApellidoM("")
        setApellidoP("")
    }

    function handleGuardar(){
        if(editing.onEdit){
            const updateIndex = solicitudes.findIndex(sol=>sol===editing.item)
            const newData = solicitudes[updateIndex]
            let newArray = [...solicitudes]            
            
            newData.oficinaReg = oficinaReg
            newData.partida = partida.toLocaleUpperCase()
            newData.asientos = asientos
            newData.representante = 
                {
                    nombre:nombre.toLocaleUpperCase(),
                    apellidoP:apellidoP.toLocaleUpperCase(),
                    apellidoM:apellidoM.toLocaleUpperCase()
                }
            newArray[updateIndex] = newData

            guardarSolicitud(newArray)
            cleanForm()
        }else{
            const solicitud ={
                tipo:"VP",
                tipoVigencia,
                oficinaReg,
                partida:partida.toLocaleUpperCase(),
                asientos,
                cargo:cargo.toLocaleUpperCase(),
                representante:{
                    nombre:nombre.toLocaleUpperCase(),
                    apellidoP:apellidoP.toLocaleUpperCase(),
                    apellidoM:apellidoM.toLocaleUpperCase()
                }
            }
            guardarSolicitud((prevLog)=>[...prevLog,solicitud])
            cleanForm()
        }
        
    }

    function añadirAsiento(){
        const existeAsiento =  asientos.filter(item=>item===asiento).length!==0        
        if(asiento===""){
            alert("Agrege el numero de asiento")
        }else if(existeAsiento){
            alert("El asiento ya es incluido en la solicitud")
        }else{
            setAsientos((prevLog)=>[...prevLog,asiento.toLocaleUpperCase()])
            setAsiento("")
        }        
    }

    function eliminarAsiento(asientoB){
        const newAsientos = asientos.filter(item=>item!==asientoB)
        setAsientos(newAsientos)
    }

   

  return (
    <>
        <Col sm={12} md={12} lg={12}>
            <FloatingLabel label="Tipo de Vigencia de Poder">
                <Form.Select value={tipoVigencia} onChange={value=>setTipoVigencia(value)} disabled placeholder='Tipo de Vigencia de Poder'>
                    <option value=''>Seleccionar</option>
                    <option value='VRJ'>Representante de persona Jurídica</option>
                </Form.Select>
            </FloatingLabel>
        </Col>
        <Col sm={12} md={12} lg={12}>
            <SelectSearch  
                options={options}
                placeholder='Oficina Registral' 
                value={oficinaReg}
                onChange={(value)=>{
                    setOficinaReg(value)                        
                }} 
            />
        </Col>
        <Col sm={12} md={12} lg={12}>
            <FloatingLabel label="Partida" className='p-0'>
                <Form.Control maxLength={9} value={partida} className='uppercase-input' onChange={e=>{setPartida(e.target.value)}} placeholder='Número de Partida'/>
            </FloatingLabel>
        </Col>
        <Col sm={10} md={10} lg={10}>
            <FloatingLabel maxLength={8} label="Asiento" className='p-0'>
                <Form.Control value={asiento} className='uppercase-input' onChange={e=>{setAsiento(e.target.value)}} placeholder='Número de Asiento'/>
            </FloatingLabel>
        </Col>
        <Col sm={2} md={2} lg={2}>
            <Button variant='white' className={`red-button ${asiento!==""&&isVibrating?'vibrate-normal':''}`} style={{width:"100%",height:"100%",fontSize:"1.3rem"}} onClick={()=>añadirAsiento()}>+</Button>
        </Col>
        <Col sm={12} md={12} lg={12}>
            
            {asientos.length>0?<table className='table table-hover' style={{fontSize:".8rem"}}>
                <thead>
                    <tr><th>Asientos añadidos</th><th/></tr>
                </thead>
                <tbody>
                {asientos.map(asiento=>
                    
                    <tr key={asiento} style={{width:"100%"}}>
                        <td style={{width:"95%"}}>{asiento}</td>
                        <td style={{width:"5%"}}><div className='close-button' onClick={()=>eliminarAsiento(asiento)}>x</div></td>                    
                    </tr>
                )}
                </tbody>    
            </table>:null}
            <FloatingLabel label="Cargo" className='p-0'>
                <Form.Control value={cargo} className='uppercase-input' onChange={e=>{setCargo(e.target.value)}} placeholder='Cargo'/>
            </FloatingLabel>            
        </Col>
        
        <div className='StolzlMedium mt-3'>Representante</div>
        <Col sm={12} md={12} lg={12}>
            <FloatingLabel label="Nombre" className='p-0'>
                <Form.Control value={nombre} className='uppercase-input' onChange={e=>{setNombre(e.target.value)}} placeholder='Nombre'/>
            </FloatingLabel>            
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
            <FloatingLabel label="Apellido Paterno" className='p-0'>
                <Form.Control value={apellidoP} className='uppercase-input' onChange={e=>{setApellidoP(e.target.value)}} placeholder='Apellido Paterno'/>
            </FloatingLabel>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
            <FloatingLabel label="Apellido Materno" className='p-0'>
                <Form.Control value={apellidoM} className='uppercase-input' onChange={e=>{setApellidoM(e.target.value)}} placeholder='Apellido Materno'/>
            </FloatingLabel>
        </Col>
        <Col className='d-flex justify-content-center'>
            <Button 
                variant='light' 
                className='blue-button StolzlMedium'
                disabled={!(oficinaReg!==""&&partida!==""&&asientos.length!==0&&cargo!==""&&nombre!==""&&apellidoP!==""&&apellidoM!=="")}
                onClick={()=>{handleGuardar()}}>{`${editing.onEdit?"Actulizar":`Añadir ${solicitudes.length>0?"otra":""}`} Partida`}</Button>
        </Col>  
    </>
  )
}
