import React from 'react'
import {Accordion,Card} from 'react-bootstrap'

export default function HistorialCollapse({id,poderdantes,apoderados,facultades}) {
  return (
    <Accordion.Collapse eventKey={id}>
        <Card.Body className="StolzlMedium">                                
            <div style={{position:"relative",bottom:"0.2rem",backgroundColor:"white",padding:"1rem" ,margin:"-1rem",borderLeft:"solid 1px lightgray",borderRight:"solid 1px lightgray", borderBottom:"solid 1px lightgray"}}>
                <div style={{display:"flex",justifyContent:"space-between",marginBottom:".4rem"}}>
                    <div>Poderdante:</div><div style={{color:"darkslategray"}}>{poderdantes}</div> 
                </div>
                <div style={{display:"flex",justifyContent:"space-between",marginBottom:".4rem"}}>
                    <div>Apoderado:</div><div style={{color:"darkslategray"}}>{apoderados}</div> 
                </div>
                <div>                                        
                    <div>Facultades:</div>                                        
                </div>
                <div style={{color:"darkslategray"}}>
                    {facultades.map((f,index)=>{return(<div key={`fac${index}`} style={{textAlign:"end"}}>{f}</div>)})}
                </div>
            </div>                            
        </Card.Body>
    </Accordion.Collapse>
  )
}
