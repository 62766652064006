import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import ErrorHandler from './components/errorHandler'
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <ErrorHandler> */}      
        <App/>
      {/* </ErrorHandler> */}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);


