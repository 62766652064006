import React,{useState,useEffect} from 'react'
import {Form,FloatingLabel,Col, Button} from 'react-bootstrap'
import SelectSearch from 'react-select-search'



export default function CopiaLiteral({options,mayus,editing,solicitudes,guardarSolicitud}) {
    const [tipoCopia, setTipoCopia] = useState("21000");
    const [servicio, setServicio] = useState("");
    const [oficinaReg, setOficinaReg] = useState("");
    const [partida, setPartida] = useState("");

    const [allPages, setAllPages] = useState("todas");   

    const [pini, setPini] = useState(0);
    const [pfin, setPfin] = useState(100);
   
    useEffect(()=>{
      if(editing.onEdit){
        const solicitud = editing.item

        if(solicitud.paginas==="*"){
          setAllPages("todas")  
        }else{
          setAllPages("rango")
          setPini(solicitud.paginas.inicio)
          setPfin(solicitud.paginas.fin)
        }
        
        setOficinaReg(solicitud.oficinaReg)
        setPartida(solicitud.partida)
        setTipoCopia(solicitud.tipoCopia)
        setServicio(solicitud.servicio)
      }
    },[editing])

  
    

      function cleanForm(){
        setAllPages("todas")
        setPini("")
        setPfin("")
        setOficinaReg("")
        setPartida("")
        setTipoCopia("")
        setServicio("")
      }

      async function handleGuardar(){
        if(allPages==="rango"&&pini>=pfin){         
          alert("La página final debe ser mayor a la inicial")
        }else{  
             
          if(editing.onEdit){
            const updateIndex = solicitudes.findIndex(sol=>sol===editing.item)
            const newData = solicitudes[updateIndex]
            let newArray = [...solicitudes]            
            
            newData.tipoCopia = tipoCopia
            newData.servicio = servicio
            newData.oficinaReg = oficinaReg
            newData.partida = partida.trim() 
            newData.paginas = allPages==="todas"?"*":{inicio:pini,fin:pfin}

            newArray[updateIndex] = newData
            
            guardarSolicitud(newArray)            
            cleanForm()
          }
          else{
            const solicitud = {
                tipo:"CL",
                servicio,
                tipoCopia,
                oficinaReg,
                partida:partida.trim(),
                paginas:allPages==="todas"?"*":{inicio:pini,fin:pfin}
            }
            guardarSolicitud((PrevLog)=>[...PrevLog,solicitud])            
            cleanForm()
          }
        }                       
      }


  return (
    <>
      <Col md={12} lg={12}>
        <FloatingLabel label="Tipo de Copia Literal" className='p-0'>
            <Form.Select value={tipoCopia}  onChange={(e)=>setTipoCopia(e.target.value)}>
                <option value="">Seleccionar</option>
                <option value={"21000"}>Registro de Propiedad Inmueble</option>
                <option value={"22000"}>Registro de Persona Juridicas</option>
                {/* <option value={"23000"}>Registro de Personas Naturales</option>            
                <option value={"24000"}>Registro de Bienes Muebles</option>*/}
            </Form.Select>
        </FloatingLabel>
      </Col>
      <Col md={12} lg={12}>
        {tipoCopia==="21000"?<FloatingLabel label="Servicio" className='p-0'>
          <Form.Select value={servicio} onChange={(e)=>setServicio(e.target.value)}>
                <option value="">Seleccionar</option>
                <option value="predios">Predios</option>
                <option value="mineria">Mineria</option>
                <option value="aeronaves">Aeronaves</option>
                <option value="embarcaciones_pesqueras">Embarcaciones Pesqueras</option>
                <option value="naves">Naves</option>
                <option value="sarp">SARP</option>                
          </Form.Select>         
        </FloatingLabel>:null}
        {tipoCopia==="22000"?<FloatingLabel label="Servicio" className='p-0'>
          <Form.Select value={servicio} onChange={(e)=>setServicio(e.target.value)}>
                <option value="">Seleccionar</option>
                <option value="certificado_literal_partida_pj ">Fir. Digital-Cert. Literal-PJ</option>                                
          </Form.Select>         
        </FloatingLabel>:null}

      </Col>     
      <Col xs={12} sm={6} md={6} lg={6}>
        <SelectSearch  
            options={options}
            placeholder='Oficina Registral' 
            value={oficinaReg}
            onChange={(value)=>{
                setOficinaReg(value)                        
            }} 
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={6}>
        <FloatingLabel label="Partida" className='p-0'>
            <Form.Control maxLength={9} value={partida} onInput={mayus} onChange={e=>{setPartida(e.target.value)}} placeholder='Partida'/>
        </FloatingLabel>
      </Col>
      
      <Col sm={12} md={12} lg={12}>
        <FloatingLabel label="Páginas" className='p-0 d-none'>
            <Form.Select value={allPages} disabled onChange={e=>setAllPages(e.target.value)}>
                <option value="todas">Todas las Páginas</option>
                <option value="rango">Rango de Páginas</option>
            </Form.Select>
        </FloatingLabel>
      </Col>   
      {allPages==="rango"?
      <>
      <Col sm={12} md={6} lg={6}>        
        <FloatingLabel label="Página Inicial" className='p-0'>
            <Form.Control type="number" value={pini}  onChange={e=>setPini(parseInt(e.target.value))} placeholder='Rango'/>
        </FloatingLabel>
      </Col>
      <Col sm={12} md={6} lg={6}>        
        <FloatingLabel label="Página Final" className='p-0'>
            <Form.Control type="number" value={pfin}  onChange={e=>setPfin(parseInt(e.target.value))} placeholder='Rango'/>
        </FloatingLabel>
      </Col>
      </>
      :null}
        <Col className='d-flex justify-content-center'>            
            <Button 
                variant='light' 
                className='blue-button StolzlMedium' 
                disabled={!(tipoCopia!==""&&oficinaReg!==""&&partida!==""&&servicio!==""&&(allPages==="todas"||(allPages==="rango"&&pini!==""&&pfin!=="")))}
                onClick={()=>handleGuardar()}>{`${editing.onEdit?"Actualizar":"Añadir"} ${solicitudes.length>0&&!editing.onEdit?"otra":""} partida`}</Button>            
        </Col>
    </>
  )
}
