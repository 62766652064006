import React,{useState,useEffect} from 'react'
import {TiInfo} from 'react-icons/ti'
import {BsBarChartSteps} from 'react-icons/bs'

//Utils
import customAlert from './alert'

export default function InsSteps({list}) {
  const  color = ["#FF5C5C","#5CA6FF","#ffbe5c","#a5cc8b","#ff5c9d","#ff6c5c","#FFC049"]
  const [listOpen, setListOpen] = useState(true) 
  const [animated,setAnimated] = useState(false) 
    
    function handleSmallSize(Width){
        if(Width<450){
            setListOpen(false)
        }else{
            setListOpen(true)    
        }
    }

    useEffect(()=>{
        window.addEventListener('resize',handleSmallSize(window.innerWidth))
        return ()=> window.removeEventListener('resize',()=>handleSmallSize(window.innerWidth))
    },[])

  let count = 0
  return (
    <>
    <div
        onAnimationStart={()=>setAnimated(true)}
        onAnimationEnd={()=>setAnimated(false)} 
        className={`customBoxShadow serviceStepsContainer ${listOpen?"fade-in":"fade-out"}`} 
        style={listOpen?{display:"block",maxWidth:"90%"}:!animated?{display:"none"}:null}
    >
        <div className='StolzlMedium'>Secuencia del Servicio:</div>
        {
            list.map(item=>{
                count += 1
                return(
                <div key={`step${count}`} className='serviceStepsBorder' style={{borderLeft: `0.25rem solid ${color[count-1]}`,borderBottom:`0.25rem solid ${color[count-1]}`}}>           
                    <div>
                        <div className='serviceSteps StolzlMedium' style={{marginTop:"-0.1rem",backgroundColor:color[count-1]}}>{count}</div>
                    </div>
                    <div style={{display:"flex"}}>                        
                        <div style={{marginLeft: "1rem"}}>{item.step}</div>
                        {item.xtraInfo?<div className='xtraInfo' onClick={()=>{customAlert(undefined,"Información",item.xtraInfo)}}><TiInfo/></div>:null}
                    </div>
                </div>)
            })
        }        
    </div>
    <div className={listOpen?'stepButtom':'stepButtom stepButtomGlow'} onClick={()=>setListOpen(!listOpen)}><BsBarChartSteps/></div>
    </>
  )
}
