import React,{useState,useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'

//Components
import MyPersonas from './myPersonas'

//Utils
import {getUrlApi} from '../../Utils/getUrl&Key'
import customAlert from '../../Utils/alert'

export default function Myuser() {
    const history = useHistory()
    const [userName,setUserName] = useState("")
    const [nombre, setNombre] = useState("");
    const [apellidom, setApellidom] = useState("");
    const [apellidop, setApellidop] = useState("");
    const [onEdit, setOnEdit] = useState(false);
    
    const usuario = JSON.parse(localStorage.getItem("App.login"))   

    useEffect(() => {                    
        getUserData()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getUserData(){
        const Url = getUrlApi() + `/users/perfil/${usuario.id}`
        try{
            await Axios.get(Url).then(res => {
                if (res.status===200){
                    setNombre(res.data.nombre)
                    setApellidop(res.data.apellidoPaterno)
                    setApellidom(res.data.apellidoMaterno)
                    setUserName(res.data.nombre + " " + res.data.apellidoPaterno )
                }
            })
        }
        catch(error){
            if(error.response){
                alert(error.response.data.message)
            }
        }
    }

    
    async function saveUserData(){
        const Url = getUrlApi() + `/users/perfil`
        let data = {
            "userid":usuario.id,
            "nombre":nombre,
            "apellidopaterno":apellidop,
            "apellidomaterno":apellidom,            
        }        
        try{
            await Axios.post(Url,data).then(res =>{
                if(res.status===200||res.status===201){                    
                    customAlert(()=>{window.location.reload(false)},"Datos Actualizados",res.data.message)                    
                }
            })
        }
        catch(error){}
    }

    

  return (
    <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
        <div style={{width:"30rem",maxWidth:"95%"}}>
            <div className="historywelcome StolzlMedium">            
                <div style={{display:"flex"}} >
                    <div style={{marginRight:"0.5rem"}}>¡Hola</div><div style={{color:"rgb(176,32,24)"}}>{userName===""?usuario.username:userName}</div>!                    
                </div>
                <div style={{fontSize:"1rem"}}>
                    Bienvenido al Panel de Usuario de tu cuenta tramitec.
                </div>                        
            </div>           
            <div className='whiteCard StolzlMedium'>
                <div>Informacion Personal:</div>
                {!onEdit?
                <div>
                    <div style={{margin:"1rem 0",fontSize:".9rem",color:"darkslategray"}}>
                        <div style={{display:"flex",justifyContent:"space-between"}}><div>Nombre(s):</div><div>{nombre}</div></div>
                        <div style={{display:"flex",justifyContent:"space-between"}}><div>Apellido(s):</div><div>{`${apellidop} ${apellidom}`} </div></div>                    
                        <div style={{display:"flex",justifyContent:"space-between"}}><div>Correo Asociado:</div><div>{usuario.username}</div></div>
                    </div>
                    <button className='btn green-button' onClick={()=>setOnEdit(true)}>Actualizar Datos</button>
                </div>:
                <div style={{margin:"1rem 0"}}>
                    <div className="form-floating">
                        <input 
                        type='text' 
                        className="form-control mb-3 uppercase-input" 
                        id="floatingInput" name="nombre" 
                        placeholder="Nombres"
                        onChange={e=>setNombre(e.target.value)}                        
                        value={nombre}                        
                        />
                        <label htmlFor="floatingInput">Nombres</label>
                    </div>

                    <div className="form-floating">
                        <input 
                            type='text' 
                            className="form-control mb-3 uppercase-input" 
                            id="floatingInput" name="apellidop" 
                            placeholder="Apellido Paterno"
                            onChange={e=>setApellidop(e.target.value)}                            
                            value={apellidop}                            
                        />
                        <label htmlFor="floatingInput">Apellido Paterno</label>
                    </div>

                    <div className="form-floating">
                        <input 
                            type='text' 
                            className="form-control mb-3 uppercase-input" 
                            id="floatingInput" 
                            name="apellidom" 
                            placeholder="Apellido Materno"
                            onChange={e=>setApellidom(e.target.value)}                    
                            value={apellidom}                    
                        />
                        <label htmlFor="floatingInput">Apellido Materno</label>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-around"}}>
                        <button className='btn red-button' onClick={()=>setOnEdit(false)}>Cancelar</button>
                        <button className='btn green-button' onClick={()=>saveUserData()}>Guardar</button>
                    </div>
                </div>}
            </div>
            <div className='whiteCard StolzlMedium' >
                <div>Historial de Tramites:</div>                
                <div style={{display:"flex", justifyContent:"center", marginTop:"1rem"}}>                    
                    <button className='btn red-button' onClick={()=>history.push({pathname:"/main/usuario/historial"})}>Ver Historial</button>
                </div>
            </div>
            <MyPersonas/>            
        </div>
    </div>
  )
}
