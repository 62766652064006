import React,{useState,useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'
import {useStep} from "react-hooks-helper";

import Seleccion from './adminSeleccion'
import Bandeja from './bandejasMain'
import Blogger from './bloggerMain'
import TestForm from './testForm'
import Pagos from './pagos'

//Utils
import {getUrlApi} from '../../Utils/getUrl&Key'

//
import {Nav, NavDropdown, NavLink} from 'react-bootstrap'
import {MdDoubleArrow} from 'react-icons/md'



const steps = [
    { id: "seleccion" },
    { id: "inscripcion" },
    { id: "tramites" },
    { id: "apostillas"},
    { id: "blogger" },
    { id: "pagos"},
    { id: "testform"}    
];


export default function AdminPage() {
  const history = useHistory()

  const [inscripciones, setInscripciones] = useState([])
  const [tramites, setTramites] = useState([])
  const [apostillas, setApostillas] = useState([])
  const [datosPagos, setDatosPagos] = useState([]);

  const [update, setUpdate] = useState(0)
  const [showMenu, setShowMenu] = useState(true);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const header = {
    "Content-Type":"application/json",
    "authorization": JSON.parse(localStorage.getItem("App.login")).token
  }

  /* const autoReload = () =>{    
    setTimeout(()=>{
      setUpdate(update+1)
      autoReload()
    },60000)
  } */

  const unauthorized = () =>{
    const user = JSON.parse(localStorage.getItem("App.login"))
    if(!user){
      alert("Usuario No Autorizado")
      history.replace("/")
    }else{
      if(!user.rol){
        alert("Usuario No Autorizado")
        history.replace("/")  
      }
    }

  }

  useEffect(()=>{
    /* autoReload() */
    const updateInterval = setInterval(()=>{setUpdate(update+1)},60000)
    unauthorized()
    return ()=>{
      clearInterval(updateInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    getBanInscripciones()
    getBanTramites()
    getBanApostillas()
    getListaPagos()
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  },[update])

  async function getBanInscripciones(){    
    const Url = getUrlApi() + "/inscripcion"    
    try{
    await Axios.get(Url,{headers:header}).then(res=>{      
      setInscripciones(res.data)
    })
    }catch(error){
      if(error.response){
        /* console.log(error.response) */
      }
    }
  }

  async function getBanTramites(){
    
    const Url = getUrlApi() + "/tramitacion"    
    try{
      await Axios.get(Url,{headers:header}).then(res=>{        
        setTramites(res.data)
      })
    }catch(error){
      const response = error.response
      if(response){        
        if(response.status===403){
           alert("Su sesion ha caducado")
           history.replace("/logout")
        }
      }
    }
  }

  async function getBanApostillas(){    
    const Url = getUrlApi() + "/apostilla"
    try{
      await Axios.get(Url,{headers:header}).then(res=>{
               
        setApostillas(res.data)
      })
    }catch(error){
      const response = error.response
      if(response){
        if(response.status===403){
          alert("Su sesion ha caducado")
          history.replace("/logout")
        }
      }
    }
  }

  const getListaPagos = async () =>{
    const url = getUrlApi() + '/common/getpagos'
    Axios.get(url).then(res=>{        
        setDatosPagos(res.data)
    })
}

  const handleMenurender = (id)=>{
    switch (id) {
      case "seleccion":          
        return <Seleccion navigation={navigation} />;
      case "inscripcion":          
        return <Bandeja navigation={navigation} title='Inscripciones' data={inscripciones} update={update} setUpdate={setUpdate}/>;
      case "tramites":          
        return <Bandeja navigation={navigation} title='Trámites' data={tramites} update={update} setUpdate={setUpdate}/>;
      case "apostillas":
        return <Bandeja navigation={navigation} title='Apostillas' data={apostillas} update={update} setUpdate={setUpdate}/>
      case "blogger":
        return <Blogger/>
      case "pagos":
        return <Pagos datosPagos={datosPagos}/>
      case "testform":
        return <TestForm/>
      default:
        return <div>Un error ha ocurrido</div>        
    } 
  }

  return (
    <div style={{display:"flex",height:"max-content"}}>
          {showMenu?
          <div style={{width:"10rem",height:"calc(100vh - 3.625rem)",position:"fixed",top:"3.625rem",backgroundColor:"var(--red-Color)",zIndex:"1"}}>
              <div className='d-flex justify-content-end p-2'>
                <button className='btn-close' onClick={()=>setShowMenu(false)}/>
              </div>
              <h2 style={{color:"white"}}>Menu</h2>
              <Nav>
                <NavDropdown title="Bandejas">
                  <NavDropdown.Item onClick={()=>{
                    navigation.go(1)
                    setShowMenu(false)
                    }}>Inscripciones</NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>{
                    navigation.go(2)
                    setShowMenu(false)
                    }}>Trámites</NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>{
                    navigation.go(3)
                    setShowMenu(false)
                    }}>Apostillas</NavDropdown.Item>  
                </NavDropdown>                
              </Nav>
              <Nav>
                <NavLink onClick={()=>{
                  navigation.go(4)
                  setShowMenu(false)
                  }}>Blogger</NavLink>
              </Nav>
              <Nav>
                <NavLink onClick={()=>{
                  navigation.go(5)
                  setShowMenu(false)
                  }}>Pagos</NavLink>
              </Nav>
              {/* <Nav>
                <NavLink onClick={()=>{
                    navigation.go(6)
                    setShowMenu(false)
                    }}>Test</NavLink>
              </Nav> */}
          </div>
          :<div className='menuShowButton' onClick={()=>setShowMenu(true)}><MdDoubleArrow className='msbIncon'/></div>}               
          {
            handleMenurender(step.id)
          }           
    </div>
  )
        
         
}
