import React,{useState,useEffect} from 'react'
import {useHistory,useLocation} from 'react-router-dom'
import {useStep} from "react-hooks-helper";

//Components
import LoginOptions from './loginoptions'
import LoginTramitec from './logintramitec'

//Assets
import Logo from "../../Assets/Logos/ColorfulLogo.svg"
import Patterm from '../../Assets/BackgroundImages/WhitePattermLarge.svg'

const steps =[
    {id: "options"},
    {id: "tramitec"}
]


const SESSION_KEY = "App.login"     
export default function Login() {
    const history = useHistory()
    const location = useLocation()
    const redirectCode = location.pathname.split("r==")[1]
    const [redirect, setRedirect] = useState("")

    useEffect(()=>{       
        if(location.state){            
            navigation.go(location.state.step)
        }
        if(redirectCode){
            let buff = new Buffer.from(redirectCode,'base64')
            let rString = buff.toString('ascii')
            setRedirect(rString)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const { step, navigation } = useStep({
        steps,
        initialStep: 0,    
    });

    function renderlogins(){
        
        switch (step.id) {
            case "options":                
                return <LoginOptions navigation={navigation} SESSION_KEY={SESSION_KEY}/>;
            case "tramitec":                
                return <LoginTramitec navigation={navigation} SESSION_KEY={SESSION_KEY} redirect={redirect}/>;
            default:
                return <div>Ha ocurrido una error</div>
        }
    }

    return (
        <div className='outerContainer'>            
            <div className="shadow rounded-1_5rem loginContainer fade-in">
                <div style={{textAlign:"center",cursor:"pointer"}} className="mb-4">
                    <img className="loginLogo" src={Logo} alt="logo" onClick={
                        ()=>{
                            history.push({pathname:"/"})
                        }
                    }></img>
                </div>        
                {renderlogins()}

            </div>
            <div style={{
                position:"fixed",
                top:"0",
                right: "0",
                zIndex: "1"}}
                className = "fade-in"
            >
                <img 
                    src={Patterm}
                    alt="background_patter"                    
                    style={{
                        height:"100vh",
                        opacity:"0.4"
                    }}
                ></img>
            </div>    
        </div>
    )
}
