export function checkmail(val){
    let mailvalido 
    let pArroba = val.lastIndexOf('@')
    let pPunto = val.lastIndexOf(".")
    if (!(pArroba < pPunto && pArroba > 0 && val.indexOf('@@') === -1 && pPunto > 2 && (val.length - pPunto) > 2)) {            
        mailvalido = false;
    }
    else{
        mailvalido = true
    }
    return mailvalido    
}