import ReactDOM from 'react-dom'
import React from 'react'
import {Alert, Button,Row,Col} from 'react-bootstrap'

export default function userLocation() {    
    if(!sessionStorage.getItem("App.userLocation")&&window.location.pathname!=="/PaginaNoEncontrada"){
    const parentElement = document.getElementById("App")
    const container = document.createElement("div")

    container.className = "customAlertContainer"    
    parentElement.appendChild(container)
 
    function handleLocal(){
        sessionStorage.setItem("App.userLocation","PER")
        handleClose()
        window.location.reload()
    }

    function handleGlobal(){
        sessionStorage.setItem("App.userLocation","USA")
        handleClose()
        window.location.reload()
        
    }

    function handleClose(){
        ReactDOM.unmountComponentAtNode(container)
        container.parentNode.removeChild(container)        
    }

    return ReactDOM.render(
        <Alert variant="light" className="customAlert mx-auto"  onClose={()=>handleClose()}>
            <Alert.Heading>¿Cuál es su ubicación?</Alert.Heading>
            <p style={{textAlign:"justify",fontSize:"calc(0.9vw + 0.35rem)"}}>
                Para un servicio más personalizado necesitamos conocer dónde se encuentra, 
                por favor haga clic en una de las siguientes opciones:
            </p>
            <hr/>
            <Row className="justify-content-center">
                <Col xs={12} md={6} className='d-flex justify-content-center p-1'>
                    <Button variant="outline-danger" onClick={()=>handleLocal()}>En Perú</Button>
                </Col>
                <Col xs={12} md={6} className='d-flex justify-content-center p-1'>
                    <Button variant='outline-info' onClick={()=>handleGlobal()}>Fuera de Perú</Button>
                </Col>
            </Row>
        </Alert>,container
    )
    }
    else {        
        if(!sessionStorage.getItem("App.userLocation")){
            setTimeout(()=>{
                window.location.reload()
            },4000)            
        }        
        return null
    }
}
