import React,{useState,useEffect} from 'react'
import {useHistory,useLocation} from 'react-router-dom';

export default function OtorgarPreview() {
    const location = useLocation()
    const history = useHistory()
    const [myhtml,setMyhtml] = useState("")
    
    const id = location.state.poderId    
    useEffect(() => {        
        
        setMyhtml(location.state.preview)
        // eslint-disable-next-line react-hooks/exhaustive-deps                              
    },[])

    return (<>
        <div style={{display:"grid",justifyContent:"center",alignItems:"center",margin:"2rem 0"}}>           
            <div dangerouslySetInnerHTML={{__html: myhtml}}/>                            
                {myhtml.length>40?
                <div style={{position:"absolute",top:"55%",width:"100%",height:"5rem",textAlign:"center",zIndex:"2"}}>
                    <div className='StolzlMedium h5'><span>Para ver el Documento completo debe concretar el Pago</span></div>
                    <div>
                        <button 
                            className='btn red-button' 
                            onClick={()=>{
                                history.push({
                                    pathname:"/main/payment",
                                    state:{poder:id,servicio:"P"}
                                })
                            }}
                        >
                        Realizar Pago</button>
                    </div>
                </div>:null}
        </div>
    </>)
}
