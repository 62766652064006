import React,{useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'
import {getUrlApi} from '../../Utils/getUrl&Key'
import {getGoogleKey} from '../../Utils/getUrl&Key'

export default function Loginoptions({navigation,SESSION_KEY}) {
    const history = useHistory()
    
    useEffect(()=>{
        /*global google*/
        google.accounts.id.initialize({
            client_id: getGoogleKey(),
            allowed_parent_origin:"http://localhost:3000",                     
            callback: handleAccountResponse
        })

        google.accounts.id.renderButton(
            document.getElementById("loginIndentify"),
            {theme:"outline"}
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]) 
  
    
    async function handleAccountResponse(response){        
        const Url = getUrlApi() + "/users/logingooglev2"
        const data = {credential:response.credential}
        const redirectCode = history.location.pathname.split("r==")[1]
        let rString
        if(redirectCode){
            const buff = new Buffer.from(redirectCode,'base64')
            rString = buff.toString('ascii')
        }else{
            rString = "/"
        }
        
        
        try{
            await Axios.post(Url,data).then(res=>{    
                           
                if (res.status===200){
                    const {id,name,token,username,rol} = res.data
                                       
                    if(rol){
                        localStorage.setItem(SESSION_KEY,JSON.stringify({name:name, username:username,id:id,token:token,AccType:"google",rol:rol}))
                        
                    }else{                    
                        localStorage.setItem(SESSION_KEY,JSON.stringify({name:name, username:username,id:id,token:token,AccType:"google"}))
                    }
                    history.replace(rString)
                }
            })
        }
        catch(error){
            if(error.response){
                alert(error.response.message)
            }else{
                alert("Ha ocurrido un error")
            }            
        }              
    }

    return (
    <div style={{textAlign:"center",marginTop:"3rem"}}>
        <button className='btn red-button mb-3' onClick={()=>navigation.next()}>Iniciar sesión con un email</button>
        <div style={{fontSize:"small", textAlign:"center", marginBottom:"0.5rem"}} className='divider'>O</div>
        <div style={{display:"flex",justifyContent:"center"}}>                    
            <div id="loginIndentify"/>            
        </div>        
    </div>        
    )
}
