import React,{useState,useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {Accordion, Card} from 'react-bootstrap'
import Axios from 'axios'

//Icons
import {ImFolderDownload,ImFolderUpload} from 'react-icons/im'
import {FaThumbsUp,FaThumbsDown} from 'react-icons/fa'
import {GiBoxUnpacking} from 'react-icons/gi'
import {VscDebugStepBack} from 'react-icons/vsc'
import {GoVerified} from 'react-icons/go'
import loading from '../../../Utils/loading'

//Utils
import {getUrlApi} from '../../../Utils/getUrl&Key'


//Assets
import usaFlg from '../../../Assets/Icons/country flags/tn_us-flag.gif'
import peFlg from '../../../Assets/Icons/country flags/tn_pe-flag.gif'

export default function BanItem({Item,update,setUpdate}) {
    const [costoGA, setCostoGA] = useState("")
    const [costoST,setCostoST] = useState("")
    const [costoApos, setCostoApos] = useState("")
    const [costoEnvio, setCostoEnvio] = useState("")

    const [rechazoCod, setRechazoCod] = useState("")
    const [rechazoTram, setRechazoTram] = useState("")
      
    const [errorMsg, setErrorMsg] = useState("")
    const [myfile, setMyfile] = useState(null)
    const [trackNumber, setTrackNumber] = useState("")
    const [courier, setCourier] = useState("")
    const history = useHistory()
    
    const flags=[
        {pais:"USD",bandera:usaFlg},
        {pais:"PEN",bandera:peFlg},
        {pais:null,bandera:peFlg}
    ]
    const header = {
        "Content-Type":"application/json",
        "authorization": JSON.parse(localStorage.getItem("App.login")).token
    }

    useEffect(()=>{
        setCostoEnvio(Number(Item.servicioenv))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function executeAxiosPost(Url,data,successMessage){
        loading()
        try {
            await Axios.post(Url,data,{headers:header}).then(res=>{
                if(res.status===200||res.status===201){
                    loading(undefined,true)
                    alert(successMessage)
                    setUpdate(update+1)
                }
            })
            
        } catch (error) {
            const response = error.response
            if(response){
                if(response.status===403){
                    loading(undefined,true)
                    alert("Su sesion ha caducado")
                    history.replace("/logout")
                }else{
                    loading(undefined,true)
                    alert(`Ha ocurrido un error, intentar nuevamente mas tarde.\n${response.data.message}`)
                }
            }else{
                console.log(error)
                loading(undefined,true)
                alert(`Error al conectar con el servidor.\nPor favor intentar nuevamente mas tarde.`)
            }
        }
    }

    function handleVerificar(){        
        let errorCounter = 0

        if((Item.servicio!=="APO"&&costoGA==="")||costoST===""){            
            errorCounter += 1
            setErrorMsg("Ingrese los costos necesarios")
        }
        
        if(Item.servicio==="TRAM"){
            if(Item.apostillado==="SI"&&costoApos===""){                
                errorCounter += 1
            }
            setErrorMsg("Ingrese los costos necesarios")
        }
        
        if(Item.servicio==="TRAM"||Item.servicio==="APO"){
            if((Item.remision==="O"||Item.remitente==="O")&&costoEnvio===""){
                errorCounter += 1
                setErrorMsg("Ingrese los costos necesarios")
            }
        }
        
        if(costoGA<0||costoST<0){
            errorCounter += 1
            setErrorMsg("Los precios no pueden ser negativo")
        }        
        
        if(errorCounter===0){
           
            handleAceptar()
        }else{
            alert(errorMsg)
        }
    }

    async function handleDownload(serviceType){
        let serviceLink
        switch (serviceType){
            case "P2":
                serviceLink="inscripcion"
                break;
            case "APO":
                serviceLink="apostilla"
        }

        loading("Descargando",false)
        const Url =  getUrlApi() + `/${serviceLink}/adjuntos`       
        const data = {tramite:Item.id}
        try{
            await  Axios.post(Url,data,{headers:header}).then(res =>{               
                if(res.status === 200){                    
                    const arrayBuffer = res.data.buffer.data;
                    const extension = res.data.extension              //Obtiene el array de la respuesta
                    const name = res.data.name
                    const buffer = Buffer.from(arrayBuffer);             //Transforma el array en un Buffer
                    const url = URL.createObjectURL(new Blob([buffer])); //Crea un objeto Blob y una url a partir el objeto
                    const link = document.createElement('a');            //Crea un elemento html <a>
                    link.href = url;                                     //Asigna al atributo href la url creada
                    link.setAttribute('download', `${name}.${extension}`);           //Añade el atributo download al elemento <a>
                    document.body.appendChild(link);                     //Agrega el elemento <a> como hijo del Body
                    loading("",true)
                    link.click();                                        //Detona el evento click del elemento <a> iniciado la descarga
                    link.parentNode.removeChild(link)                    //Remueve el elemento <a> del Body
                }else{
                    alert("Ha ocurrido un problemas.\nIntentar nuevamente mas tarde.")
                }
            })        
        }
        catch(error){
            const response = error.response
            if(response){
                if(response.status===403){
                alert("Su sesion ha caducado")
                history.replace("/logout")
                }else{
                    alert(`No se pueden decargar los archivos.\n${response.data.message}`)
                    loading("",true)
                }
            }else{                     
                alert(`No se pueden decargar los archivos.\n${error}`)
                loading("",true)
            }
        }
    }

    async function handleUploadFile(file){                
        const extension = file.type.split('/')[1]
        const name = file.name.split('.')[0]
        const base64 = await convertBase64(file)
    
        setMyfile({file:base64,name,ext:extension})
        
    }

    function convertBase64(file){
        return new Promise(async (resolve,reject)=>{            
            const ab = await file.arrayBuffer()
            const base64 = Buffer.from(ab).toString('base64')                        
            resolve(base64)
        })
    }


    async function handleAceptar(){
        let Url,data
        switch (Item.servicio){
            case "P2":
                Url = getUrlApi() + "/inscripcion/aprobar"                
                data = {
                    tramite:Item.id,            
                    servicio:costoST,
                    gadmin:costoGA                    
                }
                break
            case "TRAM":                
                Url = getUrlApi() + "/tramitacion/aprobar"                
                data = {
                    tramite:Item.id,            
                    servicio:costoST,
                    gadmin:costoGA
                }
                if(Item.apostillado==="SI"){
                    Object.assign(data,{apostilla:costoApos})
                
                }else{                   
                    Object.assign(data,{apostilla:0})                    
                }
                if(Item.remision==="O"){
                    Object.assign(data,{envio:costoEnvio})
                }else{
                    Object.assign(data,{envio:0})
                    
                }
                break
            case "APO":
                Url = getUrlApi()+ "/apostilla/aprobar"
                data = {
                    tramite:Item.id,
                    precio:costoST
                }
                if(Item.remitente==="O"){
                    Object.assign(data,{precioEnvio:costoEnvio})
                }else{
                    Object.assign(data,{precioEnvio:0})
                }
                break
            default:
                alert("Un error ha ocurrido")
        }        
        executeAxiosPost(Url,data,"Solicitud Aceptada")
    }

    async function handleRechazar(){
        const user = JSON.parse(localStorage.getItem("App.login")).id        
        let Url,data         
        switch (Item.servicio){
            case "P2":
                Url = getUrlApi() + "/inscripcion/rechazar"                
                data = {
                    tramite:Item.id,            
                    codigo:rechazoCod,
                    user                    
                }
                if(rechazoCod===""){            
                    alert("Selecione el motivo de rechazo")
                }else{
                    executeAxiosPost(Url,data,"Solicitud Rechazada")
                }
                break
            case "TRAM":
                Url = getUrlApi() + "/tramitacion/rechazar"                
                data = {
                    tramite:Item.id,
                    motivo:rechazoTram,            
                    user,
                }
                if(rechazoTram===""){
                    alert("Escriba el motivo del rechazo")
                }else{
                    executeAxiosPost(Url,data,"Solicitud Rechazada")
                }
                break
            case "APO":
                Url = getUrlApi() + "/apostilla/rechazar"
                data = {
                    tramite:Item.id,
                    motivo:rechazoTram,
                    user
                }
                if(rechazoTram===""){
                    alert("Escriba el motivo del rechazo")
                }else{
                    executeAxiosPost(Url,data,"Solicitud Rechazada")
                }
        }
    }

    

    async function handleReactivar(){        
        let Url = getUrlApi() 
        if(Item.servicio==="TRAM") {
            Url += "/tramitacion/reactivar"
        }else if (Item.servicio==="APO"){
            Url += "/apostilla/reactivar"
        }
        const data = {tramite:Item.id}
        executeAxiosPost(Url,data,"Solicitud Reactivada")
        setCostoST(Number(Item.serviciotra))
        setCostoApos(Number(Item.servicioap))
        setCostoGA(Number(Item.servicioga))
    }

    async function handleCompletar(){
            let Url = getUrlApi()            
            const data = {tramite:Item.id}
            switch(Item.servicio){
                case "TRAM":
                    Url += "/tramitacion/finalizar"
                    Object.assign(data,{traking:trackNumber,courrier:courier})                    
                    break
                case "P2":
                    Url += "/inscripcion/finalizar"                    
                    Object.assign(data,{adjunto:myfile})
                    break
                case "P":
                    Url += "/inscripcion/finalizar"                    
                    Object.assign(data,{adjunto:myfile})
                    break
                case "APO":
                    Url += "/apostilla/finalizar"
                    Object.assign(data,{traking:trackNumber,courrier:courier})
                    break
            }
            
            executeAxiosPost(Url,data,"Solicitud Completada")
        
    }

    


    async function handleCollected(){
        let Url 
            switch (Item.servicio){                 
                case "P2":
                    case "P":
                    Url = getUrlApi() + "/inscripcion/notificar"                    
                    break
                case "APO":
                    Url = getUrlApi() + "/apostilla/notificar"
                    break
            }
        
        const data = {tramite:Item.id}
        executeAxiosPost(Url,data,"Notificación de recepción enviada.")
    }

    function escribirEstado(){
        switch(Item.estado){
            case "R":
                return "Registrado"                
            case "A":
                return "Aprobado"                
            case "N":
                return "Rechazado"                
            case "P":
                return "Pagado"                
            case "F":
                return "Completado"                
            case "C":
                return "Doc. Recibido"
        }
    }
    
  return (      
                <Accordion key={Item.id} defaultActiveKey="0">                    
                    <Card key={`Card${Item.id}`}>
                        <Card.Header style={{backgroundColor:"white"}}>
                            <div style={{display:"flex"}}>
                                <div style={{display:"flex",justifyContent:"space-between", width:"20.5rem",maxWidth:"40%"}}>
                                    <div>
                                        {Item.id}
                                    </div>                                        
                                    <div className="historyText">                                        
                                        <div style={{color:"gray"}}>
                                            {Item.fechatramite}
                                        </div>
                                    </div>
                                    <div>
                                        {escribirEstado()}
                                    </div>
                                    <div>                                        
                                        <img width="64" height="40" alt={Item.moneda} src={flags.filter(item=>item.pais===Item.moneda)[0].bandera}/>
                                    </div>
                                </div>

                                <div style={{display:"flex", width:"calc(100% - 12.5rem)",minWidth:"60%",justifyContent:"space-evenly",alignItems:"center"}}>
                                    
                                    {Item.estado==="R"?
                                    <>
                                    
                                    {Item.servicio!=="APO"?<input type="number" value={costoGA} style={{width:"6rem"}} placeholder='G. Administrativos' onChange={e=>setCostoGA(e.target.value)}/>:null}
                                    <input type="number" value={costoST} style={{width:"6rem"}} placeholder='Serv. Tramitec' onChange={e=>setCostoST(e.target.value)}/>
                                    {Item.servicio==="TRAM"?(Item.apostillado==="SI"?<input type="number" value={costoApos} style={{width:"6rem"}} placeholder='Cost. Apostillado' onChange={e=>setCostoApos(e.target.value)}/>:null):null}
                                    {Item.remision==="O"||Item.remitente==="O"?<input type="number" value={costoEnvio} style={{width:"6rem"}} placeholder='Cost. Envio' onChange={e=>setCostoEnvio(e.target.value)}/>:null}
                                    
                                    </>
                                    :
                                    <div style={{display:"flex",fontSize:"0.9rem",textAlign:"center"}}>
                                        {Item.servicio!=="APO"?<div style={{marginRight:"1rem"}}>
                                            <div>Gastos Adminis.</div>
                                            <div>{Item.servicioga}</div>
                                        </div>:null}
                                        <div style={{marginRight:"1rem"}}>                                        
                                            <div>Serv. Tramitec</div>
                                            <div>{Item.serviciotra}</div>
                                            
                                        </div>                                        
                                        {Item.servicio==="TRAM"?(Item.apostillado==="SI"?
                                        <div style={{marginRight:"1rem"}}>                                        
                                            <div>Costo Apostillado</div>
                                            <div>{Item.servicioap}</div>
                                        </div>:null):null
                                        }
                                        {Item.remision==="O"||Item.remitente==="O"?<>
                                        <div style={{marginRight:"1rem"}}>                                        
                                            <div>Costo de Envio</div>
                                            <div>{Item.servicioenv}</div>                                            
                                        </div>
                                        {Item.estado==="F"?<div>
                                            <div>Datos de Envio</div>
                                            <div>{Item.envio}</div>
                                        </div>:null}
                                        </>:null}
                                        
                                    </div>}

                                    {(Item.servicio==="APO"&Item.remitente==="O"&Item.estado==="C")||(Item.servicio==="TRAM"&Item.remision==="O"&Item.estado==="P")?
                                        <><input placeholder='Tracking Number' value={trackNumber} onChange={e=>setTrackNumber(e.target.value)}/>
                                        <select value={courier} onChange={e=>setCourier(e.target.value)}>
                                            <option value="">Courier</option>
                                            <option value="FD">FedEx</option>
                                            <option value="DH">DHL</option>
                                            <option value="OV">OLVA</option>
                                        </select></>
                                        :null
                                    }

                                    {Item.estado==="R"?<>
                                        <div className='Mytooltip'>
                                            <button
                                                variant="none"
                                                className="btn green-button"
                                                disabled={Item.estado!=='R'}
                                                onClick={()=>handleVerificar()}
                                            >
                                            <FaThumbsUp/>
                                            </button>
                                            <span className='Mytooltiptext'>Aceptar Solicitud</span>
                                        </div>                                        
                                    {Item.servicio==="P2"?                                        
                                        <select value={rechazoCod} onChange={(e)=>setRechazoCod(e.target.value)}>
                                            <option value="">Seleccionar</option>
                                            <option value="01">Ilegible</option>
                                            <option value="02">Poder Invalido</option>
                                        </select>
                                    :null}

                                    {Item.servicio==="TRAM"||(Item.servicio==="APO"&&Item.tipo==="0")?                                        
                                        <textarea                                            
                                            value={rechazoTram} 
                                            onChange={(e)=>setRechazoTram(e.target.value)}
                                        />
                                    :null}
                                    
                                    <div className='Mytooltip'>
                                        <span className='Mytooltiptext'>Rechazar Solicitud</span>
                                        <button
                                            variant="none"
                                            className="btn red-button"
                                            disabled={Item.estado!=='R'}
                                            onClick={()=>handleRechazar()}
                                            >
                                            <FaThumbsDown/>
                                        </button>
                                    </div>
                                    </>:null}

                                    {Item.servicio==="P2"||(Item.servicio==="APO"&Item.tipo==="0")?
                                        <div className='Mytooltip'>
                                            <button
                                                variant="none"
                                                className="btn blue-button"                                    
                                                onClick={()=>handleDownload(Item.servicio)}
                                            >
                                            <ImFolderDownload/>
                                            </button>
                                            <span className='Mytooltiptext'>Descargar Adjuntos</span>
                                        </div>
                                    :null} 

                                    {(Item.servicio==="P2"||Item.servicio==="P"||Item.servicio==="APO")&&Item.estado==="P"?
                                        <div className='Mytooltip'>
                                            <button 
                                                className='btn green-button'                                    
                                                onClick={()=>handleCollected()}
                                            >
                                            <GiBoxUnpacking/>
                                            </button>
                                            <span className='Mytooltiptext'>Documento Recepcionado</span>
                                        </div>
                                    :null}

                                    {(Item.servicio==="TRAM"||Item.servicio==="APO")&&(Item.estado==="N"||Item.estado==="A")?
                                        <div className='Mytooltip'>
                                            <button
                                                className='btn green-button'
                                                onClick={()=>handleReactivar()}
                                            >
                                                <VscDebugStepBack/>
                                            </button>
                                            <span className='Mytooltiptext'>Reactivar Solicitud</span>
                                        </div>
                                    :null}

                                    {(Item.servicio==="TRAM"&&Item.estado==="P")||((Item.servicio==="P"||Item.servicio==="P2"||Item.servicio==="APO")&&Item.estado==="C")?
                                        <div className='Mytooltip'>
                                            <button
                                                className='btn green-button'
                                                disabled={(Item.servicio==="P"||Item.servicio==="P2"?!myfile:false)||(Item.servicio==="APO"||Item.servicio==="TRAM")&(Item.remitente==="O"||Item.remision==="O")&(trackNumber===""||courier==="")}
                                                onClick={()=>handleCompletar()}
                                                >
                                                    <GoVerified style={myfile?{color:"var(--orange-Color)"}:{color:"white"}}/>
                                            </button>
                                            <span className='Mytooltiptext'>Solicitud Completada</span>
                                        </div>
                                    :null}
                                    
                                    {Item.servicio[0]==="P"&&Item.estado==="C"?
                                    <div style={{display:"flex",justifyContent:"flex-start",marginBottom:"0.3rem"}} className="Mytooltip">
                                        <label htmlFor={`file-upload${Item.id}`} className="btn btn-warning"><ImFolderUpload/></label>
                                        <input key={`f${Item.id}`} type="file" id={`file-upload${Item.id}`} style={{display:"none"}} 
                                            
                                            onChange={e=>{                                                
                                                handleUploadFile(e.target.files[0])
                                            }}
                                        />
                                        <span className='Mytooltiptext'>Subir Archivos</span>                                            
                                    </div>:null}
                                </div>
                            </div>                            
                        </Card.Header>
                    </Card>                    
                </Accordion>
  )
}
