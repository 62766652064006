import React,{useState,useEffect} from 'react'
import {FloatingLabel, Form, FormLabel, FormControl} from 'react-bootstrap'
import {useLocation} from 'react-router-dom';
import Axios from 'axios';


//Utils
import {checkmail} from '../Utils/checkmail'
import {getUrlApi} from '../Utils/getUrl&Key'


export default function Contactenos() {
    const location = useLocation()
    const [name,setName] = useState("")
    const [email,setEmail] = useState("")    
    const [message,setMessage] = useState("")
    const [complete,setComplete] = useState(true)

    let colored = {border:"0.1rem solid red"}
    let regular = {color: "black"} 

    useEffect(()=>{        
        if(location.state&&message===""){            
            setMessage(location.state)
                      
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[])

    function handlesubmit(e){
        e.preventDefault()
    }

   

    function send(){
        const data = [name,email,message]
        const newdata = data.filter(value=>value==='')
        if(newdata.length>0){
            setComplete(false)
            alert("Debe completar todos los datos")
        }else{
            if(!checkmail(email)){
                setComplete(false)
                alert("Formato de Correo Invalido")
            }else{
                const Url = getUrlApi() + "/common/contactenos"
                const data ={"nombres":name,"email":email,"mensaje":message}
                
                try{
                    Axios.post(Url,data).then(
                        res=>{
                            if(res.status===201){
                                alert("Tu mensaje ha sido enviado con exito,\nte responderemos a la brevedad posible.\n¡Gracias por usar Tramitec!")
                                setName("")                        
                                setEmail("")
                                setMessage("")
                                setComplete(true)
                            }else(console.log(res))
                        }
                    )
                }catch(error){
                    if(error.response){
                        console.log(error.response.data)
                    }
                }
            }
        }
        
    }
    return (
        <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
            <div style={{width:"40rem",maxWidth:"95%"}}>        
                <Form onSubmit={handlesubmit} className='whiteCard'>
                    <FormLabel>¡Si tienes alguna duda o consulta, no dudes en contactarnos! Estamos aquí para ayudarte y te responderemos lo más pronto posible.</FormLabel>                    
                    <FloatingLabel label={"Nombre Completo"} className='mb-2'>
                        <FormControl 
                            name={"name"} type={"text"} placeholder='Nombre Completo'
                            maxLength="40"
                            value={name}
                            style={complete?regular:name!==""?regular:colored} 
                            onChange={e=>setName(e.target.value)}
                            
                        ></FormControl>
                    </FloatingLabel>
                    <FloatingLabel label={"Correo"} className='mb-2'>
                        <FormControl 
                            name={"email"} type={"text"} placeholder='Correo'
                            maxLength="40"
                            value={email}
                            style={complete?regular:email!==""?regular:colored} 
                            onChange={e=>setEmail(e.target.value)}
                        ></FormControl>
                    </FloatingLabel>
                    
                    <FloatingLabel label={"Mensaje"} className="mb-2">
                        <FormControl 
                            name={"message"}
                            maxLength="300" 
                            as={"textarea"}
                            placeholder='Mensaje'
                            value={message}
                            style={complete?{...regular,minHeight:"8rem"}:message!==""?{...regular,minHeight:"8rem"}:{...colored,minHeight:"8rem"}}
                            onChange={e=>setMessage(e.target.value)}
                        ></FormControl>
                        <div style={{fontSize:"0.7rem",color:"gray",textAlign:"end"}}>{`${message.length}/300`}</div>
                    </FloatingLabel>
                    <div style={{display:"flex",width:"100%",justifyContent:"end"}}>
                        <button className='btn red-button' onClick={()=>send()}>Enviar</button>
                    </div>
                    
                </Form>
            </div>
        </div>
    )
}
