import React,{useState,useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'

//Components
import PoderUpdater from '../otorgarObjects/reviewCardInfo'

//Utils
import CustomAlert from '../../../../Utils/alert'
import {getUrlApi} from '../../../../Utils/getUrl&Key'
import {moneyFormat} from '../../../../Utils/numberFormat'
import InsSteps from '../../../../Utils/InsSteps'
import LoginRequired from '../../../../Utils/loginRequired'

//Assets
import BlackLogo from '../../../../Assets/Logos/logoMainBlack.png'


function colorfullStyle(){
    let R = Math.floor(Math.random()*255)
    let G = Math.floor(Math.random()*255)
    let B = Math.floor(Math.random()*255)
    let fontColor

    const RGB = `${R},${G},${B}`     
    const luminance = (R * 0.299 + G * 0.587 + B * 0.114)/255

    if(luminance<=0.5){
        fontColor = "white"
    }else{
        fontColor = "black"
    }

    return {backgroundColor:`rgb(${RGB})`,color:fontColor}
}

const stepList = [
    {step:"Elaboración del Poder.",xtraInfo:""},
    {step:"Registro en Notaria.",xtraInfo:""},
    {step:"Cita en Notaria para Firma de escritura.",xtraInfo:""},
    {step:"Se envia a registros publicos.",xtraInfo:""},    
    {step:"Se entrega constancia de Inscripción.",xtraInfo:""}
]

const SESSION_KEY = "App.login" 


export default function FormReview({navigation}) {
    const ubicacion = sessionStorage.getItem("App.userLocation")

    const POD_KEY = `App.poderdante.${ubicacion}`
    const APO_KEY = `App.apoderado.${ubicacion}`
    const FAC_KEY = `App.facultad.${ubicacion}`
    const INSC_KEY = `App.inscriptor.${ubicacion}`

    const history = useHistory()    
    const [correctVal, setcorrectVal] = useState(false);
    const [concentVal, setconcentVal] = useState(false);

    const [precioFacs,setPrecioFacs] = useState(0) 
    const [precioBase,setPrecioBase] = useState(0)
    const [precioPerFac,setPrecioPerFac] = useState(0)
    const [precioInsc, setPrecioInsc] = useState(0);
    const [moneda, setMoneda] = useState(""); 

    const [cupdate, setCupdate] = useState(0);
    
    const usuario = JSON.parse(localStorage.getItem(SESSION_KEY))
    const poderdante = JSON.parse(localStorage.getItem(`App.poderdante.${ubicacion}`))
    const apoderado = JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`))
    const facultades = JSON.parse(localStorage.getItem(`App.facultad.${ubicacion}`))
    const inscriptor = JSON.parse(localStorage.getItem(`App.inscriptor.${ubicacion}`))

    
    const nFaculdates = JSON.parse(localStorage.getItem(`App.facultad.${ubicacion}`)).length    
    const inscPerson = inscriptor.instramitec
    

    

    useEffect(()=>{
        if(!localStorage.getItem("App.login")){
          CustomAlert(()=>history.push(`/login/r==${btoa(history.location.pathname)}`),"Por favor, inicie sesión para continuar el proceso"," Descuida, la información que has ingresado no se perderá.")
        }
        getCostosPoder()
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[])
    
    useEffect(() => {
        if(nFaculdates<5){
          
          setPrecioFacs(precioPerFac*nFaculdates)
        }
        else{
          setPrecioFacs(precioPerFac*5)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [precioPerFac]);

    async function verificar(){
        if(!(correctVal&&concentVal)){
          alert("Debe aceptar todos los terminos")      
        }else{
          completarPoder()
        }
    }   

      async function getCostosPoder(){
        const nApo = JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`)).length        
        const headers = {"ubicacion":ubicacion}
        const Url = getUrlApi()+ `/common/inscripcioncosto/${nApo}`
    
        await Axios.get(Url,{headers:headers}).then(res=>{
          const data = res.data      
          setPrecioBase(data.costobase)
          setMoneda(data.moneda)
          setPrecioPerFac(data.costoxfac)
          setPrecioInsc(data.costoinsc)
        })    
      }

    async function completarPoder() {        
        //Variable para almacenar id's de facultades
        let idFac =[]
    
        const Url = getUrlApi() + "/poder"
        const headers = {
            "Content-Type": "application/json",
            "ubicacion": ubicacion
        }

        facultades.map(item => idFac.push(item.id))
    
        const data = {
          userid:usuario.id,
          facultades:idFac,
          poderdante,
          apoderado,
          instramitec:inscriptor.instramitec,
          inscriptor:inscriptor.inscriptor
        }
        
        try{
          await Axios.post(Url,JSON.stringify(data),{headers:headers})
          
          .then(res =>{
            
            if(res.status===201){                        
              localStorage.removeItem(POD_KEY)
              localStorage.removeItem(APO_KEY)
              localStorage.removeItem(FAC_KEY)
              localStorage.removeItem(INSC_KEY)          
              setTimeout(preview(res.data.preview,res.data.poderid),1000)                        
            }else{
                alert("Ha ocurrido un problema. Porfavor intentelo más tarde")
            }    
          })            
        }
        catch(error){        
            console.log(error.response.message)
        }    
    }    

    function findPersonIndex(Documento, NumeroDoc,PersonType,updateData){
        let storedData
        let storageKey
        if(PersonType==="POD"){
            storageKey = `App.poderdante.${ubicacion}`            
    
        }else{
            storageKey = `App.apoderado.${ubicacion}`            
        }
        storedData = JSON.parse(localStorage.getItem(storageKey))
        const DataIndex =  storedData.findIndex(person=>person.documento===Documento&&person.docnumero===NumeroDoc)
           
        
        Object.assign(storedData[DataIndex],updateData)    
        localStorage.setItem(storageKey,JSON.stringify(storedData))
        setCupdate(cupdate+1)
    }
      
    function preview(htmlval="<div>Error al Cargar el Documento</div>",podId){    
        history.push({
          pathname: "/main/servicios/otorgar/vistaprevia",
          state: {preview:htmlval,poderId:podId}                            
          })    
    } 


  return (
    <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>
        <div style={{width:"30rem",maxWidth:"95%"}}>
            <InsSteps list={stepList}/>
            <div key="poderdantes" className="whiteCard StolzlMedium">
                <div>Poderdante</div>
                <div style={{fontSize:"0.7rem",color:"gray"}}>(Otorgar Poder)</div>
                {poderdante.map(pod=>{
                    return(
                        <div key={pod.docnumero} className='whiteCard'>
                            <div style={{display:"flex",alignItems:"center"}}>                                
                                <div className='circle-medium' style={{...colorfullStyle(),marginRight:".2rem"}}>{pod.nombre[0]}</div>
                                <div>{pod.nombre + " " + pod.apellidop + " " + pod.apellidom}</div>
                            </div>
                            <div>
                                <PoderUpdater personType="POD" data={pod} callback={findPersonIndex}/>
                            </div>
                        </div>                        
                    )}
                )}
            </div>

            <div key="apoderados" className="whiteCard StolzlMedium">
                <div>Apoderado</div>
                <div style={{fontSize:"0.7rem",color:"gray"}}>(Ejerce Poder)</div>
                {apoderado.map(apo=>{
                    return(
                        <div key={apo.docnumero} className='whiteCard'>
                            <div style={{display:"flex",alignItems:"center"}}>                                
                                <div className='circle-medium' style={{...colorfullStyle(),marginRight:".2rem"}}>{apo.nombre[0]}</div>
                                <div>{apo.nombre + " " + apo.apellidop + " " + apo.apellidom}</div>
                            </div>
                            <div>
                                <PoderUpdater personType="APO" data={apo} callback={findPersonIndex}/>
                            </div>
                        </div>
                    )}
                )}
            </div>

            <div key="facultades" className="whiteCard StolzlMedium">
                <div>Facultades</div>
                <div style={{fontSize:"0.7rem",color:"gray"}}>(Derechos o poderes a otorgar)</div>
                <div style={{marginTop:"1rem"}}>
                    {facultades.map((fac,index)=>{return <div key={`fac${index}`} style={{fontSize:".9rem",color:"darkslategray"}}>{fac.facultad}</div>})}
                </div>
            </div>

            {ubicacion!=="PER"?<div key="inscriptor" className="whiteCard StolzlMedium">
                <div><h6>Inscribe el poder en Registros Públicos</h6></div>                
                {inscriptor.instramitec==="S"?
                    <div style={{marginTop:"1rem",textAlign:"center"}}>
                        <img src={BlackLogo} alt={"logo tramitec"} style={{height:"3.5rem"}}/>
                    </div>:
                    <div className='whiteCard'>
                        <div style={{display:"flex",alignItems:"center"}}>                                
                            <div className='circle-medium' style={{...colorfullStyle(),marginRight:".2rem"}}>{inscriptor.inscriptor.nombres[0]}</div>
                            <div>{inscriptor.inscriptor.nombres + " " + inscriptor.inscriptor.apellidos}</div>                            
                        </div>
                        <div style={{marginTop:"1rem"}}>
                            <div style={{display:"flex",justifyContent:"space-between",fontSize:".9rem",color:"darkslategray"}}><div>Documento:</div><div>{`${inscriptor.inscriptor.documento}-${inscriptor.inscriptor.docnumero}`}</div></div>
                        </div>
                    </div>
                }            
            </div>:null}

            <div key="costos" className="whiteCard StolzlMedium">
                <div>Detalle de Precios</div>
                <div className='redCard'>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Precio Base:</div><div className="StolzlMedium">{`${moneyFormat(precioBase)} ${moneda}`}</div></div>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Facultades:</div><div className="StolzlMedium">{`${moneyFormat(precioFacs)} ${moneda}`}</div></div>
                    {ubicacion!=="PER"?<div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>{`Inscripción:`}</div><div className="StolzlMedium">{inscPerson==="S"?`${moneyFormat(precioInsc)} ${moneda}`:"-"}</div></div>:null}
                    <hr/>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium"><div>Sub Total:</div><div>{`${moneyFormat(precioFacs+precioBase+(inscPerson==="S"?precioInsc:0))} ${moneda}`}</div></div>

                </div>
            </div>
            
            <div style={{marginTop:".8rem"}}>
                <div className="mb-3" style={{fontSize:"0.90rem", textAlign:"justify", color:"red", fontWeight:"bold"}}>
                    Recuerda que luego de generar el poder deberás acercarte al consulado de tu preferencia para su formalización.
                </div>
                <div className="form-check mb-3" style={{fontSize:"0.95rem", textAlign:"justify"}}>
                    <input className="form-check-input" type="checkbox" value={correctVal} id="flexCheckDefault" onChange={()=>setcorrectVal(!correctVal)} />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Declaro que la información consignada es correcta, verdadera y me encuentro en pleno uso de mis facultades 
                        para otorgar un poder Consular. Declaro asimismo conocer que el Cónsul podrá realizar las validaciones y 
                        comprobaciones que considere apropiadas para la formalización del documento.
                    </label>
                </div>
                <div className="form-check" style={{fontSize:"0.95rem", textAlign:"justify" }}>
                    <input className="form-check-input" type="checkbox" value={concentVal} id="flexCheckChecked" onChange={()=>setconcentVal(!concentVal)}/>
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Declaro dar consentimiento expreso para el tratamiento de los datos personales y la finalidad que se le 
                        darán de conformidad con lo establecido por la ley 29733 y su reglamento.
                    </label>
                </div>
            </div>
            
            <div>                
                <LoginRequired/>
                <div style={{ marginTop: "1rem",marginBottom:"7rem",display:"flex",justifyContent:"center"}}>
                    <button          
                        variant="contained"
                        style={{ marginRight: "1rem" }}
                        className= "btn red-button"
                        onClick={() => {
                            if(ubicacion==="PER"){
                              navigation.go(2)
                            }else{
                              navigation.previous()
                            }                  
          
                          }}
                    >
                        REGRESAR
                    </button>
                    <button          
                        variant="contained"
                        style={{ marginRight: "1rem" }}
                        className= "btn green-button"
                        disabled={localStorage.getItem("App.login")?false:true}
                        onClick={() =>verificar()}
                    >
                        CONTINUAR
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}
