import React from 'react'
import {useLocation} from 'react-router-dom';
import Axios from 'axios'

//Icons
import {MdPictureAsPdf} from 'react-icons/md'
import {BsFileEarmarkWordFill,BsCheckLg} from 'react-icons/bs'

//Assets
import DownArrow from '../../Assets/Icons/downarrow_80895.svg'

//Utils
import {getUrlApi} from '../../Utils/getUrl&Key';

export default function ServiceComplete() {
    const location = useLocation()
    const id = location.state.poder
    const servicio = location.state.servicio
    let notificationmails
    const ubicacion = "https://goo.gl/maps/xoecsXYHtRPuiW3J7"
    const userLocation = sessionStorage.getItem("App.userLocation")

    if(location.state.notificacion){        
        notificationmails =  location.state.notificacion.toLowerCase().split(",")
        notificationmails = notificationmails.filter((item,index)=>{
            return notificationmails.indexOf(item) === index;
          })
    }    
    
    async function downloadHandle(tipo){
        const Url = getUrlApi() + `/common/poder/file/P/${id}/${tipo}`
        try{
            await  Axios.get(Url).then(res =>{                
                if(res.status === 200){                    
                    const arrayBuffer = res.data.file.data;
                    const extension = res.data.extension              //Obtiene el array de la respuesta
                    const buffer = Buffer.from(arrayBuffer);             //Transforma el array en un Buffer
                    const url = URL.createObjectURL(new Blob([buffer])); //Crea un objeto Blob y una url a partir el objeto
                    const link = document.createElement('a');            //Crea un elemento html <a>
                    link.href = url;                                     //Asigna al atributo href la url creada
                    if(tipo==="PCARTA"){                                  //Añade el atributo download al elemento <a>
                        link.setAttribute('download', `CartaInstruccion.${extension}`)
                    }else{
                        link.setAttribute('download', `Poder.${extension}`)
                    };           
                    document.body.appendChild(link);                     //Agrega el elemento <a> como hijo del Body
                    link.click();                                        //Detona el evento click del elemento <a> iniciado la descarga
                    link.parentNode.removeChild(link)                    //Remueve el elemento <a> del Body
                }else{
                    alert("Ha ocurrido un error, no se ha podido descargar el archivo")
                }
            })        
        }
        catch(error){            
            alert("No se ha podido descargar el archivo")
        }
    }

    
    
    return (
        <div className='d-flex justify-content-center align-content-center' style={{flexWrap:"wrap",textAlign:"center",height:"calc(100vh - 3.7rem)"}}>
            <div className='whiteCard' style={{maxWidth:"98%"}}>
                <div>
                    <div className="StolzlMedium" style={{fontSize:"2rem"}}>¡Su pago se ha realizado satisfactoriamente!</div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <div style={{color:"white",backgroundColor:"var(--green-Color)",width:"4rem",height:"4rem",borderRadius:"50%"}}>
                            <BsCheckLg style={{marginTop:"0.5rem",fontSize:"3rem"}}/>
                        </div>
                    </div>
                    
                    <h2>Su Trámite es el Número:</h2>
                    <h2 style={{color:"var(--red-Color)"}}>{id}</h2>
                </div>                
                
                <div className='mt-3' style={{justifyContent:"center", width:"100%"}}>

                    {/* ----------Servicio Otorgamiento---------- */}
                    {servicio==="P"?<>                        
                        <h5 style={{maxWidth:"98%"}}>El poder ha sido enviado a:</h5>
                        {notificationmails.map((mail,index)=><h3 key={"mail-"+index} style={{color:"var(--red-Color)"}}>{mail}</h3>)}
                        <h5> junto con las indicaciones de los siguientes pasos a seguir.</h5>
                        
                        <h5>También puedes descargar tu poder directamente haciendo click a continuación.</h5>
                        <img src={DownArrow} alt={"flecha abajo"} className='mt-2 mb-2 floating-img' style={{width:"4rem", height:"4rem"}}/>
                        <div style={{display:"flex",justifyContent:"center" }}>
                            <div style={{margin:"1rem"}}>
                                <div style={{display:"flex",justifyContent:"space-around"}}>
                                    <div onClick={()=>downloadHandle("PPDF")} style={{cursor:"pointer"}} >
                                        <MdPictureAsPdf
                                            className='downloadpdf'                            
                                            />                        
                                    </div>
                                    <div onClick={()=>downloadHandle("PWORD")} style={{cursor:"pointer"}}>                        
                                        <BsFileEarmarkWordFill
                                            className='downloadword'
                                            />                        
                                    </div>
                                </div>
                            <div> <span>Poder</span></div>
                            </div>
                            {userLocation!=="PER"?
                            <div style={{margin:"1rem"}}>
                                <div onClick={()=>downloadHandle("PCARTA")} style={{cursor:"pointer",marginBottom:"0.55rem"}}>                        
                                        <BsFileEarmarkWordFill
                                            className='downloadword'
                                            />                        
                                </div>
                                <div> <span>Instrucción</span></div>
                            </div>:null
                            }
                        </div></>
                    :null}

                    {/* ----------Servicio Inscripcion---------- */}
                    {servicio==="P2"?<>                                    
                        <h5 style={{width:"45rem",maxWidth:"98%"}}>
                            Ahora deberas enviarnos tu poder a TRAMITEC  via courrier en original 
                            para que nosotros nos encarguemos de la legalización ante el Ministerio de Relaciones 
                            Exteriores e inscripcción en los Registros Púplicos.
                        </h5>
                        <h5 style={{maxWidth:"98%"}}>
                            La dirección de TRAMITEC es:
                        </h5>
                        <h5>
                            <a href={ubicacion} target="_blank" rel="noopener noreferrer">Calle Las Palomas 523, Surquillo, Lima 15047</a>.
                        </h5></>                             
                    :null}

                    {/* ----------Servicio Apostilla---------- */}
                    {servicio==="APO"?<>                    
                        <h5 style={{width:"45rem",maxWidth:"98%"}}>
                            Ahora deberas enviarnos tu documento a TRAMITEC  via courrier en original 
                            para que nosotros nos encarguemos del Apostillado.
                        </h5>
                        <h5 style={{width:"45rem",maxWidth:"98%"}}>La dirección de TRAMITEC es:</h5>
                        <h5>
                            <a href={ubicacion} target="_blank" rel="noopener noreferrer">Calle Las Palomas 523, Surquillo, Lima 15047</a>.
                        </h5></>                
                    :null}

                    {/* ----------Servicio Tramitacion-------- */}
                    {servicio==="TRAM"?<>                    
                        <h5 style={{width:"45rem",maxWidth:"98%"}}>Le enviaremos un correo a:</h5> 
                        {notificationmails.map((mail,index)=><h3 key={'mail-'+index} style={{color:"var(--red-Color)"}}>{mail}</h3>)}
                        <h5>con las indicaciones de los siguientes pasos a seguir.</h5></>
                    :null}

                    {/* ----------Publicidad Registral-------- */}
                    {servicio==="PR"?<>                    
                        <h5 style={{width:"45rem",maxWidth:"98%"}}>Le enviaremos un correo a:</h5>
                        {notificationmails.map((mail,index)=><h3 key={'mail-'+index} style={{color:"var(--red-Color)"}}>{mail}</h3>)}
                        <h5> con el documento solicitado.</h5>                    
                        <h5 style={{width:"45rem",maxWidth:"98%"}}>Recuerda que SUNARP atiende estas solicitudes en un plazo máximo de 3 días hábiles, aunque es posible que las recibas en tan solo 30 minutos. </h5></>
                    :null}

                    {/* ----------Servicio Envio-------- */}
                    {servicio==="ENVIO"?                
                        <h3 style={{width:"45rem",maxWidth:"98%"}}>Tu poder sera enviado a la brevedad a la direccion indicada.</h3>                
                    :null}
                </div>          
            </div>
        </div>
    )
}
