export function getCurrentDate(separator='/'){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
}

export function getDay(){
    let newDate = new Date()
    let day = newDate.getDate();    
    return day
}
export function getMonth(){
    let newDate = new Date()    
    let month = newDate.getMonth() + 1;
    return month
}
export function getYear(){
    let newDate = new Date()
    let year = newDate.getFullYear();
    return year
}

export function getMonthString(number=null){
    let months = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"]
    let month
    if(number !== null){
        month = number-1
    }else{
        let newDate = new Date()    
        month = newDate.getMonth();    
    }
    return months[month]
}

export function getMorphedDate(date,separator="-"){
    const dateArray = date.split(separator)
    const newdate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    return newdate
}

export function getStandarDateFormat(date,separator="-"){
    let newDate = new Date(date)
    
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${day<10?`0${day}`:day}${separator}${month<10?`0${month}`:month}${separator}${year}`
    
}