import Axios from "axios"
import {getUrlApi} from "./getUrl&Key" 

export default async function getStateName(Country,StateCode) {
    const Url = getUrlApi() + `/common/estados/${Country}`
    let result = ""
    if(Country&&StateCode){
    await Axios.get(Url).then(res=>{
        const States = res.data
        result = States.filter(state => state.id===StateCode)[0].estado
    })} 
    return result
}
