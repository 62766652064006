import React,{useEffect} from 'react'
import {useStep} from 'react-hooks-helper'


//Components
import FormPod from './formPod'
import FormApo from './formApo'
import FormFac from './formFac'
import FormIns from './formIns'
import FormReview from './formReview'



const steps=[
    {id:"Poderdantes"},
    {id:"Apoderados"},
    {id:"Facultades"},
    {id:"Inscriptor"},
    {id:"Resumen"}
]
export default function OtorgarForm() {
    const ubicacion = sessionStorage.getItem("App.userLocation")
    const {step,navigation} = useStep({
        steps,
        initialStep:0,
    })

    useEffect(()=>{
        window.scrollTo(0,0)
        
        const ins = localStorage.getItem(`App.inscriptor.${ubicacion}`)
        const fac = localStorage.getItem(`App.facultad.${ubicacion}`)
        const apo = localStorage.getItem(`App.apoderado.${ubicacion}`)
        const pod = localStorage.getItem(`App.poderdante.${ubicacion}`)
        if(ins&&fac&&apo&&pod){
            navigation.go(4)
        }
        else if(fac&&apo&&pod){
            navigation.go(3)
        }else if (apo&&pod){
            navigation.go(2)
        }else if (pod){
            navigation.go(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    },[])
    
    
    switch (step.id){
        case "Poderdantes": 
            return <FormPod navigation={navigation}/>;    
        
        case "Apoderados":
            return <FormApo navigation={navigation}/>;
        
        case "Facultades":
            return <FormFac navigation={navigation}/>;

        case "Inscriptor":
            return <FormIns navigation={navigation}/>;
        case "Resumen":
            return <FormReview navigation={navigation}/>;
        default:
            return <div>Ha ocurrido un errror</div>;
    }
}
