import trackVersion from './trackVersion'

const globaltest = false


//92.205.24.231
//192.168.195.77

export function onProduction(){
    return !globaltest
} 

export function getUrlApi(test=globaltest){
    trackVersion() 
    if (test) return  "http://92.205.24.231:8090/api"    
    else return "https://tramitec.pe/api"
}

export function getGoogleKey(test=globaltest){
    //0 localhost //1 Tramites //2 Produccion
    const googleclient =  [
        "171423169477-oj4mukli7hvi3mj0728l38fr4l2kgblc.apps.googleusercontent.com",
        "171423169477-3rgql1bv9nkufcsh67ng8nh8o4lud65q.apps.googleusercontent.com",    
        "696940545267-v47ds7pibpll6r66ku79kr8v841he1u2.apps.googleusercontent.com"
    ]
    
    if(test){
        return googleclient[0]
    }else{
        return googleclient[2]
    }
}
const ubicacion = sessionStorage.getItem("App.userLocation")   

export function getIziKey(test=globaltest,location=ubicacion){
    

    const testKeyGlobal = "83682850:testpublickey_Qa7StGJkxaOYZvmadbkn2op2hqPKKTzT3acRL6PTt7gIn"
    const produccionKeyGlobal ="83682850:publickey_7gPVi2bRG8aZKdm9kQdoMTzZJbC7yur7q1PDRP5lqOlzV"
    const testKeyLocal = "43471050:testpublickey_S8gNndjhWI9xOdZ8zQNqkgBs22bBi90k8OxN9uxrAjJZa"
    const produccionKeyLocal = "43471050:publickey_YzkUikMBrGyMENm3ESqRr3Vh6DXGPnBSoA0u6VJjkGUo6"
    if(test){
        if(location==="PER"){
            return testKeyLocal
        }else{
            return testKeyGlobal
        }
    }else{
        if(location==="PER"){
            return produccionKeyLocal
        }else{
            return produccionKeyGlobal
        }
    }
}