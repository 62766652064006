import React,{useState,useEffect,lazy,Suspense} from 'react'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'

//Components
import TermCond from './termCond'
/* import Chat from './chat/componentChat' */

//Assets
import Patterm from '../Assets/BackgroundImages/WhitePattermLarge.svg'
import StandGuy from '../Assets/StandGuyHouse.svg'
import StandRightHand from '../Assets/stand_right hand.svg'
import painoLogo from '../Assets/Logos/zyro-imagepaino.png'
import mujicaLogo from '../Assets/Logos/zyro-imagemujica.png'

//Icons
import {MdContactPhone} from 'react-icons/md'
import {TiSocialFacebook,TiSocialInstagram,TiSocialLinkedin} from 'react-icons/ti'


//Utils
import DualAlert from '../Utils/alertDualOption'
import {getUrlApi,onProduction} from '../Utils/getUrl&Key'


//samples
import servColor from '../samples/servCardColors.json'

const VideoPlayer = lazy(()=>import("./mainVideo"))


export default function Main({userLocation}) {
    const history = useHistory()
    const ubicacion = sessionStorage.getItem("App.userLocation")    

    const esptext = [
        "No te sientas tan lejos.",
        "Realiza tus trámites desde el extranjero en un solo lugar.",        
        "Dile adiós a las complicaciones.",
        "Realiza todos tus trámites de manera rápida y simple desde la comodidad de tu hogar."
    ]
        
    const parrafos = 
        ["Inversiones Madrisqui S.A.C. es una empresa especializada en la elaboración y desarrollo de soluciones tecnológicas para la prestación de servicios legales. Luego del desarrollo de diversas herramientas inhouse para estudios y notarías, Madrisqui pone al alcance de los peruanos esta plataforma que permitirá la elaboración de poderes para su ejecución en territorio peruano, en cumplimiento de todos los requisitos y formalidades de ley.",
        "Para lograr un óptimo resultado en los servicios de TRAMITEC, Madrisqui cuenta con alianzas estratégicas muy importantes con la Notaría Paino y el Estudio Mujica y Coto Abogados."]
   
    
    const [services, setServices] = useState([]) 

    useEffect(()=>{        
        const Url = getUrlApi() + "/common/servicios"
        const headers = {"ubicacion":sessionStorage.getItem("App.userLocation")}
        let array = []
        try {            
                Axios.get(Url,{headers:headers}).then(res=>{                    
                setServices(res.data)
                res.data.map(item=>array.push({id:item.servicio_min}))                
            })
        } catch (error) {
            
        }        
    },[])
    
    /*-----------------------------------------------------
    const sectionTitles = document.querySelectorAll('.sectionTittle');

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
            entry.target.classList.add('stAnimate'); // Agrega una clase que activa la animación
            //observer.unobserve(entry.target);  // Deja de observar el elemento una vez que se ha animado
            }else{
                entry.target.classList.remove('stAnimate')
            }
            
        });
    });

    sectionTitles.forEach((element) => {
        observer.observe(element);
    });
    /*-----------------------------------------------------*/



    /* useEffect(async ()=>{
        const userdata = JSON.parse(localStorage.getItem("App.Login"))
        if(userdata){
            const url = getUrlApi() + `/users/perfil/${userdata.id}`
            const personas = await Axios.get(url)
            console.log(personas)
        }
    },[]) */

    
   
    
    const scrollToServices = () => {                
        window.scrollTo(0,window.innerHeight)
    }

    function handleRedirect (link) {        
        const redirect = ()=>{
            history.push(link)
        }

        const borrarLocal = () => {            
            localStorage.removeItem(`App.facultad.${ubicacion}`)
            localStorage.removeItem(`App.apoderado.${ubicacion}`)
            localStorage.removeItem(`App.poderdante.${ubicacion}`)
            localStorage.removeItem(`App.inscriptor.${ubicacion}`)
            redirect()
        }


        const local = localStorage.getItem(`App.poderdante.${ubicacion}`)
        if(link==="/main/servicios/otorgar"&&local){
            DualAlert("	¡Hola! Tienes un Poder sin terminar.","Notamos que tienes un Poder sin terminar. ¿Prefieres crear uno nuevo o continuar con el que ya comenzaste?","NUEVO PODER",borrarLocal,"CONTINUAR",redirect)
        }else{
            redirect()                 
        }        
    }

    return (
            <div key="container" style={{display:"flex",justifyContent:"center"}}>
                <div key="backgorund"
                        style={{  
                            position:"fixed",
                            height:"calc(100vh - 3.625rem)",
                            width:"100vw",
                            zIndex:"-1",                  
                            backgroundImage:`url(${Patterm})`,                    
                            backgroundSize:"contain"}}
                    >
                </div>
                {/* <div style={{position:"fixed"}}>{scrollP}/{window.innerHeight}</div> */}
                <div style={{width:"90%"}}>                    
                    <div key='seccion1' className='mainTop'>
                        <div className="StolzlMedium welcometext">                    
                            {userLocation!=="PER"?
                            <div>
                                <div className="welcometextbig">{esptext[0]}</div>
                                <div className="welcometextsmall">{esptext[1]}</div>
                            </div>
                            :
                            <div>
                                <div className="welcometextbig">{esptext[2]}</div>
                                <div className="welcometextsmall">{esptext[3]}</div>
                            </div>
                                
                            }
                        </div>
                        
                        <div className='welcomeVideoContainer'>
                            <Suspense fallback={<div>Loading...</div>}>
                                <VideoPlayer/>
                            </Suspense>                                                   
                        </div>
                        <div className='welcomeInicia' >
                            <div className='welcomeIniciaBody' onClick={()=>scrollToServices()}>
                                <div style={{
                                    display:"grid",
                                    alignItems:"center",
                                    color:"white",
                                    textAlign:"center",
                                    width:"6rem",height:"6rem",backgroundColor:"var(--red-Color)",
                                    borderTopLeftRadius:"50%",
                                    borderTopRightRadius:"50%",
                                    borderBottomLeftRadius:"80%",
                                    borderBottomRightRadius:"80%",

                                    }}>Iniciar Tramite</div>
                                <div style={{
                                    position:"relative",
                                    top:"-1.2rem",
                                    left:"0.5rem",
                                    borderTop:"2rem solid var(--red-Color)",
                                    borderLeft:"2.5rem solid transparent",
                                    borderRight:"2.5rem solid transparent",                                    
                                    width:"0rem",height:"1rem",
                                    borderTopLeftRadius:"30%",
                                    borderTopRightRadius:"30%"
                                    }}/>
                            </div>
                        </div>             
                        <img
                            alt = "Person"
                            src = {userLocation!=="PER"?StandGuy:StandRightHand}
                            className = "bounce-in-right welcomeperson"
                        />
                    </div>
                    <div key="seccion2" className='mainSection'>
                        <div className='sectionTittle StolzlBook'>NUESTROS SERVICIOS</div>
                        <div className='sectionServices'>
                            <div style={{height:"max-content",width:"100%",display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                                
                                {services.map((item,index)=>{return(
                                    <div
                                        key={item.servicio_min}                            
                                        className={`serviceCard scShadow_${index} customBoxShadow`}                                        
                                        style={servColor[index]}                                        
                                    >
                                        <div className='d-flex justify-content-center' style={{width:"100%",color:"white"}}>
                                            <div 
                                                className='circle-medium Amaranth-Italic customBoxShadow serviceInfo' 
                                                style={{...servColor[index],marginTop:"-1rem",position:"absolute",userSelect:"none"}}
                                                onClick={
                                                    ()=>DualAlert(item.servicio_min,item.informacion,"Cerrar",undefined,"Ir al servicio",()=>handleRedirect(item.link))}
                                                >i</div>
                                        </div>
                                        <div className='serviceCardBody StolzlMedium' style={{userSelect:"none"}} onClick={()=>handleRedirect(item.link)} dangerouslySetInnerHTML={{__html: item.servicio}}/>
                                        {/* <div className='white-rounded-button btn white-button' onClick={()=>handleRedirect(item.link)}>INICIAR</div> */}
                                    </div>
                                    )}
                                )}                                 
                            </div>
                        </div>
                    
                        {/* <Slider steps={steps}/> */}
                    </div>
                    <div key='seccion3' className='mainSection'>
                        <div className='sectionTittle StolzlBook'>ACERCA DE NOSOTROS</div>
                        <div className='sectionUs'>                            
                            <div>                       
                                <div style={{margin:"1rem 10%", fontSize:"calc(1vw + 0.3rem)"}}>
                                    <div style={{textAlign:"justify"}} className="mb-3">{parrafos[0]}</div>
                                    <div className="mb-3" style={{textAlign:"justify"}}>{parrafos[1]}</div>
                                </div>
                            </div>
                            <div style={{display:"flex",justifyContent:"center"}}>
                                <div style={{backgroundColor:"white",borderRadius:"0.5rem",width:"18rem",maxWidth:"98%"}} className='customBoxShadow'>
                                    <div>
                                        <div style={{textAlign:"center",marginTop:"1rem",fontSize:"1.2rem"}} className='StolzlMedium'>Alianzas Estratégicas</div>
                                    </div>
                                    <div style={{padding:"2rem 1rem"}}>
                                        <div style={{marginBottom:"2rem"}} className='kenburns sectionUsLogo'><a href='https://www.notariapaino.com.pe/' target="_blank" rel="noreferrer"><img src={painoLogo} alt='logonotaria' style={{width:"12rem"}}/></a></div>
                                        <div style={{backgroundColor:"white"}} className='kenburns sectionUsLogo'><a href='https://www.mvcabogados.com/' target="_blank" rel="noreferrer"><img src={mujicaLogo} alt='logoestudio' style={{width:"10rem"}}/></a></div>
                                    </div>
                                </div>
                            </div>                            
                        </div>                        
                    </div>
                    <div className='StolzlMedium socialM'>            
                            <a href='https://www.linkedin.com/company/82151273/admin/' target="_blank" rel="noreferrer" style={{color:"#404D57"}}>
                                <TiSocialLinkedin style={{fontSize:"2.5rem"}} className='socialIcon'/>
                            </a>
                            <a href='https://www.facebook.com/tramitec.pe' target="_blank" rel="noreferrer" style={{color:"#404D57"}}>
                                <TiSocialFacebook style={{fontSize:"2.5rem"}} className='socialIcon'/>
                            </a>
                            <a href='https://www.instagram.com/tramitec.pe/' target="_blank" rel="noreferrer" style={{color:"#404D57"}}>
                                <TiSocialInstagram style={{fontSize:"2.5rem"}} className='socialIcon'/>
                            </a>
                    </div>
                    {/* <Chat/> */}
                    <div key='pieDePagina' className='sectionFooter'>
                        <div>
                            <div style={{textAlign:"center"}}>© 2022 TRAMITEC - TODOS LOS DERECHOS RESERVADOS.</div>                        
                            <div style={{textAlign:"center",color:"gray",fontSize:"calc(0.6vw + 0.1rem)"}}>
                                Calle Las Palomas 523, Surquillo, Lima - Perú
                            </div>
                            <div style={{textAlign:"center",color:"gray",fontSize:"calc(0.6vw + 0.1rem)"}}>
                                +51 979 353 243
                                <MdContactPhone style={{marginLeft:"0.4rem",marginBottom:"0.2rem",fontSize:"calc(1.5vw + 0.2rem)", color:"var(--red-Color)"}}/>                            
                            </div>
                            <div className='falseLink'  onClick={()=>TermCond()}>Términos y Condiciones</div>
                        </div>                    
                    </div>
                </div>
            </div>
            
    )
}

