import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function ServiceRegistered() {
  const history = useHistory()
  const location = useLocation()

  const id = location.state.tramiteId
  const servicio = location.state.servicio  
  const emails = location.state.email

  const apoType = location.state.apoType
  const cotizaError = location.state.cotizaError
    
  return (
    <div className='d-flex justify-content-center align-content-center' style={{ flexWrap: "wrap", textAlign: "center", height: "calc(100vh - 3.7rem)" }}>
      <div className='whiteCard' style={{ maxWidth: "98%" }}>

        {/* --------Servcicio Apostilla--------- */}
        {servicio === "APO" ? <>
          <div>
            {apoType === 0 ?
              <h1 className="StolzlMedium">Hemos recibido su Solicitud</h1>:
              <h1 className="StolzlMedium">Hemos recibido y aprobado su solicitud</h1>              
            }
            <h2>Su Trámite es el Número:</h2>
            <h2 style={{ color: "var(--red-Color)" }}>{id}</h2>
          </div>
          <div className='mt-5' style={{ display: "flex", justifyContent: "center" }}>
            {apoType === 0 ? <h5 style={{ width: "45rem", maxWidth: "98%" }}>Hemos recibido tu documento, lo revisaremos y te enviaremos una respuesta con los detalles y costos del servicio.</h5>
              : <h5 style={{ width: "45rem", maxWidth: "98%" }}>Por favor, proceda con el pago del servicio. Recibirás un correo con las indicaciones del proceso.</h5>}
          </div>
          <div className='d-flex justify-content-evenly mt-5'>
            <button className='btn red-button' onClick={() => history.push('/')}>Volver a Inicio</button>
            {apoType !== 0 ? <button className='btn red-button' onClick={() => history.push({ pathname: "/main/payment", state: { poder: id, servicio: "APO" } })}>Realizar Pago</button> : null}
          </div></>
          : null}

          {/* ---------Servicio Inscripcion------- */}
          {servicio==="P2"?<>
          <div>
                <h1 className="StolzlMedium">Hemos recibido su Solicitud</h1>
                <h2>Su Trámite es el Número:</h2>
                <h2 style={{color:"var(--red-Color)"}}>{id}</h2>
            </div>
            <div style={{width:"100%", marginTop:"4rem"}}>                
                <h5 style={{maxWidth:"98%"}}>Le enviaremos un correo a:</h5> 
                {emails.map(email=><h3 style={{color:"var(--red-Color)"}}>{email}</h3>)}
                <h5>con las indicaciones de los siguientes pasos a seguir.</h5>                                  
            </div>
            <div style={{marginTop:"1rem"}}>
                <button className='btn red-button' onClick={()=>history.push('/')}>Volver a Inicio</button>
            </div>
          </>:null}

          {/* ---------Servicio Publicidad------- */}
          {servicio==="PR"?<>
            <div style={{textAlign:"center"}}>
                  <h1 className="StolzlMedium">Hemos recibido su Solicitud</h1>
                  <h2>Su Trámite es el Número:</h2>
                  <h2 style={{color:"var(--red-Color)"}}>{id}</h2>
              </div>
              {cotizaError===0?<>
              <div className='mt-5' style={{display:"flex",justifyContent:"center"}}>            
                  <h3 style={{width:"45rem",maxWidth:"98%"}}>Para continuar, le pedimos que realice el pago correspondiente.</h3>
              </div>
              <div style={{display:"flex", justifyContent:"space-evenly"}}>
                  <button className='btn red-button' onClick={()=>history.push('/')}>Volver a Inicio</button>
                  <button className='btn red-button' onClick={()=>history.push({pathname:"/main/payment",state:{poder:id,servicio:"PR"}})}>Realizar Pago</button>
              </div></>:
              <>
              <div className='mt-5' style={{display:"flex",justifyContent:"center"}}>            
                  <h3 style={{width:"45rem",maxWidth:"98%"}}>Se le enviará por correo la cotizacion correspondiente.</h3>
              </div>
              <div style={{display:"flex", justifyContent:"center"}}>
                  <button className='btn red-button' onClick={()=>history.push('/')}>Volver a Inicio</button>                
              </div>
              </>}      
          </>:null}

          {/* ---------Servicio Tramitacion------ */}
          {servicio=="TRAM"?<>
            <div>
                <h1 className="StolzlMedium">Hemos recibido su Solicitud</h1>
                <h2>Su Trámite es el Número:</h2>
                <h2 style={{color:"var(--red-Color)"}}>{id}</h2>
            </div>
            <div className='mt-5'>
                <h5 style={{width:"45rem",maxWidth:"98%"}}>Le enviaremos un correo a:</h5> 
                {emails.map(email=><h3 style={{color:"var(--red-Color)"}}>{email}</h3>)}
                <h5>con las indicaciones de los siguientes pasos a seguir.</h5>
            </div>
            <div>
                <button className='btn red-button' onClick={()=>history.push('/')}>Volver a Inicio</button>
            </div>
          </>:null}
      </div>
    </div>
  )
}
