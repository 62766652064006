import React,{useState,useEffect} from 'react'
import {FloatingLabel, Form, FormLabel} from 'react-bootstrap'
import {useHistory,useLocation} from 'react-router-dom'
import Axios from 'axios'

//Utils
import {checkmail} from './../../Utils/checkmail'
import PhoneInput from '../../Utils/phoneInput'
import {getUrlApi,onProduction} from '../../Utils/getUrl&Key'

const BOLETA = "B"
const FACTURA = "F"
export default function PaymentForm({LOCAL_KEY}) {
    
    const history = useHistory()
    const location = useLocation()

    const servicioID = location.state.servicio
    const colored = {border:"0.1rem solid red"}
    const regular = {color: "black"}        
      
    const [complete,setcomplete] = useState(true)
    const [service,setService] = useState("")
    const [currency,setCurrency] = useState("")
    const [amount,setamount] = useState(0)

    const [recipeAmount,setRecipeAmount] = useState(0)
    const [recipeCurrency, setRecipeCurrency] = useState("USD")
   
    const [tipoComp,setTipoCom] = useState("")
    const [cdocumento,setCdocumento] = useState(" ")
    const [cnumerodoc,setCnumerodoc] = useState(" ")
    const [cnombre,setCnombre] = useState(" ")
    const [capellido,setCapellido] = useState(" ")    
    const [razon, setRazon] = useState(" ")
    const [cdireccion,setCdireccion] = useState("")
    const [telefono,setTelefono] = useState("")        
    const [correo,setCorreo] = useState("")
   
    const [rdocumento,setRdocumento] = useState("")
    const [rnumerodoc,setRnumerodoc] = useState(" ")
    const [rnombre,setRnombre] = useState(" ")
    const [rapellido,setRapellido] = useState(" ")    
    
    const [disableDup,setDisableDup] = useState(false)
    
   useEffect(() => {
       if(localStorage.getItem(LOCAL_KEY)){            
            const stored = JSON.parse(localStorage.getItem(LOCAL_KEY))            
            
            setTelefono(stored.comprobante.telefono)
            setCorreo(stored.comprobante.email)
            setTipoCom(stored.comprobante.tipo)
            setCdocumento(stored.comprobante.documento)
            setCnumerodoc(stored.comprobante.numerodoc)
            setCnombre(stored.comprobante.nombre)
            setCapellido(stored.comprobante.apellido)            
            setRazon(stored.comprobante.razonsocial)    
            setCdireccion(stored.comprobante.direccion)
            setRdocumento(stored.recibo.documento)
            setRnumerodoc(stored.recibo.numerodoc)
            setRnombre(stored.recibo.nombre)
            setRapellido(stored.recibo.apellido)
       }
       /* if(service===""){ */
            getPrecio(location.state.servicio,location.state.poder)
        /* } */

        /* return()=>{
            const script = document.querySelector('script[kr-public-key]')
            document.head.removeChild(script);
            window.location.reload()
        } */
       // eslint-disable-next-line react-hooks/exhaustive-deps       
   }, [])  

    const handleSubmit = e => {
        e.preventDefault();       
    }
    
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
      };

    function justnumber(event){      
        if (Number.isNaN(parseInt(event.key))){
            event.preventDefault()
        }            
    }  

    async function getPrecio(servicio,poder){
        const Url = getUrlApi() + `/common/tramites/monto/${servicio}/${poder}`
               
        const data = await Axios.get(Url)
        
        setService(data.data.servicio)
        setCurrency(data.data.moneda)
        setamount(data.data.monto)
        if(data.data.montorecibo){
            setRecipeAmount(data.data.montorecibo)
            setRecipeCurrency(data.data.monedarecibo)
        }
    }
    
    async function getPoderdante(dupPod){
        const servicio = location.state.servicio
        const poder = location.state.poder
        const url =  getUrlApi() + `/common/tramites/${servicio}/${poder}`
        const data = await (await Axios.get(url)).data.poderdante[0]        
        if(dupPod){
            setCdocumento(data.documento)
            setCnumerodoc(data.docnumero)
            setCnombre(data.nombre)
            setCapellido(data.apellidop +" "+data.apellidom )
            setTelefono(data.telefono)
            
            setCorreo(data.email)
        }else{
            setCdocumento("")
            setCnumerodoc("")
            setCnombre("")
            setCapellido("")            
            setTelefono("")
            setCorreo("")
        }
    }
   
    
    function handleAceptar(){
        let counter = 0
        const data ={        
        comprobante:{
            tipo:tipoComp,
            documento:cdocumento,
            numerodoc:cnumerodoc,
            nombre:cnombre.toLocaleUpperCase(),
            apellido:capellido.toLocaleUpperCase(),
            razonsocial:razon.toLocaleUpperCase(),
            direccion:cdireccion.toLocaleUpperCase(),            
            telefono:telefono,            
            email:correo.toLocaleUpperCase(),            
            monto: amount,
            moneda: currency
        },recibo:{
            tipo:"R",
            documento:rdocumento,
            numerodoc:rnumerodoc,
            nombre:rnombre.toLocaleUpperCase(),
            apellido:rapellido.toLocaleUpperCase(),
            monto:recipeAmount,
            moneda:recipeCurrency,
        },servicio:location.state.servicio
        }

        let isComplete

        if(!checkmail(data.comprobante.email)){
            counter+=3
        }
        
        if(recipeAmount!==0){            
            isComplete = Object.values(data.recibo).every(item=>item!=="")               
            if(!isComplete){counter+=1}
        }        
        isComplete = Object.values(data.comprobante).every(item=>item!=="")
        
        if(!isComplete){counter+=1}
        
        if(counter===0){            
                getFormToken().then(()=>{                
                    localStorage.setItem(LOCAL_KEY,JSON.stringify(data))                
                    history.push({pathname:"/main/payment/review",state:location.state})
                })              
            
        }
        else{
            if(counter!==3){
                setcomplete(false)
                alert("Completar todos los campos!")
            }
            if(counter===3){
                setcomplete(false)
                alert("Correo Invalido!")
            }
        }
    }

    async function getFormToken(){
        const p = onProduction()
        const userid = JSON.parse(localStorage.getItem("App.login")).id
        const token = JSON.parse(localStorage.getItem("App.login")).token
        const ubicacion = sessionStorage.getItem("App.userLocation")
        const Url =  getUrlApi() + "/common/formtoken"        
        const header = {
            "Content-Type":"application/json",
            "authorization":token,
            "ubicacion":ubicacion,
            "produccion":p?"produccion":"prueba"
        }
        console.log(header);
        const data = {
            "id":userid,
            "poder":location.state.poder,
            "amount":amount + recipeAmount,
            "currency": currency,
            "email": correo,
        }        
        try{        
        await Axios.post(Url,data,{headers:header}).then(res=>{                        
            localStorage.setItem("App.formToken",JSON.stringify(res.data))             
          })
        }catch(error){
            console.log(error)
            const response = error.response
            if(response.status===400){
                console.log(response);
                alert(response.data)
            }
            if(response){
                if(response.status===403){
                alert("Su sesion ha caducado")
                history.replace("/logout")
                }
            }
        }
    }

    function handleCancelar(){
        history.push("/main/usuario/historial")
        localStorage.removeItem(LOCAL_KEY)
    }

    function handleTypeCompChange(value){
        setTipoCom(value)       
        
        switch (value){
            case BOLETA:
                setCdocumento(" ")
                setCnombre("")
                setCapellido("")                
                setCdireccion(" ")
                setRazon(" ")                
                break;
            case FACTURA:
                setCdocumento("R")
                setCnombre(" ")
                setCapellido(" ")
                setCdireccion("")                
                setRazon("")
                setDisableDup(false)
                break;
            default:
                alert("Un error ha ocurrido")                            
        }
    }

    function handleDuplicate(duplicate){
        setDisableDup(duplicate)
        if(duplicate){
            setRdocumento(cdocumento)
            setRnumerodoc(cnumerodoc)
            setRnombre(cnombre)
            setRapellido(capellido)
        }else{
            setRdocumento("")
            setRnumerodoc("")
            setRnombre("")
            setRapellido("")
        }
    }

  

    function renderCompForm(){
        if(tipoComp===BOLETA){
            return(<>
                <div className="form-floating" >
                    
                    <select 
                        className="form-select form-select-md mb-3" 
                        id="floatingInput" 
                        placeholder="documento" 
                        name="documento" 
                        onChange={(e)=>{setCdocumento(e.target.value)}} 
                        value={cdocumento}                        
                        style={complete ? regular:cdocumento===" "? colored:regular}>
                        <option value="">Seleccionar</option>
                        <option value="D">DNI</option>
                        <option value="PS">Pasaporte</option>
                        <option value="CE">Carnet Extranjero</option>
                    </select>
                    <label htmlFor="floatingInput">Documento</label>
                </div>
                <FloatingLabel label= "Documento de Identidad"  className='mb-2'>
                    <Form.Control 
                        name="cnumeroDoc" 
                        type="text" 
                        placeholder="Documento de Identidad" 
                        value={cnumerodoc}
                        onKeyPress={e=>justnumber(e)}                         
                        onChange={(e)=>{setCnumerodoc(e.target.value)}}
                        style={complete? regular:cnumerodoc===""? colored:regular}
                    />        
                </FloatingLabel>

                <div style={{display:"flex",justifyContent:"space-between"}}  className='mb-2'>
                    <FloatingLabel label= "Nombres">
                        <Form.Control 
                            name="cnombre" 
                            type="text"
                            className='uppercase-input' 
                            placeholder="Nombres" 
                            value={cnombre}                                                      
                            onChange={(e)=>{setCnombre(e.target.value)}}
                            style={complete? regular:cnombre===""? colored:regular}
                        />        
                    </FloatingLabel>

                    <FloatingLabel label= "Apellidos" >
                        <Form.Control 
                            name="capellido" 
                            type="text" 
                            placeholder="Apellidos" 
                            value={capellido}
                            className='uppercase-input'                            
                            onChange={(e)=>{setCapellido(e.target.value)}}
                            style={complete? regular:capellido===""? colored:regular}
                        />        
                    </FloatingLabel>
                </div>
            </>)
        }else if(tipoComp===FACTURA){
            return(<>
            <FloatingLabel label= "RUC"  className='mb-2'>
                <Form.Control 
                    name="numeroRUC" 
                    type="text" 
                    value={cnumerodoc}                      
                    placeholder="RUC" 
                    onChange={e=>setCnumerodoc(e.target.value)}
                    style={complete? regular:cnumerodoc===""? colored:regular}
                />        
            </FloatingLabel>

            <FloatingLabel label= "Razon Social" className='mb-2'>
                    <Form.Control 
                        name="razon" 
                        type="text" 
                        placeholder="Razon Social" 
                        value={razon}
                        className='uppercase-input'                        
                        onChange={(e)=>{setRazon(e.target.value)}}
                        style={complete? regular:razon===""? colored:regular}
                    />        
            </FloatingLabel>
            <FloatingLabel label= "Direccion"  className='mb-2'>
                        <Form.Control 
                            name="codireccion" 
                            type="text" 
                            placeholder="Direccion" 
                            value={cdireccion}
                            className='uppercase-input'                            
                            onChange={(e)=>{setCdireccion(e.target.value)}}
                            style={complete? regular:cdireccion===""? colored:regular}
                        />        
            </FloatingLabel>
            </>)
        }
    }

    function renderRecipe(){
        
        return(
        <div>
            <div style={{display:"flex",justifyContent:"space-between"}}  className='mb-4'>
                {servicioID==="PR"?
                <span>Recibo de Gastos Registrales</span>
                :<span>Recibo de Inscripcion</span>}
                <span>{recipeAmount+recipeCurrency}</span>
            </div>
            {<div className='paymentcomp'>
                        <FormLabel style={{fontWeight:"600"}}>Datos del Recibo</FormLabel>
                        {tipoComp===BOLETA?
                        <div>
                            <label className='switch' htmlFor='duplicate'>
                                <input type="checkbox" id="duplicate"  onChange={(e)=>handleDuplicate(e.target.checked)}/>
                                <span className="slider"></span>
                            </label>
                            <span>Usar Datos de la boleta</span>
                        </div>:""}                        
            </div>}
            <div>
            <div className="form-floating" >
                    <select 
                        className="form-select form-select-md mb-3" 
                        id="floatingInput" 
                        placeholder="documento" 
                        name="rdocumento" 
                        onChange={(e)=>setRdocumento(e.target.value)} 
                        value={rdocumento}
                        disabled ={disableDup}                        
                        style={complete ? regular:rdocumento===""? colored:regular}>
                        <option value="">Seleccionar</option>
                        <option value="D">DNI</option>
                        <option value="PS">Pasaporte</option>
                        <option value="CE">Carnet Extranjero</option>
                    </select>
                    <label htmlFor="floatingInput">Documento</label>
                </div>
                <FloatingLabel label= "Documento de Identidad"  className='mb-2'>
                    <Form.Control 
                        name="rnumeroDoc" 
                        type="text" 
                        placeholder="Documento de Identidad" 
                        value={rnumerodoc}
                        onKeyPress={e=>justnumber(e)}                         
                        onChange={(e)=>{setRnumerodoc(e.target.value)}}
                        disabled ={disableDup}
                        style={complete? regular:rnumerodoc===""? colored:regular}
                    />        
                </FloatingLabel>

                <div style={{display:"flex",justifyContent:"space-between"}}  className='mb-2'>
                    <FloatingLabel label= "Nombres">
                        <Form.Control 
                            name="rnombre" 
                            type="text" 
                            placeholder="Nombres" 
                            value={rnombre}
                            disabled ={disableDup}
                            onInput={e=>toInputUppercase(e)}                             
                            onChange={(e)=>{setRnombre(e.target.value)}}
                            style={complete? regular:rnombre===""? colored:regular}
                        />        
                    </FloatingLabel>

                    <FloatingLabel label= "Apellidos" >
                        <Form.Control 
                            name="rapellido" 
                            type="text" 
                            placeholder="Apellidos" 
                            value={rapellido}
                            disabled ={disableDup}
                            onInput={e=>toInputUppercase(e)}                             
                            onChange={(e)=>{setRapellido(e.target.value)}}
                            style={complete? regular:rapellido===""? colored:regular}
                        />        
                    </FloatingLabel>
                </div>
            </div>    
        </div>        
    )}


    return (
        <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>
            <div style={{width:"30rem",maxWidth:"95%"}}>
                
                <Form onSubmit={handleSubmit} className='mb-4'>                
                    <div style={{display:"flex",justifyContent:"space-between"}}  className='mb-4'>
                        <div dangerouslySetInnerHTML={{__html: service}}/>
                        <span>{amount+currency}</span>
                    </div>
                    {/* <FormLabel style={{fontWeight:"600"}}>Datos del Comprobante</FormLabel> */}
                    {tipoComp===BOLETA&&location.state.servicio==="P"?
                            <div>
                                <label className='switch' htmlFor='dupPod'>
                                    <input type="checkbox" id="dupPod"  onChange={(e)=>getPoderdante(e.target.checked)}/>
                                    <span className="slider"></span>
                                </label>
                                <span>Usar Datos del Poderdante</span>
                            </div>:""}
                    <div>
                        <div className="form-floating" >
                            <select 
                                className="form-select form-select-md mb-2"
                                id="floatingInput" 
                                placeholder="Tipo de Comprobante"
                                onChange={(e)=>handleTypeCompChange(e.target.value)}
                                value={tipoComp}
                                style={complete? regular:tipoComp===""? colored:regular}                        
                            >
                                <option value="">Seleccionar</option>
                                <option value={BOLETA}>Boleta</option>
                                <option value={FACTURA}>Factura</option>            
                            </select>
                            <label htmlFor="floatingInput">Tipo de Comprobante</label>
                        </div>                    
                        {renderCompForm()}                       
                        <PhoneInput saveValue={setTelefono} currentValue={telefono}/>
                        <FloatingLabel label= "Correo"  className='mb-2'>
                            <Form.Control 
                                name="correo" 
                                type="text" 
                                placeholder="Correo" 
                                value={correo}
                                className='uppercase-input'                                
                                onChange={(e)=>{setCorreo(e.target.value)}}
                                style={complete? regular:correo===""? colored:regular}
                            />        
                        </FloatingLabel>
                    </div>
                    {recipeAmount!==0?renderRecipe():null}
                    <div style={{display:"flex",justifyContent:"space-around"}}>
                        <button className='btn red-button' onClick={()=>handleCancelar()}>Cancelar</button>
                        <button className='btn green-button' onClick={()=>handleAceptar()}>Aceptar</button>
                    </div>                
                </Form>
            </div>                        
        </div>
    )
}
