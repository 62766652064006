import React,{useState,useEffect} from 'react'
import * as crypto from 'crypto'
import Axios from 'axios'


const PK = "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA819PG46+WDAtz9zWKL8W\n9ubs2SkadUV+MKRwKXqw9eZV16ZNeIy/7dObXPKVJV9wQDCGU9cx9xTx4AZc2/wa\nhCcm6tQUA/IUMK4zVeW51l5DT+loJhR1jDDLA5jzNje/AC/DG0RmwuzGz31BDvdw\nxUmqHfCGavjQbvjsbljfYdHK/JrFmZZCmmbbJs5vFrjRR90N3yQKPzxRHcauFEcw\newcEEYBuI2nyTWd2mdXIIpU0nyZfDhE06YG68cKkzwBvq6NaHAhC/Ig3xw4sxqxk\nMnZTdENJPpBcfqmnU8FyKN7qeParj/+0pyw2K6j+tL/9v2aqJUCRYCWTJBZ4imto\nSwIDAQAB\n-----END PUBLIC KEY-----\n"
const message = "le fishe au chocolat"
const urlBase = "http://192.168.195.84:3001/"

const plantilla = {nombre:"",apellido:""}
export default function TestForm() {
    
    const [Forms, setForms] = useState([plantilla]);  
    
    useEffect(() => {
        
        const time = sessionStorage.getItem("App.ts")
        const encryptedMessage = crypto.publicEncrypt(
            { key: PK, padding: crypto.constants.RSA_PKCS1_PADDING },
            Buffer.from(atob(time))
          ).toString('base64')     
                      
          sendSecretMessage(encryptedMessage)
    }, []);

    async function sendSecretMessage(message){
        try {
            Axios.post("http://192.168.195.77:8090/api/common/desencriptar",{message})
        } catch (error) {
            console.log(error.response);  
        }
    }

    function updateFormData(value,index){
        const dataArray = [...Forms]
        const dataObject = dataArray[index]
        const newData = {...dataObject,...value}
        dataArray[index] = newData
        setForms(dataArray) 
    }

    function handleAddForm(){
        const dataArray = [...Forms,plantilla]
        setForms(dataArray)
    }

    function handleRemoveLast(){
        const dataArray = Forms.filter((_,index)=>index !== (Forms.length-1))        
        setForms(dataArray)
    }   
    
    

      
      

  return (
    <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
        <div style={{width:"30rem"}}>
            <div className='whiteCard'>
                {Forms.map((f,index)=>{return(
                    <div className='d-flex justify-content-between mb-2'>
                        <input placeholder='Nombre' value={Forms[index].nombre} onChange={e=>updateFormData({nombre:e.target.value},index)}></input>
                        <input placeholder='Apellido' value={Forms[index].apellido} onChange={e=>updateFormData({apellido:e.target.value},index)}></input>
                    </div>
                    )
                })
                }
            </div>
            
            <div className='mt-2 d-flex justify-content-around'>
                <button className='btn green-button' onClick={()=>handleAddForm()}>Añadir Formulario</button>
                <button className='btn red-button' onClick={()=>handleRemoveLast()}>Eliminar Formulario</button>
            </div>
            <div className='whiteCard'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                        </tr>                        
                    </thead>
                    <tbody>
                        {Forms.map(f=>
                        <tr>
                            <td>{f.nombre}</td>
                            <td>{f.apellido}</td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}
