import React,{useState} from 'react'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import { pdfjs } from 'react-pdf';

import Spinner from 'react-bootstrap/Spinner'



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function InsPdf({file}) {
    
    const [numPages, setNumPages] = useState(0);
    

    function onDocumentLoadSuccess({numPages}){        
        setNumPages(numPages);        
    }

    return (
        <div style={{overflow:"auto"}}>
            <Document 
                file={file}
                renderMode="canvas" 
                onLoadSuccess={onDocumentLoadSuccess}                        
                loading={
                    <div>
                        <Spinner animation="border" variant="warning" role="status"/>
                        <span>Cargando PDF</span>            
                    </div>
                }
            >
                {Array.from({ length: numPages }, (_, index) => (
                    <Page renderTextLayer={false} scale={1} pageNumber={index + 1} key={index} />
                ))}                
            </Document>
        </div>
    )
}
