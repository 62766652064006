import React,{useState,useEffect} from 'react'
import {Form,FloatingLabel} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'
import {ImFolderDownload} from 'react-icons/im'

//Utils
import {getStandarDateFormat} from '../../Utils/getDates'
import {moneyFormat} from '../../Utils/numberFormat'
import loading from '../../Utils/loading'
import {getUrlApi} from '../../Utils/getUrl&Key'

//samples
import pagos from '../../samples/pagos.json'

const itemsPerPage = 20
export default function Pagos({datosPagos}) {
    const history = useHistory()
    
    const [filEstado, setFilEstado] = useState("");
    const [filComp, setFilComp] = useState("");
    const [filMoneda, setFilMoneda] = useState("");
    const [filData, setFilData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1); 

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedData = filData.slice(indexOfFirstItem, indexOfLastItem)
    
    const totalPages = Math.ceil(filData.length / itemsPerPage)
  
    useEffect(() => {        
        handleSearch()
    }, []);    
    
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const handleSearch = ()=>{
        let newData = [...datosPagos]
        if(filComp!==""){
            newData = newData.filter(item=>item.comprobante===filComp)
        }
        if(filEstado!==""){
            newData = newData.filter(item=>item.estado===filEstado)
        }
        if(filMoneda!==""){
            newData = newData.filter(item=>item.moneda===filMoneda)
        }
        setCurrentPage(1)
        setFilData(newData)
    }

    async function handleDownload(id){        
        loading("Descargando",false)
        const Url =  getUrlApi() + `/common/descargarfiles`       
        const data = {id}
        try{
            await  Axios.post(Url,data).then(res =>{               
                if(res.status === 200){                    
                    const arrayBuffer = res.data.buffer.data;
                    const extension = res.data.extension              //Obtiene el array de la respuesta
                    const name = res.data.name
                    const buffer = Buffer.from(arrayBuffer);             //Transforma el array en un Buffer
                    const url = URL.createObjectURL(new Blob([buffer])); //Crea un objeto Blob y una url a partir el objeto
                    const link = document.createElement('a');            //Crea un elemento html <a>
                    link.href = url;                                     //Asigna al atributo href la url creada
                    link.setAttribute('download', `${name}.${extension}`);           //Añade el atributo download al elemento <a>
                    document.body.appendChild(link);                     //Agrega el elemento <a> como hijo del Body
                    loading("",true)
                    link.click();                                        //Detona el evento click del elemento <a> iniciado la descarga
                    link.parentNode.removeChild(link)                    //Remueve el elemento <a> del Body


                   
                }else{
                    alert("Ha ocurrido un problemas.\nIntentar nuevamente mas tarde.")
                }
            })        
        }
        catch(error){
            const response = error.response
            if(response){
                if(response.status===403){
                alert("Su sesion ha caducado")
                history.replace("/logout")
                }else{
                    alert(`No se pueden decargar los archivos.\n${response.data.message}`)
                    loading("",true)
                }
            }else{                     
                alert(`No se pueden decargar los archivos.\n${error}`)
                loading("",true)
            }
        }
    }

  return (
    <div style={{display:"flex",width:"100%",justifyContent:"center",overflow:"auto"}}>
        <div style={{width:"80rem",maxWidth:"100%",height:"max-content"}}>
            <div className='whiteCard d-flex' >
                <Form.Select className='m-1' value={filComp}  onChange={(e)=>setFilComp(e.target.value)}>
                    <option value="">Selec. Comprobante</option>
                    <option value="B">Boleta</option>
                    <option value="R">Recibo</option>
                    <option value="F">Factura</option>                    
                </Form.Select>
                                
                <Form.Select className='m-1' value={filEstado}  onChange={(e)=>setFilEstado(e.target.value)}>
                    <option value="">Selec. Estado</option>
                    <option value="F">Facturado</option>
                    <option value="P">Pagado</option>
                    <option value="A">Anulado</option>                    
                </Form.Select>                
                
                <Form.Select className='m-1' value={filMoneda}  onChange={(e)=>setFilMoneda(e.target.value)}>
                    <option value="">Selec. Moneda</option>
                    <option value="USD">Dolares</option>
                    <option value="PEN">Soles</option>                    
                </Form.Select>
                
                <button className='btn red-button' onClick={handleSearch}>Buscar</button>                
            </div>
            <div className='whiteCard'style={{overflow:"auto"}}>
                <table className='table table-hover'>
                    <thead style={{fontSize:".8rem", textAlign:"center",backgroundColor:"var(--red-Color)",color:"white",height:"2.5rem"}}>
                        <tr>
                            <th><div>ID</div></th>
                            <th><div>Servicio</div></th>
                            <th><div>Servicio_ID</div></th>
                            <th><div>Comprobante</div></th>
                            <th><div>Monto</div></th>                        
                            <th><div>Fecha</div></th>
                            <th><div>Cliente</div></th>
                            <th><div>Estado</div></th>
                            <th><div>Codigo Fac.</div></th>
                            <th><div>Descargar</div></th>                        
                        </tr>
                    </thead>
                    <tbody style={{fontSize:".75rem", textAlign:"center"}}>
                        {paginatedData.map((item,index)=>(
                            <tr key={"r-"+index}>
                                <td>{item.id}</td>
                                <td>{item.servicio}</td>
                                <td>{item.servicioId}</td>
                                <td>{item.comprobante}</td>
                                <td>{moneyFormat(item.monto)} {item.moneda}</td>                                
                                <td>{getStandarDateFormat(item.fecha,"-")}</td>
                                <td>{item.cliente}</td>
                                <td>{item.estado}</td>
                                <td>{item.faccod}</td>
                                <td><ImFolderDownload style={{fontSize:"1.3rem",color:"var(--iziRed-Color)"}} onClick={()=>handleDownload(item.servicioId)}/></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div style={{display:"flex",justifyContent:"center"}}>
                <div className="whiteCard" style={{width:"max-content", display:"flex",justifyContent:"space-evenly"}}>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? 'activePB pageButton StolzlMedium' : 'pageButton'}
                    >
                    {index + 1}
                    </button>
                ))}
                </div>
            </div>

        </div>
    </div>
  )
}
