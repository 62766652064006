import React, {useState,useEffect} from 'react'
import {Form, FormLabel} from 'react-bootstrap'
import {useHistory,useLocation} from 'react-router-dom'
import loading from '../../Utils/loading'
import Axios from 'axios'
import * as crypto from 'crypto'

//Components
import PaymentIzy from './paymentIzy';


//Utils
import {getUrlApi,getIziKey} from '../../Utils/getUrl&Key'
const PK = "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA819PG46+WDAtz9zWKL8W\n9ubs2SkadUV+MKRwKXqw9eZV16ZNeIy/7dObXPKVJV9wQDCGU9cx9xTx4AZc2/wa\nhCcm6tQUA/IUMK4zVeW51l5DT+loJhR1jDDLA5jzNje/AC/DG0RmwuzGz31BDvdw\nxUmqHfCGavjQbvjsbljfYdHK/JrFmZZCmmbbJs5vFrjRR90N3yQKPzxRHcauFEcw\newcEEYBuI2nyTWd2mdXIIpU0nyZfDhE06YG68cKkzwBvq6NaHAhC/Ig3xw4sxqxk\nMnZTdENJPpBcfqmnU8FyKN7qeParj/+0pyw2K6j+tL/9v2aqJUCRYCWTJBZ4imto\nSwIDAQAB\n-----END PUBLIC KEY-----\n"

export default function Paymentreview({LOCAL_KEY}) {
    const history = useHistory()
    const location = useLocation()

    const storedData = JSON.parse(localStorage.getItem(LOCAL_KEY))
    const timeClick = sessionStorage.getItem("App.ts")
    const comprobante = storedData.comprobante
    const recibo = storedData.recibo    
    /* const [comprobante,setComprobante] = useState(storedData.comprobante) */
    /* const [recibo,setRecibo] = useState(storedData.recibo) */
    const [formToken,setFormToken] = useState("")
    const [documentoComprobante,setDocCom] = useState("")
    const [tipoComprobante,setTipoCom] = useState("")
    const [docRecibo,setDocRecibo] = useState("")
    useEffect(()=>{
        const local = JSON.parse(localStorage.getItem(LOCAL_KEY))       
        if(local.comprobante.documento==="D"){
            setDocCom("DNI")
        }else if(local.comprobante.documento==="CE"){
            setDocCom("CARNET EX.")
        }else if(local.comprobante.documento==="PS"){
            setDocCom("PASAPORTE")
        }
        if(local.recibo.documento==="D"){
            setDocRecibo("DNI")
        }else if(local.recibo.documento==="CE"){
            setDocRecibo("CARNET EX.")
        }else if(local.recibo.documento==="PS"){
            setDocRecibo("PASAPORTE")
        }
        if(local.comprobante.tipo==="B"){
            setTipoCom("BOLETA")
        }else if(local.comprobante.tipo==="F"){
            setTipoCom("FACTURA")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
          loading("Cargando pasarela de Pago...")        
          setFormToken(JSON.parse(localStorage.getItem("App.formToken")))

          const script = document.createElement('script');
            script.async = true;
            script.defer = true;
            script.src = 'https://api.micuentaweb.pe/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
            script.setAttribute('kr-public-key', getIziKey(undefined,comprobante.moneda==="PEN"?"PER":"USA"));
            document.head.appendChild(script);

            return()=>{
                const script = document.querySelector('script[kr-public-key]')
                document.head.removeChild(script);
                window.location.reload()
            }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    
    
    
    const handleSubmit = e => {
        e.preventDefault();       
    }

    async function handleComplete(card="",paymentMethodToken=null){
        loading("Procesando Pago")

        const encryptedMessage = crypto.publicEncrypt(
            { key: PK, padding: crypto.constants.RSA_PKCS1_PADDING },
            Buffer.from(atob(timeClick))
          ).toString('base64');
        
        const selected = location.state.poder
        const token = JSON.parse(localStorage.getItem("App.login")).token
        const Url = getUrlApi() + "/common/pagosatisfactorio"         
        const data = {
            id:selected,
            card:card,
            paymentMethodToken:paymentMethodToken,
            comprobante:comprobante,
            recibo:recibo,
            message:encryptedMessage
        }        
        const header = {
            "Content-Type":"application/json",
            "Authorization":token
        }
                
        try{
            await Axios.post(Url,data,{headers:header})
            .then(res=>{                                        
                if(res.status===201){                
                    setTimeout(
                    history.push({
                        pathname:"/main/servicios/complete",
                        state:{poder:selected,notificacion:res.data.email,servicio:storedData.servicio}
                    })
                    ,1000)
                    loading(undefined,true)
                }
            })
        }catch(error){
            const response = error.response
            if(response){
                if(response.status===403){
                loading("",true)    
                alert("Su sesión ha caducado")
                history.replace("/logout")
                }else{
                    alert(`Ha ocurrido un problema.\nPuede comunicarse con nosotros identificando su tramite ${location.state.poder} \n${error}`)
                    loading(undefined,true)
                    history.push({
                        pathname:"/main/contactenos",
                        state:`No he podido completar mi tramite ${location.state.poder}`,
                    })
                }
            }else{
                alert(`Ha ocurrido un problema.\nPuede comunicarse con nosotros identificando su tramite ${location.state.poder} \n${error}`)
                loading(undefined,true)
                history.push({
                    pathname:"/main/contactenos",
                    state:`No he podido completar mi tramite ${location.state.poder}`,
                })
            }
        }
    }

    return (
        <div style={{display:"flex",justifyContent:"center", minHeight:"calc(100vh - 3.675rem)",height:"max-content"}}>
            <div>                                
                <Form onSubmit={handleSubmit} className="paymentreview index2">            
                    <div className='mb-4'>
                        <FormLabel style={{fontWeight:"600"}}>Datos de la {tipoComprobante}</FormLabel>                
                        {comprobante.tipo==="B"?<>
                        <div>{`Doc. Identidad: ${documentoComprobante} - ${comprobante.numerodoc}`}</div>
                        <div>{`Nombre:  ${comprobante.nombre} ${comprobante.apellido}`}</div>
                        <div>{`Teléfono: ${comprobante.telefono}`}</div>
                        <div>{`Correo: ${comprobante.email}`}</div>
                        </>
                        :<><div>{`RUC: ${comprobante.numerodoc}`}</div>
                        <div>{`Razón Social: ${comprobante.razonsocial}`}</div>
                        <div>{`Dirección: ${comprobante.direccion}`}</div></>}
                    </div>
                    {recibo.monto!==0?
                        <div className='mb-4'>
                            <FormLabel style={{fontWeight:"600"}}>Datos del Recibo</FormLabel>                
                            <div>{`Doc. Identidad: ${docRecibo} - ${recibo.numerodoc}`}</div>
                            <div>{`Nombre:  ${recibo.nombre} ${recibo.apellido}`}</div>                    
                        </div>:null
                    }         
                    <div style={{display:"flex",justifyContent:"space-between"}}>                
                        {formToken?<PaymentIzy loading={loading} formToken={formToken} handleComplete={handleComplete} moneda={comprobante.moneda==="PEN"?"PER":"USA"}/>:null}
                        <button className="btn red-button" onClick={()=>{history.push({pathname:"/main/payment",state:location.state})}}>Regresar</button>
                    </div>
                </Form>
            </div>
        </div>        
    )
}
