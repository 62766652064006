import ReactDOM from 'react-dom'
import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

const loading = (loadingText="Procesando...",close=false) => {
    const parentElement = document.getElementById("App")

    if(!close){        
        const container = document.createElement("div")
        container.id = "loadingContainer"
        container.className = "customAlertContainer"    
        
        parentElement.appendChild(container)
        
        return ReactDOM.render(
            <div>
                <div>
                    <div style={{transform:"scale(1.5)", display:"flex",justifyContent:"center", margin:"2rem"}}>
                        <Spinner animation="border" variant="warning" role="status"/>
                    </div>            
                    <div style={{fontSize:"1.5rem"}}>
                        <span style={{color:"white"}}>{loadingText}</span>
                    </div>
                </div>
            </div>,container
        )
               
        
    }else{
        const container = document.getElementById("loadingContainer")
        ReactDOM.unmountComponentAtNode(container)
        container.parentNode.removeChild(container)        
        return null    
    }
    
}
export default loading