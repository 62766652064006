import React,{useState} from 'react';
import {FloatingLabel, Form,FormLabel} from 'react-bootstrap'
import {useHistory} from 'react-router-dom';
import Axios from 'axios'

//Assets
import Patterm from './../../../Assets/BackgroundImages/WhitePattermSmall.svg';
import LogoForMail from '../../../Assets/Logos/logoTramitec.png' //Imagen en el proyecto para que este en la carpteta static del build

//Utils
import {checkmail} from '../../../Utils/checkmail'
import {getUrlApi} from '../../../Utils/getUrl&Key';

export default function LoginpswRecovery() {
    const history = useHistory()

    const [email,setEmail] = useState("")
    const [creation,setCreation] = useState("");
    const handleSubmit = e => {
        e.preventDefault();       
    }

    async function handleConfirm(){
        if(checkmail(email)){
            const Url = getUrlApi() + "/users/olvideContrasena"
            const data = {email:email}
            try{
            await Axios.post(Url,data).then(res=>{
                
                if(res.status==="201"){
                    alert(res.data.message)
                }
            })}
            catch(error){
                if(error.response){
                    alert(`Ha ocurrido un problema. Porfavor intentar nuevamenta mas tarde.\n${error.response.message}`)
                }
                
            }

        }else{            
            setCreation("Formato de Correo no valido")
        }
    }

  return (
  <div 
    style={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"100vh",
        backgroundColor:"var(--red-Color)",        
        }}
    >
      <div className='fade-in' style={{position:"relative",zIndex:"2",}}>
        <div style={{display:"flex", justifyContent:"center"}}>
            <img src={LogoForMail} alt="Logo Tramitec" style={{height:"5rem",cursor:"pointer"}} onClick={()=>history.push("/")}/>
        </div>
        <Form onSubmit={handleSubmit}
            style={{
                width:"28rem",
                padding:"2rem 2.5rem",
                backgroundColor:"white",
                borderRadius:"0.5rem"
            }}
        >
            <div className='mb-3'>
                <div className='StolzlMedium'>Recuperacion de Contraseña</div>
            </div>
            <FloatingLabel label="Correo Registrado">
                    <Form.Control type="text" placeholder='Correo Registrado' value={email} onChange={e=>setEmail(e.target.value)}/>
            </FloatingLabel>
            <div className='mt-3' style={{display:"flex", justifyContent:"center"}}>
                <button className='btn green-button' onClick={()=>handleConfirm()}>Enviar solicitud</button>
            </div>
            <div className='mt-5'>
                <div style={{cursor:"pointer"}} onClick={()=>{history.push("/login",{step:1})}}>Volver</div>
            </div>
            <div style={{display:"flex",justifyContent:"center",marginTop:"1rem"}}>
                <FormLabel style={{marginLeft:"0.5rem", color:"red"}}>{creation}</FormLabel>                        
            </div>            
        </Form>
      </div>
      <div style={{
                position:"fixed",
                top:"0",
                right: "0",
                zIndex: "0"}}
                className = "fade-in"
            >
                <img 
                    src={Patterm}
                    alt="background_patter"                    
                    style={{
                        height:"100vh",
                        opacity:"0.4"
                    }}
                ></img>
        </div>  
  </div>
  )
}
