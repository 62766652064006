import ReactDOM from 'react-dom'
import React from 'react'
import {Alert,Button} from 'react-bootstrap'

const CustomAlert = (callback=()=>{},header="Alerta!",message="Mensaje de Alerta!") => {
    const parentElement = document.getElementById("App")
    const container = document.createElement("div")

    container.className = "customAlertContainer"    
    parentElement.appendChild(container)
 
    function handleConfirm(){
        callback()
        handleClose()
    }

    function handleClose(){
        ReactDOM.unmountComponentAtNode(container)
        container.parentNode.removeChild(container)        
    }
    
    return ReactDOM.render(
        <Alert variant="light" className="customAlert mx-auto"  onClose={()=>handleClose()} dismissible>
            <Alert.Heading>{header}</Alert.Heading>
            {message.split("\n").map(line=><p style={{textAlign:"justify"}} key={line[1]+"L"+line[0]}>{line}</p>)}
            <hr/>
            <div className="d-flex justify-content-end">
                <Button variant="outline-success" onClick={()=>handleConfirm()}>Aceptar</Button>
            </div>
        </Alert>,container
    )
    
}
export default CustomAlert