import Axios from 'axios'

//92.205.24.231
//192.168.195.77

const VERSION = "App.version"
export default function trackVersion() {  
   const appVersion = localStorage.getItem(VERSION)
  const url = "https://tramitec.pe/api/common/version"

  Axios.get(url).then(res=>{    
    const vr = res.data.numero    
    if(!appVersion||appVersion!=vr){      
      localStorage.setItem(VERSION,vr)  
      window.location.reload(true)
    }
  })
}
