import React,{useEffect,useState,useRef} from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search';
import Axios from 'axios'


//Utils
import customAlert from '../../Utils/alert'
import {getUrlApi} from '../../Utils/getUrl&Key'
import {checkmail} from '../../Utils/checkmail'
import PhoneCodes from '../../Utils/phoneInput'

//Icons
import {MdModeEdit,MdDelete} from 'react-icons/md'


let colored = {border:"0.1rem solid red"}
let regular = {color: "black"}
let OpPais = [    
    {name:"Australia",value:"AUS"},
    {name:"Canada",value:"CAN"},
    {name: "España",value:"ESP"},
    {name:"Estados Unidos", value: "USA"},
    {name:"Francia",value:"FRA"},
    {name:"Japón",value:"JPN"},
    {name:"México",value:"MEX"},
    {name:"Nueva Zelanda",value:"NZL"},
    {name:"Perú",value:"PER"}        
  ]

const infoMisPersonas = "Esta característica  te permite ahorrar tiempo y esfuerzo al rellenar formularios en TRAMITEC. Solo tienes que completar los campos requeridos una vez para registrar a la personas que desees y luego utilizarla automáticamente en otros formularios."

export default function MyPersonas() {

  const [personas, setPersonas] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [onEditID, setOnEditID] = useState(0);

  const [complete,setComplete] = useState(true)
  const [errormsg,setErrormsg] = useState("")

  const [states,setStates] = useState([])

  const [documento, setDocumento] = useState("");
  const [docnumero, setDocnumero] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellidop, setApellidop] = useState("");
  const [apellidom, setApellidom] = useState("");
  const [pais, setPais] = useState("");
  const [estado, setEstado] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [codigopostal, setCodigopostal] = useState("");
  const [direccion, setDireccion] = useState("");    
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [estadocivil, setEstadocivil] = useState("");
  const [ocupacion, setOcupacion] = useState("");

  const formRef = useRef(null)
  const idusuario = JSON.parse(localStorage.getItem("App.login")).id

  useEffect(() => {
      getPersonas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(pais!==""){
    getStates(pais)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pais]);

  useEffect(()=>{
    if(formRef.current){
      const position = formRef.current.getBoundingClientRect()     
      const scrollTo =  Math.abs(position.top)-window.innerHeight;
      window.scrollTo(0,scrollTo)
    }
  },[showForm])

  async function getStates(country){
    const Url = getUrlApi() + `/common/estados/${country}`     
    let data
    let orderedStates = []
    try{       
      await Axios.get(Url)
      .then( res=>{
          if (res.status===200){ 
              data = res.data
              orderedStates = []
              data.map(item => {
                orderedStates.push({name:item.estado,value:item.id})
                return null
                })
              setStates(orderedStates)
          }
      })
    }
    catch(error){
      console.log(error)
    }    
  }  

  async function getPersonas(){
    const url = getUrlApi() + `/common/perfil/${idusuario}`
    await Axios.get(url).then(res=>{
        setPersonas(res.data)        
    })
  }

  

  function revisar(){
    let errorCount = 0
    const PerData = [
        documento,docnumero,nombre,apellidop,apellidom,
        pais,estado,ciudad,codigopostal,direccion,
        telefono,email,estadocivil,ocupacion]

    const isComplete = PerData.every(item=>item!=="")        
    if(!isComplete){
        errorCount += 1
        setComplete(false)
        setErrormsg("Completar todos los datos")
    }else{

      //Se verifica si la persona no esta repetida
      if(personas.length){ //Solo se reliza la verificacion de localpod existe (si exite la data en el local storage)
          const existe = personas.filter(pod=> pod.documento+pod.docnumero === documento+docnumero)
          if(existe.length>0&&!onEdit){            
          setErrormsg("Persona ya esta registrado")
          errorCount+=1
          }        
      }    

      if(docnumero.length!==8&&documento==="D"){
          setErrormsg("El DNI debe contener 8 digitos")
          errorCount+=1
        }

      if(!checkmail(email)){
          setErrormsg("Formato de Correo no Valido")
          errorCount+=1
      }  
          
      if(errorCount===0){
          if(!onEdit){
              addPersona()
          }else{
              updatePersona()
          }        
      }
    }
  }

  function cleanForm(){
    setDocumento("")
    setDocnumero("")
    setNombre("")
    setApellidop("")
    setApellidom("")
    setPais("")
    setEstado("")
    setCiudad("")
    setCodigopostal("")
    setDireccion("")      
    setTelefono("")
    setEmail("")
    setEstadocivil("")
    setOcupacion("")
    setComplete(true)
    setErrormsg("")
}

function justnumber(event){      
  if (Number.isNaN(parseInt(event.key))){
      event.preventDefault()
  }            
}

function checkdocnumero(event){
    if(documento==="D"){      
        if (Number.isNaN(parseInt(event.key))){
            event.preventDefault()
        }           
    }else if(documento==="PS"){
      var regex = new RegExp("^[0-9a-zA-Z]+$");
      if(!regex.test(event.key)){
        event.preventDefault()
      }
    } 
  } 

  function addPersona(){        
    try {
      const idusuario = JSON.parse(localStorage.getItem("App.login")).id
      const data = {
        idusuario,
        documento,
        docnumero,
        nombre:nombre.toLocaleUpperCase(),
        apellidop:apellidop.toLocaleUpperCase(),
        apellidom:apellidom.toLocaleUpperCase(),              
        pais,
        estado,
        ciudad:ciudad.toLocaleUpperCase(),
        codigopostal,
        direccion:direccion.toLocaleUpperCase(),
        telefono,
        email:email.toLocaleUpperCase(),
        estadocivil,
        ocupacion:ocupacion.toLocaleUpperCase()
      }
      const url = getUrlApi() + `/common/perfil`
      Axios.post(url,data).then(()=>{
        getPersonas()
        setShowForm(false)
        setOnEdit(false)            
        cleanForm()              
      })
      } catch (error) {
        console.log(error.response)
      } 
  }

  async function deletePersona(idperfil){    
      try {
        const url = getUrlApi()+"/common/perfil_delete"
        const datos = {idperfil}
        await Axios.post(url,datos).then(res=>
          getPersonas()  
        )
      } catch (error) {
        console.log(error.response)        
      }
  }

  async function updatePersona(){
    try {
      const url = getUrlApi() + "/common/update_perfil"
      const datos = {
        idperfil:onEditID,
        documento,
        docnumero,
        nombre:nombre.toLocaleUpperCase(),
        apellidop:apellidop.toLocaleUpperCase(),
        apellidom:apellidom.toLocaleUpperCase(),
        pais,
        ciudad:ciudad.toLocaleUpperCase(),
        estado,
        codigopostal,
        direccion:direccion.toLocaleUpperCase(),    
        telefono,
        email:email.toLocaleUpperCase(),
        estadocivil,
        ocupacion:ocupacion.toLocaleUpperCase()
      }
      await Axios.post(url,datos).then(()=>{       
        setShowForm(false)
        setOnEdit(false)
        setOnEditID(0)            
        cleanForm() 
        getPersonas()
      })
    } catch (error) {
      console.log(error.response)
    }    
}

function editPersona(documento,docnumero,personaId){
  const index = personas.findIndex(per=>per.documento===documento&&per.docnumero===docnumero)
  setShowForm(true)
  setOnEdit(true)
  setOnEditID(personaId)
  setDocumento(personas[index].documento)
  setDocnumero(personas[index].docnumero)
  setNombre(personas[index].nombre)
  setApellidop(personas[index].apellidop)
  setApellidom(personas[index].apellidom)
  setPais(personas[index].pais)
  setEstado(personas[index].estado)
  setCiudad(personas[index].ciudad)
  setCodigopostal(personas[index].codigopostal)
  setDireccion(personas[index].direccion)
  
  setTelefono(personas[index].telefono)
  setEmail(personas[index].email)
  setEstadocivil(personas[index].estadocivil)
  setOcupacion(personas[index].ocupacion)
}



  return (
    <div className='whiteCard StolzlMedium'>
      <div style={{display:"flex",justifyContent:"space-between"}}>
        <div>Mis Personas:</div>
        <div>
          <div 
            className='circle-small Amaranth-Italic' 
            style={{backgroundColor:"var(--red-Color)",color:"white",cursor:"pointer"}}
            onClick={()=>customAlert(undefined,'¿Que son "Mis Personas"?',infoMisPersonas)}
          >i</div>
        </div>
      </div>

      <div>
        <button className='btn blue-button' onClick={()=>setShowForm(true)}>Agregar Persona</button>
        <div>
        {showForm?
            <div ref={formRef} className='customBoxShadow' style={{margin:".5rem 1rem",padding:"1rem",borderRadius:"0.5rem"}}>
            Datos Personales
            <hr style={{margin:"0 0 0.5rem"}}/>

            <div className="form-floating" >
              <select                
                className="form-select form-select-md mb-3"
                id="floatingInput" 
                placeholder="documento" 
                name="documento" 
                onChange={(e)=>setDocumento(e.target.value)} 
                value={documento}
                style={complete? regular:documento===""? colored:regular}
              >
                <option value="">Seleccionar</option>
                <option value="D">DNI</option>
                <option value="PS">Pasaporte</option>
              </select>
              <label htmlFor="floatingInput">Documento</label>
            </div>

            <div className="form-floating">
              <input
                disabled = {documento===""} 
                type='text'
                className="form-control mb-3"
                id="floatingInput"
                name="docnumero"
                placeholder="Numero"
                onKeyPress={(e)=>checkdocnumero(e)}
                onChange={e=>setDocnumero(e.target.value)}
                value={docnumero}
                style={complete ? regular:docnumero===""? colored:regular}
              />
              <label htmlFor="floatingInput">Número de Documento</label>
            </div>

            <div className="form-floating">
              <input 
                type='text' 
                className="form-control mb-3 uppercase-input" 
                id="floatingInput" name="nombre" 
                placeholder="Nombres" 
                onChange={e=>setNombre(e.target.value)}                
                value={nombre}
                style={complete ? regular:nombre===""? colored:regular}
              />
              <label htmlFor="floatingInput">Nombres</label>
            </div>

            <div className="form-floating">
              <input 
                type='text' 
                className="form-control mb-3 uppercase-input" 
                id="floatingInput" name="apellidop" 
                placeholder="Apellido Paterno" 
                onChange={e=>setApellidop(e.target.value)}
                value={apellidop}
                style={complete ? regular:apellidop===""? colored:regular}
              />
              <label htmlFor="floatingInput">Apellido Paterno</label>
            </div>

            <div className="form-floating">
              <input 
                type='text' 
                className="form-control mb-3 uppercase-input" 
                id="floatingInput" 
                name="apellidom" 
                placeholder="Apellido Materno" 
                onChange={e=>setApellidom(e.target.value)}
                value={apellidom}
                style={complete ? regular:apellidom===""? colored:regular}
              />
              <label htmlFor="floatingInput">Apellido Materno</label>              
            </div>

            Dirección
            <hr style={{margin:"0 0 .5rem"}}/>

            <div className="mb-3 GraphikR">
              <SelectSearch 
                options={OpPais} 
                search
                value={pais} 
                filterOptions={fuzzySearch} 
                autoComplete="new-password"
                placeholder="Seleccionar País" 
                onChange={(value)=>{
                  setPais(value)
                  setEstado("")
                }}
              />
            </div>

            <div className="mb-3 GraphikR">
              <SelectSearch  options={states} search filterOptions={fuzzySearch} autoComplete="new-password"
              placeholder="Estado" value={estado} 
              onChange={(value)=>setEstado(value)}/>
            </div>

            <div className="form-floating">
              <input type='text' className="form-control mb-3 uppercase-input" id="floatingInput" 
              name="ciudad" placeholder="ciudad" onChange={e=>setCiudad(e.target.value)} value={ciudad} 
              style={complete ? regular:ciudad===""? colored:regular}
            />
              <label htmlFor="floatingInput">Ciudad</label>
            </div>

            <div className="form-floating">
              <input type='text' className="form-control mb-3" id="floatingInput" onKeyPress={e=>justnumber(e)} onPaste={e=>e.preventDefault()}
              name="codigopostal" placeholder="codPostal" onChange={e=>setCodigopostal(e.target.value)} value={codigopostal} autoComplete="off"
              style={complete ? regular:codigopostal===""? colored:regular}
            />
              <label htmlFor="floatingInput">Código Postal</label>
            </div>


            <div className="form-floating">
              <input type='text' className="form-control mb-3 uppercase-input" id="floatingInput" 
              name="direccion" placeholder="direccion" onChange={e=>setDireccion(e.target.value)} value={direccion} 
              style={complete ? regular:direccion===""? colored:regular}
            />
              <label htmlFor="floatingInput">Dirección</label>
            </div>

            Contacto
            <hr style={{margin:"0 0 .5rem"}}/>
            
            <div style={{display:"flex",justifyContent:"space-between"}}>
              <PhoneCodes saveValue={setTelefono} currentValue={telefono}/>
            </div>
            
            

            <div className="form-floating">
              <input 
                type='text' 
                className="form-control mb-3" 
                id="floatingInput" 
                name="email" 
                placeholder="Email" 
                onChange={e=>setEmail(e.target.value)} 
                value={email}
                style={!complete&&email===""?colored:regular}/>
              <label htmlFor="floatingInput">Email</label>
            </div>

            <div className="form-floating">
              <select 
                className="form-select form-select-md mb-3" id="floatingInput" 
                placeholder="Estado Civil" name="estadocivil" 
                onChange={e=>setEstadocivil(e.target.value)} value={estadocivil}
                style={(!complete&&estadocivil==="")?colored:regular}
              >
                <option value="">Seleccionar</option>
                <option value="07">SOLTERO(A)</option>
                <option value="08">CASADO(A)</option>
                <option value="09">VIUDO(A)</option>
                <option value="10">DIVORCIADO(A)</option>
              </select>
              <label htmlFor="floatingInput">Estado Civil</label>
            </div>

            <div className="form-floating">
              <input 
                type='text' 
                className="form-control mb-3 uppercase-input" 
                id="floatingInput" 
                name="ocupacion" 
                placeholder="Ocupación" 
                onChange={e=>setOcupacion(e.target.value)} 
                value={ocupacion}
                style={(!complete&&ocupacion==="")?colored:regular}/>
              <label htmlFor="floatingInput">Ocupación</label>
            </div>

            <div style={{textAlign:"center",color:"red"}}><p>{errormsg}</p></div>  
            <div style={{display:"flex",justifyContent:"space-around"}}>
              <button className='btn red-button'
                onClick={()=>{
                    setShowForm(false)
                    setOnEdit(false)
                    setOnEditID(0)
                    cleanForm()
                  }
                }                
              >Cancelar</button>
              
              <button className='btn blue-button' 
                onClick={()=>{
                    revisar()
                  }
                }
              >Guardar</button>
            </div>
          </div>   
          :null
          }
          {personas.length>0?personas.map(per=>{return(
              <div key={per.docnumero} className='customBoxShadow formSavedCards'>
                <div style={{width:"85%"}}>{per.nombre} {per.apellidop}</div>
                <div style={{width:"15%",fontSize:"1.2rem",display:"flex",justifyContent:"space-between"}}>
                  <MdModeEdit onClick={()=>editPersona(per.documento,per.docnumero,per.idperfil)} style={{color:"navy", cursor:"pointer"}}/>
                  <MdDelete onClick={()=>deletePersona(per.idperfil)} style={{color:!onEdit?"darkred":"gray", cursor:"pointer"}}/>
                </div>
              </div>
            )}
          ):null}
        </div>        
      </div>

      
    </div>
  )
}
