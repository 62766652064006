import React from 'react'

export default function AdminSeleccion() { 
  
  return (
    <div className='d-flex justify-content-center' style={{width:"100%"}}>
       <div className='d-flex justify-content-center' style={{width:"30rem"}}>
        <div className='whiteCard'>
          Administrador de Tramitec
        </div>
       </div>
    </div>
  )
}
