import React,{useState,useEffect} from 'react'

//Utils
import { getMorphedDate } from '../../../Utils/getDates'

export default function BanFilter({rawData={},setFilData=()=>{},update}) {
    const [myUpdate, setMyUpdate] = useState(update)
    const [filTramId, setFilTramId] = useState("")    
    const [filDate, setFilDate] = useState("")

    if(myUpdate!==update){
      setMyUpdate(update)
    }

    
     useEffect(()=>{       
        let newData = rawData        
        if(filTramId!==""){          
        newData = newData.filter(item=>item.id===Number(filTramId))} // eslint-disable-next-line react-hooks/exhaustive-deps
        if(filDate!==""){
        newData = newData.filter(item=>item.fechatramite === getMorphedDate(filDate,undefined))} // eslint-disable-next-line react-hooks/exhaustive-deps
        
        setFilData(newData) // eslint-disable-next-line react-hooks/exhaustive-deps
    },[rawData])

    const handleSearch = ()=>{
        let newData = rawData
        
        if(filTramId!==""){
        newData = newData.filter(item=>item.id===Number(filTramId))}
        if(filDate!==""){
        newData = newData.filter(item=>item.fechatramite === getMorphedDate(filDate,undefined))}
        
        setFilData(newData)
    }
       

  return (
    <div className="historySearch" >
        <div>                                           
            <input placeholder="Número Tramite" type="number" className="hSearchFilter" onChange={e=>setFilTramId(e.target.value.toUpperCase())}/>            
            <input type="date" className="hSearchFilter" onChange={(e)=>{setFilDate(e.target.value)}}></input>
            <button className='btn red-button' onClick={handleSearch}>Buscar</button>
        </div>
    </div>
  )
}
