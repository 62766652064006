import React,{useState,useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import Axios from 'axios'

//Components
import MiniBlogEntry from './miniBlogEntry'

//Icons
import {MdSearchOff} from 'react-icons/md'

//Assets
import standarMiniature from '../../Assets/Logos/tramitecIconLogo.jpg'
import BlogLogo from '../../Assets/Logos/logoBlogBlack.png'

//Utils
import {getUrlApi} from '../../Utils/getUrl&Key'
const itemsPerPage = 1
export default function Blog() {
    const location = useLocation()
    const [blogs, setBlogs] = useState({todos:[],recomendados:[],regulares:[]});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); 

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedData = blogs.recomendados.slice(indexOfFirstItem, indexOfLastItem)  
    

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const autoPageChange = ()=>{        
        setCurrentPage((prevLog)=>{
            if(prevLog < totalPages / itemsPerPage){
                return prevLog + 1 
            }else{
                return 1
            }
        })
    }

    useEffect(() => {
        window.scrollTo(0,0)        
        getListBlogs()
    }, []);

    useEffect(()=>{
        let pageChangeInterval
        if(totalPages){
            pageChangeInterval = setInterval(autoPageChange,20000)
        }
        return ()=>{
            clearInterval(pageChangeInterval)
        }
    },[totalPages])    

    async function getListBlogs(){
        const Url = getUrlApi() + "/blog/listado"
        await Axios.get(Url).then(res=>{
            const todos = res.data.respuesta2
            const recomendados = res.data.respuesta2.filter(blog=>blog.estado==="F")
            const regulares = res.data.respuesta2.filter(blog=>blog.estado==="V")
            setBlogs({todos,recomendados,regulares})            
            setTotalPages(Math.ceil(recomendados.length / itemsPerPage))
        })       
    }

    function DragDirection(e){
        console.log(e)
        const target =  e.target.getBoundingClientRect()
        console.log(e.pageX)
        const startX = e.clientX       
        
        const targetCenterX = target.left + target.width / 2

        
        if(startX>targetCenterX){
            setCurrentPage((prevLog)=>{
                if(prevLog < totalPages / itemsPerPage){
                    return prevLog + 1 
                }else{
                    return 1
                }
            })
            
        }else{
            setCurrentPage((prevLog)=>{
                if(prevLog > 1){
                    return prevLog - 1 
                }else{
                    return totalPages
                }
            })
        }
        
        
        
    }

    const blogId = Number(location.pathname.replace("/main/blog/entry=",""))
    const activeBlog = blogs.todos.filter(blog=>blog.id===blogId).length > 0

    if(isNaN(blogId)){
        return(
            <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>                      
                <div style={{width:"60rem",maxWidth:"95%"}}>
                 
                <div className='d-flex justify-content-center mt-2' >                    
                    <div>
                        <img src={BlogLogo} alt="BlogLogo" style={{height:"3.5rem"}}/>                    
                        <hr/>
                    </div>
                </div>               

                {blogs.recomendados.length>0?
                    <div className='mt-2 mb-3 p-3'>                         
                        {paginatedData.map(blog=>
                            <div 
                                key={`blog-${blog.id}`} 
                                className='d-flex justify-content-center' 
                                draggable 
                                onDragStart={(e)=>DragDirection(e)}
                               
                            >
                                <MiniBlogEntry blog={blog} />
                            </div>                        
                        )}
                        
                        <div className='d-flex justify-content-center p-2'>
                            <div className='d-flex justify-content-around flex-wrap' >
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <button                                    
                                        key={`recom-${index}`}                                        
                                        className={`m-2 ${currentPage === index + 1 ? 'blogCarouselButton-Active':'blogCarouselButton'}`}                                        
                                        onClick={() => handlePageChange(index + 1)}                                        
                                    />
                                ))}
                            </div>
                        </div>
                    </div>:
                null}                

                {blogs.regulares.map(blog=>(                    
                    <div key={`blog-${blog.id}`} className='d-flex justify-content-center'>                        
                      <MiniBlogEntry blog={blog}/>
                    </div>                            
                    )                   
                )}
                </div>                   
            </div>
        )
    }
    else if(activeBlog){        
        const blog = blogs.todos.filter(blog=>blog.id===blogId)[0]    
        const myhtml = blog.contenido

        return (
            <div id="header" style={{height:"max-content"}}>
                <div style={{
                    height:"40vh",
                    width:"100%",
                    backgroundImage:`url(${blog.fcabecera})`,
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    
                }}
                >
                    <div style={{display:"grid",alignItems:"end",height:"100%",width:"100%",backgroundColor:blog.cabecera!==""?"rgba(0,0,0,.4)":"rgba(0,0,0,0)"}}>
                        <div id="title" style={{display:"flex",justifyContent:"center"}}>
                            <div style={{width:"50rem",maxWidth:"95vw"}}>
                                <div className=' pb-2'>
                                    <div className='BlogCategoria'>{blog.categoria}</div>
                                    <h1 style={{color:blog.cabecera!==""?"white":"black"}}>{blog.titulo}</h1>
                                    <div style={{color:blog.cabecera!==""?"white":"black"}}>{blog.autor}</div>
                                    <div style={{color:blog.cabecera!==""?"white":"black"}}>{blog.fecha_publicacion}</div>
                                </div>                  
                            </div>
                        </div>    
                    </div>                
                </div>
                <div id="content" style={{display:"flex",justifyContent:"center"}}>
                    <div style={{width:"50rem",maxWidth:"95%"}}>            
                        <div className='pt-4' style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: myhtml}}/>
                    </div>
                </div>
            </div>
        )
    }
    else{
        const recomendados = blogs        
        return (
            <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
                <div style={{maxWidth:"95%",padding:"5%"}}>
                    <div className='d-flex'>
                        <h1>Blog no encontrado</h1>
                        <MdSearchOff style={{fontSize:"3rem",color:"var(--iziRed-Color)",marginLeft:"1.3rem"}}/>                
                    </div>                           
                    <h4>El contenido que busca no existe o no esta</h4>
                    <h4>disponible en este momento.</h4>
                    <div className='p-3'>
                        <h5>Blogs Recomendados</h5>
                        <hr/>
                        <div className='d-flex justify-content-center'>{blogs.recomendados.map(blog=> 
                            blog.estado==="F"?<div className='whiteCard kenburns' style={{width:"10rem",marginLeft:".25rem",marginRight:".25rem", backgroundColor:"var(--bs-gray-200)"}}>
                                <div className='d-flex justify-content-center'>
                                    <img src={blog.fminiatura===""?standarMiniature:blog.fminiatura} alt="Miniatura" className='miniBlogMiniatura'/>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className='BlogCategoria'>{blog.categoria}</div>
                                </div>
                                <div style={{fontSize:".8rem"}}>
                                    <span>{blog.titulo}</span>                                
                                </div>
                            </div>:null
                        )}
                        </div>
                    </div>
                    
                </div>                       
            </div>
        )
    }
}
