import React,{useState,useEffect} from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import Axios from 'axios'

import {FloatingLabel,Form} from 'react-bootstrap'

//Assets
import standarMiniature from '../../Assets/Logos/tramitecIconLogo.jpg'

//Utils
import {getCurrentDate,getStandarDateFormat} from '../../Utils/getDates'
import { getUrlApi } from '../../Utils/getUrl&Key';



export default function BloggerMain() {       
    
    const [blogs, setBlogs] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [id, setId] = useState(0);
    const [titulo, setTitulo] = useState("");
    const [categoria, setCategoria] = useState("");
    const [autor, setAutor] = useState("");
    const [resumen, setResumen] = useState("");
    const [cabecera, setCabecera] = useState("");
    const [miniatura, setMiniatura] = useState("");
    const [editorHtml, setEditorHtml] = useState('')
    
    const [onEdit, setOnEdit] = useState(false);
    

    useEffect(() => {
        getListBlogs()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);    
    const handleEditorChange = (content) => {      
        
        if (content.includes('<iframe')) {
            setEditorHtml(content.replace("ql-video", "ql-video blogEntryVideo"))            
        }
        else if(content.includes('<img')) {
            setEditorHtml(content.replace("<img", "<img style='max-width:100%'"))
        }
        else if(content.includes('<a')){
            setEditorHtml(content.replace("<a", "<img class='blogEntryLink'"))
        }
        else{
            setEditorHtml(content);
        }
    }

    const handleLoadBlog = (blog) =>{    
        setShowForm(true)    
        setOnEdit(true)
        setId(blog.id)
        setTitulo(blog.titulo)
        setCategoria(blog.categoria)
        setAutor(blog.autor)
        setResumen(blog.resumen)
        setCabecera(blog.fcabecera)
        setMiniatura(blog.fminiatura)
        setEditorHtml(blog.contenido)        
    }

    function cleanForm(){
        setShowForm(false)
        setOnEdit(false)
        setId("")
        setTitulo("")
        setCategoria("")
        setAutor("")
        setResumen("")
        setCabecera("")
        setMiniatura("")
        setEditorHtml("")
    }

    async function getListBlogs(){
        const Url = getUrlApi() + "/blog/listado"
        await Axios.get(Url).then(res=>{            
            setBlogs(res.data.respuesta2)            
        })
    }

    async function saveBlog (){
        const Url = getUrlApi() + "/blog/registrar"
        const data ={
            titulo,
            autor,
            resumen,
            contenido:editorHtml,
            categoria,
            fcabecera:cabecera,
            pclaves:"",
            fminiatura:miniatura
        }
        try {
            await Axios.post(Url,data).then(res=>{
                alert(res.data.message)
                getListBlogs()
                cleanForm()
            })
        } catch (error) {
            alert(error.response.data.message)
        }
    }

    async function updateBlog(){
        const Url = getUrlApi() + "/blog/actualizar"
        const data ={
            id,
            titulo,
            autor,
            resumen,
            contenido:editorHtml,
            categoria,
            fcabecera:cabecera,
            pclaves:"",
            fminiatura:miniatura
        }
        try {
            await Axios.post(Url,data).then(res=>{
                alert(res.data.message)
                getListBlogs()
                cleanForm()
            })
        } catch (error) {
                alert(`No se ha podido actualizar el blog.\nError: ${error.response.data.message}`)
        }
    }

    async function handleHabilitar(id,estado){
        const Url = getUrlApi() + "/blog/estado"
        let newEstado
        if(estado==="V"||estado==="F"){
            newEstado="NV"
        }else{
            newEstado="V"
        }

        try {
            await Axios.post(Url,{id,estado:newEstado}).then(res=>{
                alert(res.data.message)
                getListBlogs()
            })
        } catch (error) {
            console.log(error)
        }
    }

    async function handleDestacado(id,estado){
        const Url = getUrlApi() + "/blog/estado"
        let newEstado
        if(estado!=="F"){
            newEstado="F"
        }else{
            newEstado="V"
        }
        try {
            await Axios.post(Url,{id,estado:newEstado}).then(res=>{
                alert(res.data.message)
                getListBlogs()
            })
        } catch (error) {
                console.log(error)
        }

    }



  return (
    <div style={{width:"100%"}} >        
        <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>
            <div>
                <div>
                    <div className='serviceTittle StolzlMedium'>Administración de Blogs</div>                    
                </div>        
                {showForm?<div style={{width:"45rem",maxWidth:"95%"}}>                    
                    <div className='whiteCard'>                    
                        <div>
                            Datos
                            <hr/>
                            <FloatingLabel label="Título" className='mb-2'>
                                <Form.Control value={titulo} onChange={e=>setTitulo(e.target.value)} placeholder='Título'/>
                            </FloatingLabel>
                            <div className='d-flex justify-content-between'>
                                <FloatingLabel label="Categoria" className='mb-2' style={{width:"45%"}}>
                                    <Form.Select value={categoria} onChange={e=>setCategoria(e.target.value)} placeholder='Categoria'>
                                        <option value=''>Seleccionar</option>
                                        <option value='Servicios'>Servicios</option>
                                        <option value='Novedades'>Novedades</option>
                                        <option value='Guías'>Guías</option>
                                    </Form.Select>
                                </FloatingLabel>

                                <FloatingLabel label="Autor" className='mb-2' style={{width:"45%"}}>
                                    <Form.Select value={autor} onChange={e=>setAutor(e.target.value)} placeholder='Autor'>
                                        <option value=''>Seleccionar</option>
                                        <option value='Jhon Doe'>Jhon Doe</option>
                                        <option value='María Rodríguez'>María Rodríguez</option>
                                        <option value='Carlos Gómez'>Carlos Gómez</option>
                                        <option value='Tramitec'>Tramitec</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </div>

                            <FloatingLabel label="Resumen" className='mb-4'>
                                <Form.Control as="textarea" style={{minHeight:"8rem"}} row={4} value={resumen} onChange={e=>setResumen(e.target.value)} placeholder='Resumen'/>
                            </FloatingLabel>

                            Imagenes
                            <hr/>
                            <FloatingLabel label="Cabecera(Link)"  className='mb-2'>
                                <Form.Control placeholder='cabecera(Link)' value={cabecera} onChange={e=>setCabecera(e.target.value)}/>
                            </FloatingLabel>

                            <FloatingLabel label="Miniatura(Link)"  className='mb-4'>
                                <Form.Control placeholder='Miniatura(Link)' value={miniatura} onChange={e=>setMiniatura(e.target.value)}/>
                            </FloatingLabel>
                            
                            Contenido
                            <hr/>                        
                            <ReactQuill
                                value={editorHtml}
                                modules={{toolbar: [
                                    [{ 'header': '1'}, {'header': '2'}],
                                    [{size: []}],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{'list': 'ordered'}, {'list': 'bullet'}, 
                                    {'indent': '-1'}, {'indent': '+1'}],
                                    ['link', 'image', 'video'],
                                    ['clean']
                                ]}}
                                onChange={handleEditorChange}
                            />
                            <div className='mt-2 d-flex justify-content-around'>
                                <button 
                                    className={`btn ${!onEdit?"green-button":"blue-button"}`}
                                    disabled={categoria===""||autor===""||titulo===""} 
                                    onClick={()=>{
                                        if(!onEdit){
                                            saveBlog()
                                        }else{
                                            updateBlog()
                                        }
                                        
                                    }}
                                >{!onEdit?"Guardar":"Actualizar"}</button>
                                <button className='btn red-button' onClick={()=>cleanForm()}>Cancelar</button>
                            </div>                        
                        </div> 
                    </div>
                </div>
                :
                <div style={{width:"60rem",maxWidth:"95%"}}>
                    <button className='btn red-button' onClick={()=>setShowForm(true)}>Crear Nueva Publicación</button>
                    <div>            
                        <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>        
                        {blogs.length>0?blogs.map(blog=>{return(
                        <div key={`blog-${blog.id}`} style={{width:"45%",minWidth:"20rem"}} className='m-2 whiteCard'>
                            <div key={`blog${blog.id}`} className='d-flex p-2' style={{fontSize:"0.8rem"}}>
                                <div>
                                    <img className='miniBlogMiniatura' alt='Miniatura' src={blog.fminiatura!==""?blog.fminiatura:standarMiniature}/>
                                </div>            
                                <div>                
                                    <div><strong>Titulo: </strong>{blog.titulo}</div>                        
                                    <div><strong>Categoria: </strong>{blog.categoria}</div>                
                                    <div><strong>Autor: </strong>{blog.autor}</div>
                                    <div><strong>Publicado: </strong>{getStandarDateFormat(blog.fecha)}</div>                
                                </div>
                            </div>
                            <div style={{fontSize:".8rem"}}><strong>Resumen: </strong>{blog.resumen}</div>
                            <div style={{fontSize:".8rem"}}><strong>Estado: </strong>{blog.estado}</div>
                            <div>
                                <button className='btn green-button m-1' onClick={()=>handleLoadBlog(blog)}>Editar</button>
                                <button className={`btn m-1 ${blog.estado==="V"||blog.estado==="F"?"red-button":"blue-button"}`} onClick={()=>handleHabilitar(blog.id,blog.estado)}>{blog.estado==="V"||blog.estado==="F"?"Deshabilitar":"Habilitar"}</button>
                            </div>
                            <div style={{fontSize:".8rem"}}>
                                <span><strong>Destacado: </strong></span>
                                <label className='switch' htmlFor={`blogDesc-${blog.id}`}>
                                    <input type="checkbox" checked={blog.estado==="F"}  id={`blogDesc-${blog.id}`} onChange={()=>{handleDestacado(blog.id,blog.estado)}}/>
                                    <span className="slider"></span>
                                </label>                    
                            </div>
                        </div>
                        )}
                        ):null}
                        </div>
                    </div>
                </div>}
            </div>
        </div>
        
        {showForm?<div className='mt-5'>
        <h3>Vista Previa</h3>
        <div>
            <div className='d-flex justify-content-center'>            
                <div className='d-flex miniBlogEntry'>
                    <div className='miniBlogSection mbsLeft'>
                        <div style={{textAlign:"center"}}><img src={miniatura===""?standarMiniature:miniatura} alt="Miniatura" className='miniBlogMiniatura'/></div>
                        <div className='BlogCategoria'>{categoria}</div>
                    </div>
                    <div className='miniBlogSection mbsRight' >
                        <h4 className='miniBlogTitle'>{titulo}</h4>
                        <p className='miniBlogResume'>{resumen}</p>
                        <div className='miniBlogFooter'>
                            <div>Autor: {autor}</div>
                            <div>Publicado: {getCurrentDate("-")}</div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="header" style={{height:"max-content"}} className='customBoxShadow'>
                <div style={{
                    height:"35vh",
                    width:"100%",
                    backgroundImage:`url(${cabecera})`,
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    
                }}
                >
                    <div style={{display:"grid",alignItems:"end",height:"100%",width:"100%",backgroundColor:cabecera!==""?"rgba(0,0,0,.4)":"rgba(0,0,0,0)"}}>
                        <div id="title" style={{display:"flex",justifyContent:"center"}}>
                            <div style={{width:"50rem",maxWidth:"95vw"}}>
                                <div className=' pb-2'>
                                    <div className='BlogCategoria'>{categoria}</div>
                                    <h1 style={{color:cabecera!==""?"white":"black"}}>{titulo}</h1>
                                    <div style={{color:cabecera!==""?"white":"black"}}>{autor}</div>
                                    <div style={{color:cabecera!==""?"white":"black"}}>{getCurrentDate("-")}</div>
                                </div>                  
                            </div>
                        </div>    
                    </div>                
                </div>
                <div id="content" style={{display:"flex",justifyContent:"center"}}>
                    <div style={{width:"50rem",maxWidth:"95%"}}>            
                        <div className='pt-4' style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: editorHtml}}/>
                    </div>
                </div>
            </div>
        </div>
        </div>:null}
    </div>
  )
}
