import React,{useState,useEffect,useContext} from 'react'
import {Card,Button,Accordion, useAccordionButton,AccordionContext} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'

//Utils
import { getMorphedDate } from '../../../Utils/getDates'
import {getUrlApi} from '../../../Utils/getUrl&Key'

//Components
import ExtraInfo from './historialCollapse'
import Download from './historialDownload'

const SESSION_KEY = "App.login"
export default function MyUser() {   
         
    const history = useHistory()

    const [dropOpen,setDropOpen] = useState([{isOpen:false,key:0,why:"if this is not here this object is a boolean"}])
    const [historySer,setHistorySer] = useState([{id:-1,servicio:"0",fechatramite:""}])
    const [filService,setFilService] = useState("")
    const [filPod,setFilPod] = useState("")
    const [filApo,setFilApo] = useState("")
    const [filDate,setFilDate] = useState("")
    
      
    
    
    let usuario = JSON.parse(localStorage.getItem(SESSION_KEY))
    
    
    useEffect(()=>{                
        getHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])    
 

    async function getHistory(){
        const Url = getUrlApi() + `/common/tramites/${usuario.id}`
        try{
            await Axios.get(Url).then(res =>{
               if(res.status===200){                                                        
                   setHistorySer(res.data)
                                                        
               }else{setHistorySer("")}
            })           
        }
        catch(error){
            if(error.response){
                console.log(error.response.message)
            }
        }
    }   

    function textstate(estado){
        switch (estado){
            case "P":
                return "Pagado"
            case "A":
                return "Aprobado"
            case "R":
                return "Registrado"
            case "N":
                return "Rechazado"
            case "F":
                return "Completado"
            case "C":
                return "Doc. Recibido"
            default:
                return "Estado Desconocido"
        }
    }

    async function handleDownload(servicio,poder,tipo){
        const Url =  getUrlApi() + `/common/poder/file/${servicio}/${poder}/${tipo}`
        try{
            await  Axios.get(Url).then(res =>{
                console.log(res)
                if(res.status === 200){    
                    console.log(res)                
                    const arrayBuffer = res.data.file.data;
                    const extension = res.data.extension              //Obtiene el array de la respuesta
                    /* const arrayBuffer = res.data.rows[0].file.data
                    const extension = res.data.rows[0].extension */
                    const buffer = Buffer.from(arrayBuffer);             //Transforma el array en un Buffer
                    const url = URL.createObjectURL(new Blob([buffer])); //Crea un objeto Blob y una url a partir el objeto
                    const link = document.createElement('a');            //Crea un elemento html <a>
                    link.href = url;                                     //Asigna al atributo href la url creada
                    let filename
                    switch(tipo){
                        case "PCARTA":
                            filename = "Carta Instruccion"
                            break
                        case "PPDF":
                            filename = "Poder"
                            break
                        case "PWORD":
                            filename = "Poder"
                            break
                        case "IPDF":
                            filename = "Inscripcion"
                            break
                        default:
                            filename = "archivoTramitec"
                            break                            
                    }
                    link.setAttribute('download', `${filename}.${extension}`) //Añade el atributo download al elemento <a>
                    document.body.appendChild(link);                     //Agrega el elemento <a> como hijo del Body
                    link.click();                                        //Detona el evento click del elemento <a> iniciado la descarga
                    link.parentNode.removeChild(link)                    //Remueve el elemento <a> del Body
                }
            })        
        }
        catch(error){
            console.log(error.response)
            alert(`Ha ocurrido un problema, porfavor intentelo mas tarde.\n${error}`)
        }
    }  
    


    
    function filterHandle(){ 
        let newHistorydata = historySer                
        if(filService!==""){
        newHistorydata = newHistorydata.filter(item=>item.servicio===filService)}
        if(filPod!==""){            
        newHistorydata = newHistorydata.filter(item=>item.poderdantes.toLowerCase().includes(filPod.toLowerCase()))}
        if(filApo!==""){
        newHistorydata = newHistorydata.filter(item=>item.apoderados.toLowerCase().includes(filApo.toLowerCase()))}
        if(filDate!==""){
        newHistorydata = newHistorydata.filter(item=>item.fechatramite === getMorphedDate(filDate,undefined))}
        
        return newHistorydata
    }

    return (
        <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
            <div style={{width:"45rem",maxWidth:"95%"}}>
                <div>
                    <div className="historywelcome StolzlMedium">
                        <div>Tu resumen de procesos:</div>
                    </div>
                    <div className="historySearch" >
                        <div>
                            <select className="hSearchFilter" defaultValue="" onChange={e=>setFilService(e.target.value)}>
                                <option value="" style={{color:"gray"}}>Todos</option>
                                <option value="P">Otorgamiento</option>
                                <option value="P2">Inscripción</option>
                                <option value="TRAM">Tramitación de documentos</option>
                                <option value="APO">Apostillado</option>
                            </select>                   
                            <input placeholder="Poderdante" className="hSearchFilter" onChange={e=>setFilPod(e.target.value.toUpperCase())}/>
                            <input placeholder="Apoderado" className="hSearchFilter" onChange={e=>setFilApo(e.target.value)}></input>
                            <input type="date" className="hSearchFilter" onChange={(e)=>{setFilDate(e.target.value)}}></input>
                        </div>
                    </div>
                </div>
                {historySer[0].id===-1?<div className="historynone">No hay nada en el historial</div>:
                <div className="historyContainer customScroll">
                    {filterHandle().map(Item=>{
                             
                        return <Accordion key={Item.id} defaultActiveKey="0" className='mb-1'>
                            <Card>
                                <Card.Header style={{backgroundColor:"white"}}>
                                    <div style={{display:"flex"}}>
                                        <div style={{display:"flex", width:"15.5rem"}}>
                                            <div className="StolzlMedium historyText" style={{marginRight:".5rem"}}>
                                                <div>ID</div>
                                                <div>{Item.id}</div>                                            
                                            </div>   
                                            <div className="historyText">
                                                <div>
                                                    {Item.servicioDes}
                                                </div>
                                                <div style={{color:"gray"}}>
                                                    {Item.fechatramite}
                                                </div>
                                                <div>
                                                    {textstate(Item.estado)}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display:"flex", width:"calc(100% - 15.5rem)",justifyContent:"flex-end",alignItems:"center"}}>
                                            <div>
                                                {(Item.estado==="R"&&Item.servicio==="P")||(Item.estado==="A"&&(Item.servicio!=="P"||Item.servicio!=="PR"))?<Button
                                                    variant="none"
                                                    className="red-outlinebutton historyText"
                                                    onClick={()=>{
                                                        history.push({
                                                            pathname:"/main/payment",
                                                            state:{poder:Item.id,servicio:Item.servicio}
                                                            })
                                                        }}
                                                    >
                                                    Pagar
                                                </Button>:null}
                                            </div>
                                        </div>
                                    </div>
                                    
                                </Card.Header>
                                
                                {(Item.estado!=='R'&&Item.servicio==="P")||(Item.estado==='F'&&Item.servicio==="P2")?
                                <Download id={Item.id} estado={Item.estado} ubicacion={Item.moneda} servicio={Item.servicio} handleDownload={handleDownload}/>
                                :null}

                                {Item.servicio==="P"?
                                <Card.Footer className='p-0'>
                                    <div style={{display:"flex",justifyContent:"center"}}>
                                        <CustomToggle open={dropOpen} changeOpen={setDropOpen} eventKey={Item.id}/>
                                    </div>
                                </Card.Footer>
                                :null}
                            </Card>
                            
                            {Item.servicio==="P"?<ExtraInfo id={Item.id} poderdantes={Item.poderdantes} apoderados={Item.apoderados} facultades={Item.facultades}/>:null}
                        </Accordion>                    
                        }
                    )}                    
                </div>}
            </div>       
        </div>
    )
}

function CustomToggle({ children, eventKey}) {
    const {activeEventKey} = useContext(AccordionContext)
    const isCurrentEventKey = activeEventKey === eventKey

    const decoratedOnClick = useAccordionButton(eventKey, () =>{})
    return (
      <button 
        type="button"
        style={{ backgroundColor: "rgba(0,0,0,0)", border:"none" }}
        onClick={decoratedOnClick}
        className={isCurrentEventKey?"historymidrop-active":"historymidrop"}
      >
        {children}
      </button>
    );
}