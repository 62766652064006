import React,{useState,useEffect} from 'react'
import Axios from 'axios'
//Component
import CardInfo from '../otorgarObjects/reviewCardInfo'

//Icons
import {MdModeEdit,MdDelete} from 'react-icons/md'

//Utils
import AddMiPersona from '../../../../Utils/addMiPersona'
import {getUrlApi} from '../../../../Utils/getUrl&Key'

let colored = {border:"0.1rem solid red"}
let regular = {color: "black"}

export default function FormApo({navigation}) {
    const [complete,setComplete] = useState(true)
    const [errormsg,setErrormsg] = useState("")


    const [apoderados, setApoderados] = useState([]);    
    const [showForm, setShowForm] = useState(false)   
    const [onEdit, setOnEdit] = useState(false);
    const [documento, setDocumento] = useState("");
    const [docnumero, setDocnumero] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellidop, setApellidop] = useState("");
    const [apellidom, setApellidom] = useState("");

    const [listaPersonas, setListaPersonas] = useState([]);

    const user = JSON.parse(localStorage.getItem("App.login"))
    const ubicacion = sessionStorage.getItem("App.userLocation")
    const localpod = JSON.parse(localStorage.getItem(`App.poderdante.${ubicacion}`))
    const localapo = JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`))

    useEffect(()=>{
        getPersonas()
        if(localStorage.getItem(`App.apoderado.${ubicacion}`)){
            setApoderados(JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`)))            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{        
        if(apoderados.length===0){
            setShowForm(true)
        }else{
            setShowForm(false)
        }
    },[apoderados])


    async function getPersonas(){
        if(user){
            const url = getUrlApi() + `/common/perfil/${user.id}`
            await Axios.get(url).then(res=>{
                setListaPersonas(res.data)
            })
        }
        
      }
    
    function addApoderado(){        
        let newApos = apoderados     
        const ApoData = {
            documento,
            docnumero,
            nombre:nombre.toLocaleUpperCase(),
            apellidop:apellidop.toLocaleUpperCase(),
            apellidom:apellidom.toLocaleUpperCase()}
        newApos.push(ApoData)   
        setApoderados(newApos)
    }

    function editApoderado(documento,docnumero){
        const index = apoderados.findIndex(apo=>apo.documento===documento&&apo.docnumero===docnumero)
        setShowForm(true)
        setOnEdit(true)
        setDocumento(apoderados[index].documento)
        setDocnumero(apoderados[index].docnumero)
        setNombre(apoderados[index].nombre)
        setApellidop(apoderados[index].apellidop)
        setApellidom(apoderados[index].apellidom)     
    }

    function updateApoderado(){
        const index = apoderados.findIndex(apo=>apo.documento===documento&&apo.docnumero===docnumero)
        apoderados[index].documento=documento
        apoderados[index].docnumero=docnumero
        apoderados[index].nombre=nombre.toLocaleUpperCase()
        apoderados[index].apellidop=apellidop.toLocaleUpperCase()
        apoderados[index].apellidom=apellidom.toLocaleUpperCase()           
    }


    function deleteApoderado(documento,docnumero){
        if(!onEdit){
            let newApos = apoderados.filter(apo=>apo.documento+apo.docnumero!==documento+docnumero)
            setApoderados(newApos)
            if(newApos.length>0){
                localStorage.setItem(`App.apoderado.${ubicacion}`,JSON.stringify(newApos))
            }else{
                localStorage.removeItem(`App.apoderado.${ubicacion}`)
            }
        }
    }

    function cleanForm(){
        setDocumento("")
        setDocnumero("")
        setNombre("")
        setApellidop("")
        setApellidom("")        
        setComplete(true)
        setErrormsg("")
    }

    function checkdocnumero(event){
        if(documento==="D"){      
            if (Number.isNaN(parseInt(event.key))){
                event.preventDefault()
            }           
        }else if(documento==="PS"){
          var regex = new RegExp("^[0-9a-zA-Z]+$");
          if(!regex.test(event.key)){
            event.preventDefault()
          }
        } 
    }
    
    function revisar(){
        let errorCount = 0
        const ApoData = [
            documento,docnumero,nombre,apellidop,apellidom]

        const isComplete = ApoData.every(item=>item!=="")        
        if(!isComplete){
            errorCount += 1
            setComplete(false)
            setErrormsg("Completar todos los datos")
        }

        //Se verifica si el poderdante no esta repetido
      if(localpod){ //Solo se reliza la verificacion de localpod existe (si exite la data en el local storage)        
        const existe = localpod.filter(pod=> pod.documento+pod.docnumero === documento+docnumero)
        if(existe.length>0){                        
          setErrormsg("Ya esta registrado como Poderdante")
          errorCount+=1
        }        
      }

      if(localapo){
        const existe = localapo.filter(apo=> apo.documento+apo.docnumero === documento+docnumero)
        if(existe.length>0&&!onEdit){
          setErrormsg("El Apoderado ya esta registrado")
          errorCount+=1
        }
      }

        if(docnumero.length!==8&&documento==="D"){
            setErrormsg("El DNI debe contener 8 digitos")
            errorCount+=1
        }
            
        if(errorCount===0){
            if(!onEdit){
                addApoderado()
            }else{
                updateApoderado()
            }
            setShowForm(false)
            setOnEdit(false)            
            cleanForm()
            localStorage.setItem(`App.apoderado.${ubicacion}`,JSON.stringify(apoderados))
        }
        
    }


  return (
    <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
        <div style={{width:"30rem",maxWidth:"95%"}}>
            
            <div className='whiteCard StolzlMedium' >
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>
                        <h5>¿Quién recibe el poder?</h5>                        
                    </div>
                    <div>                        
                        <AddMiPersona formType='apo' listaPersonas={listaPersonas} updateList={setApoderados}/>                        
                    </div>
                </div>               
                
                <div>
                    <div style={{width:"100%"}}>
                        {apoderados.length>0?apoderados.map(apo=>{return(
                            <div key={apo.docnumero} className='customBoxShadow formSavedCards'>
                                <div className='d-flex'>
                                    <div style={{width:"85%"}}>{apo.nombre} {apo.apellidop}</div>
                                    <div style={{width:"15%",fontSize:"1.2rem",display:"flex",justifyContent:"space-between"}}>
                                        <MdModeEdit onClick={()=>editApoderado(apo.documento,apo.docnumero)} style={{color:"navy", cursor:"pointer"}}/>                                        
                                        <MdDelete onClick={()=>deleteApoderado(apo.documento,apo.docnumero)} style={{color:!onEdit?"darkred":"gray", cursor:"pointer"}}/>
                                    </div>
                                </div>
                                <CardInfo personType="APO" data={apo} noEdit={true}/>
                            </div>
                            )}
                        ):null}
                        {apoderados.length>=1&&!showForm?
                            <div className='d-flex formSavedCards customBoxShadow justify-content-between'>
                                <div className='d-grid align-content-center'>
                                    <span>Agregar apoderado adicional: </span>
                                </div>
                                <div className='circle-medium red-button' onClick={()=>setShowForm(true)}>+</div>
                            </div>
                        :null}
                    </div>
                    {showForm?
                        <div className='customBoxShadow' style={{margin:".5rem 1rem",padding:"1rem",borderRadius:"0.5rem"}}>                                                       
                            <div className="form-floating" >
                                <select
                                    disabled = {onEdit} 
                                    className="form-select form-select-md mb-3"
                                    id="floatingInput" 
                                    placeholder="documento" 
                                    name="documento" 
                                    onChange={(e)=>setDocumento(e.target.value)} 
                                    value={documento}            
                                    style={complete? regular:documento===""? colored:regular}
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="D">DNI</option>
                                    <option value="PS">Pasaporte</option>            
                                </select>
                                <label htmlFor="floatingInput">Documento</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    disabled = {documento===""||onEdit} 
                                    type='text'
                                    className="form-control mb-3"
                                    id="floatingInput"
                                    name="docnumero"
                                    placeholder="Numero"
                                    onKeyPress={(e)=>checkdocnumero(e)}
                                    onChange={e=>setDocnumero(e.target.value)}
                                    value={docnumero}
                                    style={complete ? regular:docnumero===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Número de Documento</label>
                                </div>

                            <div className="form-floating">
                                <input 
                                    type='text' 
                                    className="form-control mb-3 uppercase-input" 
                                    id="floatingInput" name="nombre" 
                                    placeholder="Nombres" 
                                    onChange={e=>setNombre(e.target.value)}
                                    value={nombre}
                                    style={complete ? regular:nombre===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Nombres</label>
                            </div>

                            <div className="form-floating">
                                <input 
                                    type='text' 
                                    className="form-control mb-3 uppercase-input" 
                                    id="floatingInput" name="apellidop" 
                                    placeholder="Apellido Paterno" 
                                    onChange={e=>setApellidop(e.target.value)}
                                    value={apellidop}
                                    style={complete ? regular:apellidop===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Apellido Paterno</label>
                            </div>

                            <div className="form-floating">
                                <input 
                                    type='text' 
                                    className="form-control mb-3 uppercase-input" 
                                    id="floatingInput" 
                                    name="apellidom" 
                                    placeholder="Apellido Materno" 
                                    onChange={e=>setApellidom(e.target.value)}
                                    value={apellidom}
                                    style={complete ? regular:apellidom===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Apellido Materno</label>
                            </div>
                                                                                     
                            <div style={{textAlign:"center",color:"red"}}><p>{errormsg}</p></div>  
                            <div style={{display:"flex",justifyContent:"space-around"}}>
                                <button className='btn red-button'
                                    onClick={()=>{
                                        setShowForm(false)
                                        setOnEdit(false)
                                        cleanForm()                                            
                                        }
                                    }
                                    disabled={apoderados.length===0}
                                >Cancelar</button>
                                <button className='btn blue-button' 
                                    onClick={()=>{
                                            revisar()                                            
                                        }
                                    }
                                >Guardar</button>                                    
                            </div>
                        </div>
                    :null}        
                </div>
            </div>
            <div style={{display:"flex",justifyContent:"space-around",marginBottom:"1rem"}}>
                <button className='btn red-button' onClick={()=>navigation.previous()}>REGRESAR</button>
                <button disabled={apoderados.length===0} className='btn green-button' onClick={()=>navigation.next()}>SIGUIENTE</button>    
            </div>
        </div>
    </div>
  )
}
