import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import Axios from 'axios'

//Utils
import { getUrlApi } from '../Utils/getUrl&Key'

export default function NavLoginTimer(user) {
  const history = useHistory()

  const initialTime = 600
  const [timer, setTimer] = useState(0)
  const [showTimeOut, setShowTimeOut] = useState(false)



  useEffect(() => {
    getpublickey()
    setTimer(initialTime)
    window.addEventListener("click", timerReload)

    return () => {
      window.removeEventListener("click", timerReload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let interval
    
    if (user.user.username !== null) {
      interval = timer > 0 && setInterval(() => setTimer(timer - 1), 1000)      
      if (timer === 60 && user.user.username !== null) {
        setShowTimeOut(true)
      }

      if (timer === 0 && user.user.username !== null) {
        signOut()
      }
    }

    
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer])



  const timerReload = async () => {
    const now = new Date().toLocaleDateString('en-US',{
      timeZone: 'America/Lima',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    sessionStorage.setItem("App.ts",Buffer.from(now).toString('base64'))    
    setTimer(initialTime)
    setShowTimeOut(false)    
  }

  function signOut() {
    history.replace({ pathname: "/logout" })
  }

  async function getpublickey() {
    const url = getUrlApi() + "/common/getpublickey"
    Axios.get(url).then((res) => {
      let data = res.data
      data = Buffer.from(data).toString('base64')      
    })
  }


  return (
    <div className={showTimeOut ? "timeOutAlert" : "timeOutAlert_Off"}>
      <div>
        <div style={{ fontSize: "1.2rem" }}>Ha sobrepasado el tiempo de Inactividad!</div>
        <div className="mt-2 mb-4">
          <div style={{ textAlign: "justify" }}>Su sesión actual va a expirar en <strong style={{ color: "red" }}>{timer}</strong> segundos. Haga clic en cualquier lugar para seguir conectado.</div>

        </div>
        <div>
          <button className="btn red-button" onClick={() => {
            setShowTimeOut(false)
            signOut()
          }}>Cerrar Sesión</button>
        </div>
      </div>
    </div>
  )
}
