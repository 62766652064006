import React,{useState,useRef,useEffect} from 'react'

import PeruFlag from '../Assets/Icons/country flags/tn_pe-flag.gif'
import UsaFlag from '../Assets/Icons/country flags/tn_us-flag.gif'
import EspFlag from '../Assets/Icons/country flags/tn_sp-flag.gif'
import JpnFlag from '../Assets/Icons/country flags/tn_ja-flag.gif'            
import AusFlag from '../Assets/Icons/country flags/tn_as-flag.gif'
import CanFlag from '../Assets/Icons/country flags/tn_ca-flag.gif'
import MexFlag from '../Assets/Icons/country flags/tn_mx-flag.gif'
import NzlFlag from '../Assets/Icons/country flags/tn_nz-flag.gif'
import FranFlag from '../Assets/Icons/country flags/fr-flag.gif'

const phoneCodes = [
    {code: "+61",name:"Autralia",value:"61",photo:AusFlag},
    {code: "+1",name:"Canada",value:"1",photo:CanFlag},        
    {code: "+34",name:"España",value:"34",photo:EspFlag},
    {code:"+1",name:"Estados Unidos",value:"1",photo:UsaFlag},
    {code:"+33",name:"Francia",value:"33",photo:FranFlag},
    {code: "+81",name:"Japón",value:"81",photo:JpnFlag},
    {code: "+52",name:"México",value:"52",photo:MexFlag},
    {code: "+64",name:"Nueva Zelanda",value:"64",photo:NzlFlag},
    {code:"+51",name:"Perú",value:"51",photo:PeruFlag}
]

export default function PhoneCodes({saveValue,currentValue,disabled=false}) {              
    
    const [selectedCode, setSelectedCode] = useState("");
    const [showCode,setShowCode] = useState("--")
    const [showPhone,setShowPhone] = useState("")
    const [showDrop,setShowDrop] = useState(false)
    const customDrpRef = useRef(null)


    useOutsideAlerter(customDrpRef,handleClose)

    

    useEffect(() => {        
        if(currentValue.length>7){  
            const code = currentValue.split(" ")[0]
            const number = currentValue.split(" ")[1]            
            const selected = phoneCodes.filter(item=>item.value===code)            
            if(code.length<4){
                setSelectedCode(code)
                setShowPhone(number)
                if(selected.length>0){                    
                    setShowCode(selected[0].code)
                }
            }else{
                setShowPhone(currentValue)
            }
        }
        if(currentValue===""){
            setSelectedCode("")
            setShowCode("--")
            setShowPhone("")
        }
    }, [currentValue]);

    useEffect(() => {        
        if(selectedCode!==""||showPhone!==""){        
        saveValue(selectedCode  + " " + showPhone)} 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPhone,selectedCode]);
   

    function changeShowDrop(){
        if(!disabled){
            setShowDrop(!showDrop)
        }        
    }

    function handleClose(){
        setShowDrop(false)
    }

    function handleSelect(code,value){
        setSelectedCode(value)
        setShowCode(code)        
        setShowDrop(false)
    }

    function justnumber(event){      
        if (Number.isNaN(parseInt(event.key))){
            event.preventDefault()
        }            
      }

    return(
        <div style={{display:"flex", justifyContent:"space-between",width:"100%"}}>
            <div ref={customDrpRef}>
                <div key="box" className='form-control' style={{display:"flex",width:"5rem",justifyContent:"space-between"}} onClick={()=>changeShowDrop()}>                
                    <div style={disabled?{display:"grid",alignItems:"center",color:"gray"}:{display:"grid",alignItems:"center"}}>{showCode}</div>
                    <div  className="customDropArrowCont">
                        <i className='customDropArrow'></i>
                    </div>
                </div>
                <div key="drop" style={showDrop?{position:"absolute",height:"7.5rem",overflow:"auto",zIndex:"1",backgroundColor:"white",padding:"0.5rem",border:"1px solid #ced4da"}:{display:"none"}}>
                    {phoneCodes.map(option=>                
                        <div key={option.name+option.value} className='customDropOptions mb-1' onClick={()=>handleSelect(option.code,option.value)}>
                            <div><img src={option.photo} alt={"logo"} style={{width:"2rem", marginRight:"1rem"}}/></div>
                            <div>{option.name}</div>
                            <div style={{color:"gray"}}>({option.code})</div>                        
                        </div>
                    
                    
                    )}
                </div>
            </div>
            <div className="form-floating">
                <input 
                  type='text' 
                  className="form-control mb-3" 
                  id="floatingInput" 
                  name="telefono" 
                  placeholder="Teléfono" 
                  onKeyPress={(e)=>justnumber(e)}   
                  onChange={e=>setShowPhone(e.target.value)} 
                  value={showPhone}
                />
                <label htmlFor="floatingInput">Teléfono / Celular</label>
            </div>
        </div>

)}

function useOutsideAlerter(ref,callback=()=>{}) {
    useEffect(() => {       
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {                
                callback() // eslint-disable-next-line react-hooks/exhaustive-deps
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

