import React,{useState,useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {Form,FloatingLabel,Button,Container,Row,Col,Spinner} from 'react-bootstrap'
import Axios from 'axios'

//Utils
import {getUrlApi} from '../../../Utils/getUrl&Key'
import {moneyFormat} from '../../../Utils/numberFormat'
import loading from '../../../Utils/loading'
import customAlert from '../../../Utils/alert'

//Compontents
import CopiaLiteral from './publicidades/CopiaLiteral'
import VigenciaRJ from './publicidades/VigenciaRJ'
import CertificadoReg from './publicidades/CertificadoReg'

//Icons
import {MdModeEdit,MdDelete} from 'react-icons/md'
import LoginRequired from '../../../Utils/loginRequired'



export default function ServPublicidad() {
    const history = useHistory()
    
    const [tipoSol, setTipoSol] = useState("");
    const [editing, setEditing] = useState({item:null,onEdit:false});
    const [solicitudes, setSolicitudes] = useState([]);
    const [options, setOptions] = useState([]);

    const [precios, setPrecios] = useState([]);
    const [updatePrecio, setUpdatePrecio] = useState(false);
    const [cotizaError, setCotizaError] = useState(0); 
    const [declaracion, setDeclaracion] = useState(false);
    const [declaracion2, setDeclaracion2] = useState(false);
    const session = JSON.parse(localStorage.getItem("App.login"))

    const keygen = () => {
        const min = 1;
        const max = 100;
        const random = Math.floor(Math.random() * (max - min + 1)) + min;
        return random;
    };

    useEffect(()=>{
        const stored = JSON.parse(localStorage.getItem("App.publicidad"))        
        getOficinaReg()
        if(stored){
            setSolicitudes(stored)
            setTipoSol(stored[0].tipo) 
        }
        
    },[])

    useEffect(()=>{
        setEditing({item:null,onEdit:false})
        if(solicitudes.length>0){
            localStorage.setItem("App.publicidad",JSON.stringify(solicitudes)) 
        }else{
            localStorage.removeItem("App.publicidad")
        }
    },[solicitudes])

    function servicioName (servicio){
        const words = servicio.split(/[_\s-]+/);
  
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }  
        return words.join(' ');        
    }

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
      };

    function handleDelete(item){              
        const newSolicitudes = solicitudes.filter((sol,index)=>index!==item)
        /* const newSolicitudes = solicitudes.filter(sol=>!(sol.tipo===item.tipo&&sol.oficinaReg===item.oficinaReg&&sol.partida===item.partida))*/
        /* const newPrecios = precios.filter(pre=>!(pre.tipo===item.tipoSol&&pre.partida===item.partida)) */
        setSolicitudes(newSolicitudes)
        /* setPrecios(newPrecios)         */
    }

    function handleEdit(item){
        setEditing({item:item,onEdit:true})
        window.scrollTo(0,0)
    }

    async function  handleEnviar(){        
        let data = []
        let Url = getUrlApi() + "/registral"
        
        const headers = {
            "Content-Type": "application/json",
            "ubicacion": "PER"
        }
        
        const plantilla = {
            userid:session.id,
            tipo: "",
            tipoCopia: "",
            servicio:"",
            tipoVigencia:"",
            oficinaReg: "",
            partida: "",
            asientos: [],
            paginas:"*",            
            monto:0,
            cargo:"",
            representante: {
                nombre: "" ,
                aplellidoP: "",
                aplellidoM: "",
            }
        }
        solicitudes.forEach((sol,index)=>{
            
            if(precios[index].estado!==401){                
                data.push({...plantilla,...sol,monto:precios[index].monto_simple,estado:precios[index].estado})
                if(precios[index].estado===500){
                    data[index].monto=0
                }
            }
            
        })
        
        try {            
            await Axios.post(Url,data,{headers:headers}).then(res=>{                
                const data = res.data
                
                localStorage.removeItem("App.publicidad")
                if(precios.every(item=>item.estado!==500)){
                    history.push({pathname:"/main/payment",state:{poder:data.id,servicio:"PR"}})
                }else{
                    history.push({pathname:"/main/servicios/registrado",state:{tramiteId:data.id,servicio:"PR",cotizaError}})
                }
                
                
            })            
        } catch (error) {
            if(error.response){
                alert(error.response)
            }
        }
       
    }

    async function getOficinaReg(){
        const Url = getUrlApi()+"/common/oficinareg"      
        let data
        let oficinas = []
        try{               
          await Axios.get(Url)
          .then( res=>{
              if (res.status===200){ 
                  data = res.data
                  oficinas = []
                  data.map(item => {                    
                      oficinas.push({name:item.oficina,value:item.id})
                      return null
                  })
                  setOptions(oficinas)                  
              }
          })
        }
        catch(error){
          console.log(error.response.data.message)
        }    
      }      

      async function getCotizacionBot(){                
        setCotizaError(0) 
        loading("Obteniendo cotizacion...")      
        setUpdatePrecio(true)      
        const url  = getUrlApi() + "/registral/cotizar"
        /* const url  = "http://192.168.195.77:8090/api" + "/registral/cotizar" */
               
        try {
            await Axios.post(url,solicitudes).then(res=>{
                const data = res.data.responseData
                
                data.forEach(item=>{
                    
                    if(item.estado===200){                        
                        setPrecios((prevLog)=>[...prevLog,item])
                    }
                    else{
                        if(item.estado===500){
                            setCotizaError((prevLog)=> prevLog+1)
                        }                        
                        const precio = {...item,monto:0}                        
                        setPrecios((prevLog)=>[...prevLog,precio])
                    }
                })                
                loading("",true)             
                setUpdatePrecio(false)
                               
            })
        } catch (error) {
            loading("",true)
            setUpdatePrecio(false)
            if(error.response){
                console.log(error)
                alert(error.response.message)
            }
        }
      }

      async function getCotizacion(){
        const headers = {"ubicacion":"PER"}        
        setCotizaError(0) 
        loading("Obteniendo cotizacion...")      
        setUpdatePrecio(true)      
        const url = getUrlApi() + "/registral/precio"
        const urlComprobar  = getUrlApi() + "/registral/comprobar"                
        try {
            await Axios.post(urlComprobar,solicitudes,{headers:headers}).then(async res=>{                
                const data = res.data.data
                console.log(data)
                let DataCotiza = []
                data.forEach(item=>{
                    
                    const itemCotiza = {partida:item.partida,tipo:solicitudes[0].tipo}
                    DataCotiza.push(itemCotiza)
                })

                await Axios.post(url,DataCotiza,{headers:headers}).then(resCotiza=>{
                    const dataCotiza = resCotiza.data

                    dataCotiza.forEach((item,index)=>{
                        let newItem = {...item,...data[index]}
                        
                        if(newItem.estado===401){
                            newItem = {...newItem,...{monto:0}}
                            setPrecios((prevLog)=>[...prevLog,newItem])
                        }else{
                            setPrecios((prevLog)=>[...prevLog,newItem])
                        }                   
                    })
                })
                
                

                loading("",true)             
                setUpdatePrecio(false)                
            })
        } catch (error) {
            loading("",true)
            setUpdatePrecio(false)
            if(error.response){
                alert(error.response.message)
            }
        }
      }

  return (
    <Container>
        <Row className='justify-content-center'>
            <Col sm={12} md={7} lg={6}>
                <div className='serviceTittle'>Solicitud de Publicidad Registral</div>
                <hr/>
                <LoginRequired/>
            </Col>            
        </Row>
        <Row className='justify-content-center'>
            <Col xs={12} sm={10} md={7} lg={5}>                
                <Form className='row justify-content-center g-2 whiteCard'>
                    <Col md={12}>                                                
                        <FloatingLabel label = "Tipo de Publicidad Registral" className='p-0'>
                            <Form.Select value={tipoSol} onChange={e=>setTipoSol(e.target.value)} disabled={solicitudes.length>0}>
                                <option value="">Seleccionar</option>
                                <option value="CL">Copia Literal</option>
                                <option value="CRI">Certificado Registral Inmobiliario</option>
                                <option value="VP">Vigencia de Poder</option>
                            </Form.Select>       
                        </FloatingLabel>
                    </Col>                    
                </Form>
            </Col>
        </Row>
        {tipoSol==="CL"?<Row className='justify-content-center'>
            <Col xs={12} sm={10} md={7} lg={5}>                
                <Form className='row justify-content-center g-2 whiteCard'>                    
                    <CopiaLiteral options={options} editing={editing} mayus={toInputUppercase} solicitudes={solicitudes} guardarSolicitud={setSolicitudes}/>
                    {solicitudes.length>0?solicitudes.map((sol,index)=>{
                        return(
                            <div key={sol.partida+"-"+keygen()} className='d-flex whiteCard StolzlMedium'>
                                <div style={{width:"90%"}}>
                                    <table>
                                        <tbody style={{fontSize:"0.8rem"}}>
                                            <tr>                                                
                                                <td className='p-1'>Partida</td>
                                                <td className='p-1'>Oficina Reg.</td>
                                                
                                            </tr>
                                            <tr>                                                
                                                <td className='p-1' style={{color:"gray"}}>{sol.partida}</td>
                                                <td className='p-1' style={{color:"gray"}}>{options.length>0?options.filter(item=>item.value===sol.oficinaReg)[0].name:sol.oficinaReg}</td>                                                
                                            </tr>
                                            <tr>
                                                <td className='p-1'>Servicio</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className='p-1' style={{color:"gray"}}>{servicioName(sol.servicio)}</td>
                                            </tr>
                                        </tbody>
                                    </table>                                      
                                </div>
                                <div style={{width:"10%",fontSize:"1.2rem",display:"flex",justifyContent:"space-between"}}>
                                    <MdModeEdit onClick={()=>handleEdit(sol)} style={{color:"navy", cursor:"pointer"}}/>
                                    <MdDelete onClick={()=>handleDelete(index)} style={{color:"darkred",cursor:"pointer"}}/>
                                </div>
                            </div>
                        )
                    }):null}
                </Form>
            </Col>
        </Row>:null}
        {tipoSol==="VP"?<Row className='justify-content-center'>
            <Col xs={12} sm={10} md={7} lg={5}>                
                <Form className='row justify-content-center g-2 whiteCard'>
                    <VigenciaRJ options={options} mayus={toInputUppercase} editing={editing} solicitudes={solicitudes} guardarSolicitud={setSolicitudes}/>
                    {solicitudes.length>0?solicitudes.map((sol,index)=>{
                        return(
                            <div key={sol.partida+"-"+keygen()} className='d-flex whiteCard StolzlMedium'>
                                <div style={{width:"90%"}}>
                                    <table style={{fontSize:".9rem"}}>
                                        <tbody>
                                            <tr>                                                
                                                <td className='p-1'>Partida</td>
                                                <td className='p-1'>Oficina Reg.</td>
                                                <td className='p-1'>Representante</td>
                                            </tr>
                                            <tr>                                                
                                                <td className='p-1' style={{color:"gray"}}>{sol.partida}</td>
                                                <td className='p-1' style={{color:"gray"}}>{options.length>0?options.filter(item=>item.value===sol.oficinaReg)[0].name:sol.oficinaReg}</td>
                                                <td className='p-1' style={{color:"gray"}}>{sol.representante.nombre + " " + sol.representante.apellidoP} </td>
                                            </tr>
                                            <tr>
                                                <td className='p-1'>Asientos:</td>
                                                <td className='p-1' colSpan={2} style={{color:"gray"}}>{sol.asientos.join(" ")}</td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>                                      
                                </div>
                                <div style={{width:"10%",fontSize:"1.2rem",display:"flex",justifyContent:"space-between"}}>
                                    <MdModeEdit onClick={()=>handleEdit(sol)} style={{color:"navy", cursor:"pointer"}}/>
                                    <MdDelete onClick={()=>handleDelete(index)} style={{color:"darkred",cursor:"pointer"}}/>
                                </div>
                            </div>
                        )
                    }):null}
                </Form>
            </Col>
        </Row>:null}
        
        {tipoSol==="CRI"?<Row className='justify-content-center'>
            <Col xs={12} sm={10} md={7} lg={5}>                
                <Form className='row justify-content-center g-2 whiteCard'>
                    <CertificadoReg options={options} mayus={toInputUppercase} editing={editing} solicitudes={solicitudes} guardarSolicitud={setSolicitudes}/>
                    {solicitudes.length>0?solicitudes.map((sol,index)=>{
                        return(
                            <div key={sol.partida+"-"+keygen()} className='d-flex whiteCard StolzlMedium'>
                                <div style={{width:"90%"}}>
                                    <table>
                                        <tbody>
                                            <tr>                                                
                                                <td className='p-1'>Partida</td>
                                                <td className='p-1'>Oficina Reg.</td>
                                            </tr>
                                            <tr>                                                
                                                <td className='p-1' style={{color:"gray"}}>{sol.partida}</td>
                                                <td className='p-1' style={{color:"gray"}}>{options.length>0?options.filter(item=>item.value===sol.oficinaReg)[0].name:sol.oficinaReg}</td>
                                            </tr>
                                        </tbody>
                                    </table>                                      
                                </div>
                                <div style={{width:"10%",fontSize:"1.2rem",display:"flex",justifyContent:"space-between"}}>
                                    <MdModeEdit onClick={()=>handleEdit(sol)} style={{color:"navy", cursor:"pointer"}}/>
                                    <MdDelete onClick={()=>handleDelete(index)} style={{color:"darkred",cursor:"pointer"}}/>
                                </div>
                            </div>
                        )
                    }):null}
                </Form>
            </Col>            
        </Row>:null}
        <Row>
        
        </Row>
        {precios.length>0?< div className='customAlertContainer'>
                <div className='whiteCard StolzlMedium scale-in-ver-center'>
                    Listado de Precios
                    {cotizaError===0?<div className='redCard' style={{width:"25rem",maxWidth:"90vw"}}>
                        {updatePrecio?
                        
                        <Spinner animation="border" variant="light" role="status"/>
                        :<div>
                            <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium"><div>Partida</div><div>Monto</div> </div>
                            {precios.map(item=>{return(
                                <div key={`p${item.partida}-${keygen()}`} style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium"><div>{item.partida}</div>{item.estado===200||item.estado===500?<div>{moneyFormat(item.monto)} PEN</div>:<div>{item.message}</div>}</div>
                                )}
                            )}
                        </div>}
                        <hr/>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium"><div>Costo Total:</div><div>{moneyFormat(precios.reduce((total,item)=>total + item.monto,0))} PEN</div></div>
                    </div>:
                    <div className='redCard' style={{width:"25rem",maxWidth:"90vw"}}>
                        <p>
                            No se ha podido realizar la cotizacion en este momento.
                        </p>
                        <p>
                            De clic en "Enviar Solicitud" para registrar el servicio y recibirá la cotización por correo.
                        </p>
                    </div>}
                    <div className='d-flex justify-content-around'>
                        <Button variant='light' onClick={()=>setPrecios([])} className='red-button'>CANCELAR</Button>
                        <Button variant='light' disabled={precios.reduce((total,item)=>total + item.monto,0)===0&&cotizaError===0} onClick={()=>handleEnviar()} className='green-button'>{precios.every(item=>item.estado!==500)?"PAGAR":"ENVIAR SOLICITUD"}</Button>
                    </div>
                    <div><span className='StolzlMedium' style={{fontSize:".75rem"}}>*Partidas no encontradas seran omitidas al enviar la solicitud</span></div>                    
                </div>
        </div>:null}
        <Row className='d-flex justify-content-center'>
            <Col xs={12} sm={10} md={7} lg={5}>
                <div>
                    <div className='form-check'>
                        <input type='checkbox' className='form-check-input' value={declaracion} onChange={()=>setDeclaracion(!declaracion)}/>            
                        <label className='form-check-label'>Declaro que toda la información consignada es correcta.</label>
                    </div>
                    <div className='form-check'>
                        <input type='checkbox' className='form-check-input' value={declaracion2} onChange={()=>setDeclaracion2(!declaracion2)}/>            
                        <label className='form-check-label'> Entiendo y acepto los posibles <strong style={{cursor:"pointer",color:"red"}} onClick={()=>customAlert(undefined,"Plazos de Atencion de SUNARP","El plazo máximo de SUNARP para la atención de estos requerimientos es de 3 días hábiles; pudiendo recibirlo a partir de los 30 minutos.")}>Tiempos de espera</strong> asociados con el proceso de solicitud. </label>
                    </div>
                </div>
            </Col>        
        </Row>
        <Row>
            {/* {tipoSol==="CL"||tipoSol==="VP"? */}
            <Col className='d-flex justify-content-center'>                
                <Button 
                    disabled = {solicitudes.length===0||!session||!declaracion||!declaracion2}
                    variant='light' 
                    onClick={()=>{
                        if(tipoSol==="CL"){
                            getCotizacionBot()
                        }else{
                            getCotizacion()
                        }
                        
                    }} 
                    className='green-button'
                >CONTINUAR</Button>
            </Col>{/* :
            <Col className='d-flex justify-content-center'>
                <Button
                    disabled={solicitudes.length===0}
                    variant='light'
                    onClick={()=>handleEnviar()}
                    className='green-button'
                >ENVIAR SOLICITUD 
                </Button>
                </Col>} */}            
        </Row>                
    </Container>
  )
}
