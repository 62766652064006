import React,{useState} from 'react'
import BanItem from './bandejasObjects/banItem'
import BanFilter from './bandejasObjects/banFilter'


const itemsPerPage = 40
export default function BandejasMain({navigation,data,title,update,setUpdate}) {

  const [cleanData, setCleanData] = useState(data)
  const [currentPage, setCurrentPage] = useState(1); 

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedData = cleanData.slice(indexOfFirstItem, indexOfLastItem)
  
  const totalPages = Math.ceil(cleanData.length / itemsPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }; 

  return (
    <div style={{display:"flex",width:"100%",justifyContent:"center",overflow:"auto"}}>
      <div style={{width:"70rem",maxWidth:"100%",height:"max-content"}}>
          <div style={{display:"flex",justifyContent:"center",margin:"1rem 0"}}>
            <div className='StolzlMedium' style={{fontSize:"1.5rem"}}>{`Bandejas de ${title}`}</div>
          </div>
          <div style={{display:"flex"}}>
            <BanFilter rawData={data} setFilData={setCleanData} update={update}/>            
          </div>
            
          <div style={{marginBottom:"1rem",maxHeight:"80%",overflow:"auto"}}>
            <div className='customBoxShadow customScroll' style={{maxHeight:"50vh",minWidth:"50rem" ,overflow:"auto"}} >
              {
                paginatedData.map(Item=>{return <BanItem key={Item.id} Item={Item} update={update} setUpdate={setUpdate}/>})
              }
            </div>
          </div>
          <div style={{display:"flex",justifyContent:"center"}}>
            <div className="whiteCard" style={{width:"max-content", display:"flex",justifyContent:"space-evenly"}}>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? 'activePB pageButton StolzlMedium' : 'pageButton'}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>          
      </div>
    </div>
  )
}
