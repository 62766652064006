import React, {useEffect} from 'react'
import Spinner from 'react-bootstrap/Spinner'
import {useHistory} from 'react-router-dom'

//Assets
import BlackLogo from '../../Assets/Logos/logoMainBlack.png'

const SESSION_KEY = "App.login" 


export default function Logout() {
    const history = useHistory()
    
    useEffect(()=>{
        setTimeout(() => {                    
            localStorage.removeItem(SESSION_KEY)
            history.replace({pathname:"/"})
        }, 1500)
        // eslint-disable-next-line
    },[])

    return (        
        <div style={{height:"100%",display:"flex", justifyContent:"center",alignItems:"center"}}>
            <div style={{textAlign:"center"}}>
                <img src={BlackLogo} alt="logo tramitec" style={{height:"6rem",margin:"1rem 0"}}/>
                <div style={{height:"min-content"}}>                
                    <Spinner animation="border" role="status"/>
                    <span style={{fontSize:"1.5rem"}}>Cerrando Sesion...</span>            
                </div>
            </div>            
        </div>
        
    )
}

