import React,{useEffect} from 'react'
import {useHistory} from 'react-router-dom'

//Assets
import Logo from '../Assets/Logos/WhiteLogo.svg'

export default function Notfound() {
    const history = useHistory()

    useEffect(()=>{        
        setTimeout(() => {
            history.replace({pathname:"/"})
        }, 3500)
        // eslint-disable-next-line        
    },[])

    return (
        <div 
            style={{
                backgroundColor:"var(--red-Color)",
                height:"100vh",
                textAlign:"center",
                padding:"5%"
            }}
        >
            <div>
                <img className='mt-4 mb-4' alt='logo' src={Logo}/>
            </div>
            <div className='whiteCard StolzlMedium'>
                <div style={{fontSize:"1.5rem"}}>
                    Oops! Parece que te has perdido en el ciberespacio
                </div>
                <div>
                    Espera unos segundos mientras volvemos a la pagina de inicio
                </div>
            </div>
        </div>
    )
}
