import React,{createRef,useState, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {Accordion} from 'react-bootstrap'
import {BsFillQuestionDiamondFill} from 'react-icons/bs'
import { useEffect } from 'react'


export default function Helpcenter() {
    const location = useLocation()
    const faqRef = useRef([])    
    const [activeKey,setActiveKey] = useState(null)

    
    useEffect(()=>{
        let timer
        if(location.state){
            window.scrollTo(0,0)
            timer = setTimeout(()=>irFaq(location.state-1),500) 
        }
        return ()=>{
            clearTimeout(timer)            
        }
    },[])
    const questionsAnswers= [
        {
            id:1,
            question:"¿Puedo otorgar un poder si no tengo DNI o éste se encuentra vencido?",
            answer:["No, siempre que desee otorgar un poder será necesario contar con un DNI vigente."]
        },
        {
            id:2,
            question:"¿Qué tipo de poderes puedo otorgar en un Consulado?",
            answer:["Existen 3 tipos de poderes: Carta Poder con firma legalizada, Poder fuera de registro y Poder por Escritura Pública."+
                    " El tipo de poder que deberás otorgar dependerá principalmente de 2 factores: "+
                   "Monto del encargo y tipo de acto a realizar por quien recibe el poder (apoderado).",
                   "La carta poder sirve para actos que no sobrepasen media Unidad Impositiva Tributaria (UIT), que al 2022 equivale"+
                   " a S/ 2,300. Normalmente es empleada para trámites muy sencillos como presentación de solicitudes"+
                   " ante instituciones públicas, tramitación de certificados, etc.",
                   "El poder fuera de registro sirve para actos de más de media UIT y hasta 3 UIT, es decir montos por encima de los S/ 2,300 y"+
                   " hasta S/ 13,800. Normalmente son utilizados para el cobro de pensiones ante el Banco de la Nación.",
                   "El poder por escritura pública sirve para actos por encima de las 3 UIT es decir, de S/ 13,800 a"+
                   " más. Asimismo se deberá emplear el poder por escritura pública para: compraventa o constitución"+
                   " de gravámenes (hipotecas) sobre bienes muebles (vehículos) e inmuebles"+
                   " (casas, departamentos, terrenos, etc.), representación en procesos judiciales, constitución"+
                   " de todo tipo de empresas, realización de trámites notariales de divorcio, sucesión intestada,"+
                   " rectificación de partidas, entre otros."]
        },
        {
            id:3,
            question:"¿Qué poder debo otorgar en caso quiera arrendar un inmueble?",
            answer:["El arrendamiento de bienes inmuebles (casas, departamentos, etc.) requiere necesariamente el otorgamiento de un poder por escritura pública."]
        },
        {
            id:4,
            question:"¿Qué poder debo otorgar si quiere vender, comprar o hipotecar un inmueble (casa, departamento, lote, etc.)?",
            answer:["La compra, venta o constitución de gravámenes (hipoteca) sobre inmuebles, necesariamente requiere un poder por escritura pública."]
        },
        {
            id:5,
            question:"Tengo que iniciar una demanda o he sido demandado en Perú. ¿Cómo nombro a un apoderado?",
            answer:["El poder para juicios necesariamente debe ser efectuado por escritura pública."]
        },
        {
            id:6,
            question:"¿Qué poder requiero para solicitar el duplicado de mi token, mi tarjeta de débito o crédito emitida por un Banco en Perú?",
            answer:["La mayoría de instituciones bancarias requiere que este tipo de encargo sea efectuado mediante poder por escritura pública. Sin embargo, sugerimos averiguar ante su propia institución bancaria los requisitos necesarios para dichos trámites."]
        },
        {
            id:7,
            question:"¿Qué documentos debo presentar al Consultado para otorgar un poder por escritura pública?",
            answer:["Para otorgar un poder consultar por escritura pública, será necesario presentar:",
            "DNI vigente de la persona que otorga poder (poderdante).",
            "Minuta de poder (La cual contiene todo el texto de las facultades otorgadas al representante  o apoderado).",
            "Identificar a la persona que se encargará del trámite de inscripción en Perú. Puede ser el mismo apoderado o una tercera persona.",
            "A través de TRAMITEC podrá generar de manera automática la minuta de poder que requiere para su trámite consular, la cual se emitirá con la firma de un abogado peruano. Adicionalmente si lo requiere nosotros nos podemos encargar del trámite de inscripción registral en Perú."]            
        },
        {
            id:8,
            question:"¿Qué documentos me emite el Consulado luego de firmado mi poder por escritura pública?",
            answer:["Una vez firmada la escritura pública, el consulado emitirá 2 documentos: parte consular (que es el documento que deberá ingresarse a los Registros Públicos en Perú para su inscripción) y el testimonio (que es el documento que deberá guardar el apoderado para acreditar las facultades otorgadas. Es decir, el testimonio le permitirá al apoderado demostrar cuáles son las cosas que se encuentra autorizado a hacer."]
        },
        {
            id:9,
            question:"¿Una vez que tengo el parte y el testimonio consular qué debo hacer?",
            answer:["Una vez emitido ambos documentos, estos deberán enviarse a Perú para: "+
            "Llevar los mismos a certificar ante el Ministerio de Relaciones Exteriores, debiendo abonarse para dichos efectos el monto correspondiente. "+
            "Una vez certificado el parte consular, deberá llevarse a los Registros Públicos para su inscripción, debiendo abonarse para dichos efectos los derechos registrales que correspondan. "+
            "En TRAMITEC nos podemos encargar de estas gestiones. Lo invitamos a que revise en nuestra página web los requisitos y el procedimiento necesario para solicitar este servicio."]            
        },
        {
            id:10,
            question:"¿Todos los poderes se ingresan a Registros Públicos se inscriben sin problemas?",
            answer:["No. Una vez ingresado un poder a Registros Públicos, éste podrá: observarse, tacharse, requerir el pago de un mayor derecho o inscribirse. En el caso de las observaciones, estas se refieren a alguna deficiencia del poder, que requieren ser subsanadas para que proceda la inscripción. La tacha del poder, implica que el mismo adolece de defectos que son insubsanables y que requerirán su reformulación integral. El pago de un mayor derecho registral, implica la necesidad de cancelar un monto adicional de derechos registrales para que procese la inscripción. La inscripción del poder, agota el trámite registral y permite que el mismo quede inscrito ante los Registros Públicos. En TRAMITEC garantizamos que los poderes generados a través de los formatos estándares de nuestra plataforma serán inscritos sin inconvenientes en Registros Públicos."]
        },
        {
            id:11,
            question:"¿Cuál es el tiempo de vigencia de un poder por escritura pública?",
            answer:["Un poder por escritura pública tiene una vigencia indefinida hasta que el poderdante decida revocarlo. Para poder dejar sin efecto el poder por escritura pública, su revocación deberá ser efectuada también por escritura pública."]
        },
        {
            id:12,
            question:"Otorgué un poder ante un Consulado y ahora que me encuentro en Perú quiero modificarlo o revocarlo, ¿Qué hago?",
            answer:["Deberá acercarse a cualquier Notaría en Perú para proceder a la modificación o revocación del poder Consular. Contáctenos para poder asesorarlo en su trámite y proveerle información sobre las Notarías con las que trabajamos en Perú."]
        }        
    ]

    faqRef.current = new Array(questionsAnswers.length).fill(null).map((_,index) => faqRef.current[index] || createRef()) 

    const irFaq = (index) => {            
        const iRef = faqRef.current[index].current                    
        const positionY = iRef.getBoundingClientRect().top        
        setActiveKey("QA"+(index+1))        
        window.scrollTo(0,(positionY-60))       
    };
    return(
        <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
            <div style={{width:"60rem",maxWidth:"95%"}}>
                <div>
                    <div className='FAQTitleContainer'>
                        <div style={{color:"var(--green-Color)",fontSize:"2.5rem",transform:"rotate(25deg)"}}><BsFillQuestionDiamondFill/></div>
                        <div style={{fontSize:"2.5rem",textAlign:"center",fontWeight:"bold"}}>Preguntas Frecuentes</div>
                        <div style={{color:"var(--orange-Color)",fontSize:"2.5rem",transform:"rotate(-25deg)"}}><BsFillQuestionDiamondFill/></div>                    
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <div style={{width:"60rem",maxWidth:"95%"}}>¿Tiene alguna duda? Siéntase libre de revisar las preguntas más frecuentes que se puede tener acerca de nuestros servicios.</div>
                    </div>                   
                    
                    <Accordion key="Accor" style={{marginTop:"1.5rem", width:"90%"}} activeKey={activeKey}>                
                        {questionsAnswers.map((item,index)=> 
                        <Accordion.Item key={item.id} eventKey={"QA"+item.id} ref={faqRef.current[index]} className='QAContainer'>
                            <Accordion.Header className="question" onClick={()=>activeKey===`QA${item.id}`?setActiveKey(null):setActiveKey("QA"+item.id)}>
                                <div style={{maxWidth:"90%"}}>{item.question}</div>
                            </Accordion.Header>

                            <Accordion.Body className="answer">
                                {item.answer.map((text,index)=>
                                    <div key={`t-${index}`} style={{marginBottom:"0.5rem"}}>{text}</div>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        )}
                    </Accordion>
                </div>
            </div>
        </div>
)}

