import ReactDOM from 'react-dom'
import React from 'react'


const TermCond = () => {
    const parentElement = document.getElementById("App")
    const container = document.createElement("div")
    
    const red = {color:"red"}
    container.className = "customAlertContainer"    
    parentElement.appendChild(container)
    

    function handleClose(){
        ReactDOM.unmountComponentAtNode(container)
        container.parentNode.removeChild(container)        
    }
    
    return ReactDOM.render(
        <div
            className='customScroll' 
            style={{
            display:"flex",
            justifyContent:"center",
            height:"80%",
            width:"80%",
            borderRadius:"0.4rem",
            marginLeft:"10%",            
            overflow:"auto",
            backgroundColor:"white"
            }}
        >
            <div style={{position:"absolute",top:"calc(10% + 1rem)",right:"13%",display:"flex",justifyContent:'flex-end'}}>
                <button 
                    style={{borderRadius:"0.1rem", fontSize:"1.4rem"}}
                    className='close-button'
                    onClick={()=>{
                            handleClose()
                        }}
                >X</button>
            </div> 
            <div className="userterm">                
                <div>
                    <div
                        style={{
                            textAlign:"center",
                            fontSize:"1.5rem"                            
                            }}
                        className="mb-4"     
                        >Términos y Condiciones de Uso de www.tramitec.pe</div>
                    <div>
                        <div className="mb-2">
                            El objeto de los presentes Términos y condiciones es regular el acceso y uso al sitio web [www.tramitec.pe],
                            entendiéndose por este cualquier tipo de contenido, producto y/o servicio que se encuentre a disposición 
                            del público dentro del dominio mencionado.
                        </div>
                        <div className="mb-2">
                            El sitio web es de titularidad de la empresa <strong style={red}>INVERSIONES MADRISQUI SAC</strong> (en 
                            adelante, MADRISQUI), quien se reserva la facultad de modificar en cualquier momento y sin previo aviso 
                            la presentación, los contenidos, la funcionalidad, los productos, los servicios y la configuración que 
                            pudiera estar contenida en este sitio web; en este sentido, el cliente reconoce y acepta que en
                            cualquier momento podrá interrumpir, desactivar, modificar o cancelar cualquiera de los elementos
                            que componen el sitio web o el acceso a los mismos.
                        </div>
                        <div className="mb-2">
                            El sitio web se encuentra dirigido a personas que cuenten con la mayoría de edad, en ese sentido, 
                            el titular no tendrá responsabilidad alguna por el incumplimiento de este requisito. El cliente declara 
                            ser mayor de edad y disponer de la capacidad jurídica necesaria para sujetarse a los presentes
                            Términos y condiciones.
                        </div>
                        <div className="mb-2">
                            MADRISQUI se compromete a realizar su mejor esfuerzo para asegurar la disponibilidad continua 
                            de sus servicios, así como para asegurar la ausencia de errores en cualquier transmisión de información 
                            que pudiera tener lugar en las transacciones; sin embargo, MADRISQUI no se hace responsable 
                            cuando sus servicios se vean afectados por la naturaleza misma del internet. Asimismo, los clientes 
                            declaran conocer que el acceso a los servicios del sitio web podría ocasionalmente verse suspendido 
                            o restringido por la realización de trabajos de mantenimiento o administración de los servicios ofertados.
                        </div>
                        <div className="mb-2">
                            MADRISQUI podrá actualizar y/o modificar los presentes Términos y condiciones sin previo aviso. 
                            Por este motivo recomendamos revisar los Términos y Condiciones cada vez que utilice la página web, 
                            ya que la versión aplicable será la que se encuentre colgada al momento de la consulta.                        
                        </div>
                        <div className="mb-2">
                            Para efecto de las transacciones que se realicen a través del presente sitio web, MADRISQUI señala 
                            como su domicilio el ubicado en <strong style={red}>CALLE ELIA AGUIRRE 180 DISTRITO DE MIRAFLORES</strong>, 
                            provincia y departamento de Lima, República del Perú y el cliente 
                            renuncia a la jurisdicción de su domicilio, sometiéndose a la competencia de las autoridades 
                            administrativas o judiciales de Lima.
                        </div>
                        <div className="mb-3">
                            El tratamiento de los datos personales que se recopilen durante la vigencia de estas medidas excepcionales se realizará cumpliendo con los más alto estándares de seguridad y dichos datos será almacenados por MADRISQUI en cumplimiento de la normativa vigente.
                        </div>
                    </div>
                </div>
                <div>            
                    <div 
                        style={{fontSize:"1rem"}}
                        className="mb-2"
                        >CLÁUSULA INFORMATIVA SOBRE EL TRATAMIENTO DE DATOS PERSONALES</div>
                    <div>
                        <div className="mb-3">
                            MADRISQUI informa al cliente que realizará el tratamiento de los datos personales brindados por cliente (nombre, apellidos, documento de identidad, claves de acceso, dirección, teléfono). Este tratamiento es necesario y tiene la finalidad de ejecutar las prestaciones a nuestro cargo y brindarle al cliente el servicio solicitado.
                        </div>
                        <div className="mb-3">
                            MADRISQUI declara que cualquier tratamiento de los datos personales se ajusta en las normativas de protección de datos personales vigentes y aplicables dentro del territorio peruano.
                        </div>
                        <div className="mb-4">
                            Para mayor detalle, la Política de Privacidad de MADRISQUI se puede encontrar en [link].
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            textAlign:"center",
                            fontSize:"1.5rem",
                            }}
                        className="mb-3"
                        >Política de Privacidad</div>
                    <div className="mb-2">
                        El cliente autoriza a <strong style={red}>INVERSIONES MADRISQUI SAC</strong> (“MADRISQUI”),
                        con RUC No. <strong style={red}>20524058155</strong>, domiciliado para estos efectos en 
                        <strong style={red}> CALLE ELIAS AGUIRRE 180 DISTRITO DE MIRAFLORES</strong>, 
                        provincia y departamento de Lima, República del Perú a realizar el tratamiento de los datos que se declaren a través 
                        del presente canal (en adelante, los “Datos Personales”).
                    </div>
                    <div className="mb-3">    
                        Los Datos Personales que serán objeto de tratamiento por parte de MADRISQUI serán almacenados en un 
                        banco de datos respetando los lineaminetos previstos por la Ley 29733 - Ley de datos personales y su 
                        Reglamento. MADRISQUI declara que cualquier tratamiento de los Datos Personales se ajusta a las 
                        normativas de protección de datos personales vigentes y aplicables dentro del territorio peruano.
                    </div>    
                    <div
                        style={{fontSize:"1rem"}}
                        className="mb-2"     
                        >INFORMACIÓN SOBRE EL TRATAMIENTO OBLIGATORIO DE DATOS PERSONALES:</div>
                    <div>
                        <div className="mb-2">
                        MADRISQUI informa al cliente que, para la prestación de los servicios y/o ejecución de 
                        las disposiciones establecidas en los Términos y Condiciones aceptados, realizará el 
                        tratamiento de su nombre, apellidos, No. de documento de identidad, dirección, correo 
                        electrónico, contraseña y/o teléfono mientras dure la relación contractual. Este 
                        tratamiento será realizado por MADRISQUI de forma directa, así como otras empresas 
                        encargadas del procesamiento y almacenamiento de datos.
                        </div>
                        <div className="mb-3">
                        MADRISQUI cumple con informarle al cliente que la información mencionada en el 
                        párrafo precedente es necesaria para el cumplimiento de las obligaciones a su cargo, las 
                        mismas que de otra forma no podrían ejecutarse.
                        </div>
                        <div 
                            style={{fontSize:"1rem"}}
                            className="mb-2"
                        >FINALIDADES ADICIONALES DE TRATAMIENTO:</div>
                        Con la aceptación de la presente Política de Privacidad el cliente otorga su 
                        consentimiento para que los Datos Personales sean tratados por un plazo indefinido, y 
                        mientras no solicite su cancelación o revoque la presente autorización, para las 
                        siguientes finalidades adicionales:
                        <div style={{paddingLeft:"5%"}} className="mb-3">
                            <div style={{display:"flex"}}>
                                <div style={{paddingRight:"1rem"}}>
                                    a.
                                </div>
                                <div>
                                    Enviar al cliente información comercial, promocional y/o publicidad con relación a los 
                                    servicios brindados por MADRISQUI, así como realizar prospección comercial. El 
                                    contacto podrá realizarse a través de los siguientes medios: centros de llamada (call centers), 
                                    sistemas de llamado telefónico, envío de mensajes de texto a celular o de mensajes electrónicos 
                                    masivos y telemercadeo.
                                </div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{paddingRight:"1rem"}}>
                                    b.    
                                </div>
                                <div>
                                    Almacenar y tratar los Datos Personales con fines estadísticos y/o históricos.
                                </div>
                            </div>
                        </div>
                        <div>
                            Transferencia de Datos Personales:
                        </div>
                        <div 
                            style={{paddingLeft:"5%"}}
                            className="mb-3"
                            >
                            <div style={{display:"flex"}}>
                                <div style={{paddingRight:"1rem"}}>•</div>
                                <div>Realizar los mismos usos descritos en los literales a y b anteriores;</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{paddingRight:"1rem"}}>•</div>
                                <div>Evaluar solicitudes que efectúe el cliente en el presente y/o futuro.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        style={{fontSize:"1rem"}}
                        className="mb-2"
                    >EJERCICIO DE DERECHOS:</div>
                    <div>
                        <div className="mb-2">
                            El Cliente reconoce que, como titular de los Datos Personales, tiene derecho a acceder a los datos 
                            en posesión de MADRISQUI, conocer las características de su tratamiento, rectificarlos en caso de ser 
                            inexactos o incompletos, solicitar que sean suprimidos o cancelados al considerarlos innecesarios 
                            para las finalidades previamente expuestas, o bien oponerse a su tratamiento para fines específicos.
                        </div>
                        <div>
                            A fin de ejercer los derechos antes mencionados, así como para revocar el consentimiento brindado 
                            para el tratamiento de datos para finalidades no relacionadas con la prestación de los servicios 
                            y/o ejecución de las disposiciones establecidas en los Términos y Condiciones, el cliente deberá 
                            presentar una solicitud (incluyendo el nombre del titular de los Datos Personales, domicilio o 
                            medio para recibir respuesta, documentos que acrediten su identidad o representación legal, descripción 
                            clara y precisa de los datos respecto de los que se busca ejercer el derecho y otros elementos o 
                            documentos que faciliten la localización de los datos) a través de la dirección electrónica 
                            <strong style={red}> soporte@tramitec.pe</strong>. 
                            De considerar que no se ha atendido su requerimiento de manera correspondiente, puede dirigirse 
                            ante la Autoridad Nacional de Protección de Datos Personales, con la finalidad de presentar algún 
                            reclamo o solicitud complementaria.
                        </div>
                    </div>
                </div>
            </div>
        </div>,container
    )
    
}
export default TermCond