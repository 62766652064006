import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

//Assets
import Patterm from './Assets/BackgroundImages/WhitePattermLarge.svg'

import './App.css';
import './CSS/SelectSearch.css';

//Components
import NaviBar from './components/navibar'
import LogOut from './components/login/logout'
import LogIn from './components/login/login'
import Register from './components/userRegistration'
import MyUser from './components/myuser/myuser'
import MyHistory from './components/myuser/myuserHistorial/myuserHistorialV2'
import Main from './components/main'
import HelpCenter from './components/helpcenter'
import NotFound from './components/notfound'

import Blog from './components/blog/blog'

import OtorgarPoder from './components/services/servOtorgar/otorgarForm/otorgarForm'
import OtorgarPreview from './components/services/servOtorgar/otorgarPreview'
import ServiceComplete from './components/services/serviceComplete'
import ServiceRegistered from './components/services/serviceRegistered'
import ServInscribir from './components/services/servInscribir/servInscribir'
import ServApostilla from './components/services/servApostilla/servApostilla'

import ServTramDoc from './components/services/servTramDoc/servTramDoc'
import ServPublicidad from './components/services/servPublicidad/servPublicidad'

import Contactenos from './components/contactenos'
import LoginpswRecovery from './components/login/PasswordRecovery/loginpswRecovery';
import LoginRecoveryChange from './components/login/PasswordRecovery/loginRecoveryChange';
import UpdatePsw from './components/updatepassword'


import PaymentForm from './components/payment/paymentform'
import PaymentReview from './components/payment/paymentreview'

//Admin Componets
import AdminPage from './components/admin/adminPage'




//Util
import caching from './Utils/caching'
import userLocationFunct from './Utils/userLocation';
import trackVersion from './Utils/trackVersion'

import eruda from './Hooks/useEruda'

export default function App (){    
    const [userLocation,setUserLocation] = useState(null)
    
    /* Prueba de asignacion condicionada */ 
    /*   const num1 = null
    const lve = num1?.myNumber || {myNumber:0} 
    console.log(lve) */

    /* eruda() */
    useEffect(()=>{            
        caching()
        trackVersion()
        userLocationFunct()
        setUserLocation(sessionStorage.getItem("App.userLocation"))
        
        const redirect = document.createElement("a")
        redirect.href = "/PaginaNoEncontrada"
        const bodyElement = document.body
        const bodyHeight = bodyElement.offsetHeight
        if(bodyHeight<100){
          bodyElement.appendChild(redirect)
          redirect.click()
        }
    },[])

    useEffect(() => {
      //Carga asincronica de estilos de pasarela de pagos      
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://api.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic-reset.css';
      link.async = true;
  
      document.head.appendChild(link);
  
      return () => {
        document.head.removeChild(link);
      };
    }, []);
    

    

    return <div id="App" className="App">
                 
      <Router>        
        <Route exact path="/">
          <div className="mainContainer">
            <NaviBar userLocation={userLocation} alterLocation={setUserLocation}/>          
            <Main userLocation={userLocation}/>
          </div>
        </Route>

        <Route path = "/login" children={<LogIn/>}/>          
        <Route path = "/registrarme" children={<Register/>}/>
        <Route path = "/logout" children={<LogOut/>}/>
        <Route exact path = "/recovery" children = {<LoginpswRecovery/>}></Route>
        <Route path = "/recovery/change" children={<LoginRecoveryChange/>}/>

        <Route path = "/main">
          <NaviBar/>          
          <div className="mainContainer">
            <div key="backgorund"
              style={{                  
                  position:"fixed",
                  height:"calc(100vh - 3.625rem)",
                  width:"100vw",
                  zIndex:"-1",                  
                  backgroundImage:`url(${Patterm})`,                    
                  backgroundSize:"contain"}}
            />                       
            
            <Route path = "/main/preguntasfrecuentes" children={<HelpCenter/>}/>            
            <Route exact path = "/main/servicios/otorgar" children={<OtorgarPoder/>}/>            
            <Route path = "/main/servicios/otorgar/vistaprevia" children={<OtorgarPreview/>}/>
            <Route path = "/main/servicios/complete" children={<ServiceComplete/>}/>         
            <Route exact path = "/main/servicios/inscribir" children={<ServInscribir/>}/>            
            <Route exact path = "/main/servicios/tramdoc" children = {<ServTramDoc/>}/>
            <Route exact path= "/main/servicios/apostilla" children={<ServApostilla/>}/>
            <Route exact path= "/main/servicios/publicidad" children={<ServPublicidad/>}/>
            <Route exact path= "/main/servicios/registrado" children={<ServiceRegistered/>}/>
            <Route path="/main/blog" children={<Blog/>}/>
            <Route exact path = "/main/usuario" children={<MyUser/>}/>
            <Route path = "/main/usuario/historial" children={<MyHistory/>}/>
            <Route path = "/main/updatePassword" children={<UpdatePsw/>}/>            
            <Route exact path = "/main/payment" children={<PaymentForm LOCAL_KEY={"App.payment"}/>}/>
            <Route path = "/main/payment/review" children={<PaymentReview LOCAL_KEY={"App.payment"}/>}/>
            <Route path = "/main/contactenos" children={<Contactenos/>}/>
            <Route exact path = "/main/admin" children={<AdminPage/>}/>
          </div>          
        </Route>        
        <Route path = "/PaginaNoEncontrada" children={<NotFound/>}/>
      </Router>
    </div>  
}

