import React,{useState,useEffect} from 'react'
import Axios from 'axios'

//Utils
import {getUrlApi} from '../../../../Utils/getUrl&Key';
import {moneyFormat} from '../../../../Utils/numberFormat';

import {Form,FloatingLabel} from 'react-bootstrap'


let colored = {border:"0.1rem solid red"}
let regular = {color: "black"}
const ubicacion = sessionStorage.getItem("App.userLocation")
export default function FormIns({navigation}) {
  const facultades = JSON.parse(localStorage.getItem(`App.facultad.${ubicacion}`))  
  const nFaculdates = facultades?facultades.length:0
  

  const [inscPerson,setInscPerson] = useState("");  

  const [complete, setComplete] = useState(true);
  const [errormsg, setErrormsg] = useState("");

  const [documento,setDocumento] = useState("")
  const [docnumero,setDocnumero] = useState("")
  const [nombres,setNombres] = useState("")
  const [apellidos,setApellidos] = useState("")
  const [precioFacs,setPrecioFacs] = useState(0) 
  const [precioBase,setPrecioBase] = useState(0)
  const [precioPerFac,setPrecioPerFac] = useState(0)
  const [precioInsc, setPrecioInsc] = useState(0);
  const [moneda, setMoneda] = useState("");  

  const [notaria, setNotaria] = useState("");  

  useEffect(()=>{    
  
    const inscriptor = JSON.parse(localStorage.getItem(`App.inscriptor.${ubicacion}`))
    if(ubicacion==="PER"){      
      setInscriptorRestricted()      
    }else{

      getCostosPoder()      

      if(inscriptor){
        setInscPerson(inscriptor.instramitec)
        setDocumento(inscriptor.inscriptor.documento)
        setDocnumero(inscriptor.inscriptor.docnumero)
        setNombres(inscriptor.inscriptor.nombres)
        setApellidos(inscriptor.inscriptor.apellidos)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  
  useEffect(() => {
    if(nFaculdates<5){      
      setPrecioFacs(precioPerFac*nFaculdates)
    }
    else{
      setPrecioFacs(precioPerFac*5)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precioPerFac]);

  
  async function getCostosPoder(){
    const location = sessionStorage.getItem("App.userLocation")
    const poderdantes = JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`)).length
    
    const Url = getUrlApi()+ `/common/inscripcioncosto/${poderdantes}`
    const headers = {"ubicacion":location}
    await Axios.get(Url,{headers:headers}).then(res=>{
      const data = res.data      
      setPrecioBase(data.costobase)
      setMoneda(data.moneda)
      setPrecioPerFac(data.costoxfac)
      setPrecioInsc(data.costoinsc) 
           
    })    
  }

  function checkdocnumero(event){
    if(documento==="D"){      
        if (Number.isNaN(parseInt(event.key))){
            event.preventDefault()
        }           
    }else if(documento==="PS"){
      var regex = new RegExp("^[0-9a-zA-Z]+$");
      if(!regex.test(event.key)){
        event.preventDefault()
      }
    } 
  }

  useEffect(()=>{
    let errorCount = 0
      const insData= [documento,docnumero,nombres,apellidos]
      if(inscPerson===""){
        errorCount += 1
      }
      if(inscPerson==="N"){
        if(docnumero.length!==8&&documento==="D"){
          setErrormsg("El DNI debe contener 8 digitos")
          errorCount+=1
        }

        if(!insData.every(item=>item!=="")){
          errorCount += 1
          setErrormsg("Completar todos los datos")
        }
      }
      if(errorCount>0){
        setComplete(false)
      }else{
        setComplete(true)        
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inscPerson,documento,docnumero,nombres,apellidos])

  

  function setInscriptor(){
    if(inscPerson==="S"){
      localStorage.setItem(`App.inscriptor.${ubicacion}`,JSON.stringify({instramitec:inscPerson,inscriptor:{documento:"",docnumero:"",nombres:"",apellidos:""}}))
    }else{
      localStorage.setItem(`App.inscriptor.${ubicacion}`,JSON.stringify({instramitec:inscPerson,inscriptor:{documento,docnumero,nombres:nombres.toLocaleUpperCase(),apellidos:apellidos.toLocaleUpperCase()}}))
    }
    navigation.next() 
  }

  function setInscriptorRestricted(){
    localStorage.setItem(`App.inscriptor.${ubicacion}`,JSON.stringify({instramitec:"S",inscriptor:{documento:"",docnumero:"",nombres:"",apellidos:""}}))
    navigation.next()
  }


  return (
    <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>
      <div className="opodfacListContainer StolzlMedium">
          <div className="opodfacListContent customBoxShadow">            
              <div className="mb-2">Listado de Precios</div>              
              <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Precio Base:</div><div className="StolzlMedium">{`${moneyFormat(precioBase)} ${moneda}`}</div></div>
              <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Facultades:</div><div className="StolzlMedium">{`${moneyFormat(precioFacs)} ${moneda}`}</div></div>
              <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>{`Inscripción:`}</div><div className="StolzlMedium">{inscPerson==="S"?`${moneyFormat(precioInsc)} ${moneda}`:"-"}</div></div>
              <hr/>
              <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium">
                <div>Sub Total:</div><div>{`${(precioFacs+precioBase+(inscPerson==="S"?precioInsc:0)).toPrecision((precioFacs+precioBase+(inscPerson==="S"?precioInsc:0)).toString().split('.')[0].length+2)} ${moneda}`}</div>
              </div>                  
          </div>
          <div style={{fontSize:"0.7rem",marginTop:"0.5rem"}}>{`Cada facultad seleccionada tiene un costo unitario de ${precioPerFac} ${moneda}.`}</div>
          <div style={{fontSize:"0.7rem"}}>A partir de la 6ta facultad no se agregará costos adicionales. </div>
      </div>

        <div style={{width:"30rem",maxWidth:"95%"}}>
          {ubicacion!=="PER"?<div className="customBoxShadow StolzlMedium" style={{backgroundColor:"white",borderRadius:"0.5rem",padding:"1rem",margin:"2rem 0"}}>
            <div><h5>¿Quién realizará la inscripción del poder?</h5></div>            
            <div style={{margin:"1rem 0"}}>              
              <div className="form-floating">
                <select value={inscPerson} className="form-select mb-2" onChange={(e)=>{setInscPerson(e.target.value)}}>
                  <option value="">SELECCIONAR</option>
                  <option value="S">TRAMITEC</option>                  
                  <option value="N">OTRA PERSONA</option>
                </select>
                <label htmlFor="floatingDoc">Encargado</label>
              </div>              
              {inscPerson==="N"?
                <div style={{marginRight:"2rem"}}>
                  
                    <div className="form-floating">
                      <select 
                        value={documento} 
                        id="floatingDoc" 
                        className='form-select mb-2' 
                        onChange={e=>setDocumento(e.target.value)}
                        style={complete? regular:documento===""? colored:regular}
                      >
                        <option value="">SELECCIONAR</option>
                        <option value="D">DNI</option>
                        <option value="CE">C. EXTRANJ</option>
                        <option value="PS">PASAPORTE</option>
                      </select>
                      <label htmlFor="floatingDoc">Documento</label>
                    </div>
                    <div className="form-floating">
                      <input
                        disabled = {documento===""} 
                        type='text'
                        className="form-control mb-3"
                        id="floatingInput"
                        name="docnumero"
                        placeholder="Numero"
                        onKeyPress={(e)=>checkdocnumero(e)}
                        onChange={e=>setDocnumero(e.target.value)}
                        value={docnumero}
                        style={complete ? regular:docnumero===""? colored:regular}
                      />
                        <label htmlFor="floatingInput">Número de Documento</label>
                    </div>

                    <div className="form-floating">
                      <input 
                        type='text' 
                        className="form-control mb-3 uppercase-input" 
                        id="floatingInput" name="nombres" 
                        placeholder="Nombres" 
                        onChange={e=>setNombres(e.target.value)}
                        value={nombres}
                        style={complete ? regular:nombres===""? colored:regular}
                      />
                      <label htmlFor="floatingInput">Nombres</label>
                    </div>

                    <div className="form-floating">
                      <input 
                        type='text' 
                        className="form-control mb-3 uppercase-input" 
                        id="floatingInput" name="apellidos" 
                        placeholder="Apellidos" 
                        onChange={e=>setApellidos(e.target.value)}
                        value={apellidos}
                        style={complete ? regular:apellidos===""? colored:regular}
                      />
                        <label htmlFor="floatingInput">Apellidos</label>
                    </div>
                </div>
              :null
              }             
            </div>
            {!complete?<div style={{color:"red",textAlign:"center"}}>{errormsg}</div>:null}                       
          </div>
          :<div className='whiteCard'>
            <div className='StolzlMedium'><h5>¿Dónde realizara la inscripción del poder?</h5></div>
            <FloatingLabel label='Notaria'>
                <Form.Select placeholder='Notaria' value={notaria} onChange={e=>setNotaria(e.target.value)}>
                    <option value=''>Seleccionar</option>
                    <option value='NP'>Notaria Paino</option>
                    <option value='O'>Otra</option>
                </Form.Select>              
            </FloatingLabel>
          </div>}

          <div key="costos" className="whiteCard StolzlMedium poderListaPrecio">
                <div>Detalle de Precios</div>
                <div className='redCard'>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Precio Base:</div><div className="StolzlMedium">{`${moneyFormat(precioBase)} ${moneda}`}</div></div>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Facultades:</div><div className="StolzlMedium">{`${moneyFormat(precioFacs)} ${moneda}`}</div></div>
                    {ubicacion!=="PER"?
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>{`Inscripción:`}</div><div className="StolzlMedium">{inscPerson==="S"?`${moneyFormat(precioInsc)} ${moneda}`:"-"}</div></div>:
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium"><div>Gastos Notariales</div><div>{notaria==="NP"?`${moneyFormat(0)} ${moneda}`:"-"}</div></div>
                    }
                    <hr/>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium"><div>Sub Total:</div><div>{`${moneyFormat(precioFacs+precioBase+(inscPerson==="S"?precioInsc:0))} ${moneda}`}</div></div>
                                                            
                </div>
                <div style={{fontSize:".8rem"}}>
                  El costo del trámite de legalización de inscripción por parte de TRAMITEC varía según número de apoderados. 
                </div>
            </div>
          <div style={{display:"flex", justifyContent:"space-around"}}>
              <button className='btn red-button' onClick={()=>navigation.previous()}>REGRESAR</button>
              <button className='btn green-button' disabled={!complete} onClick={()=>setInscriptor()}>SIGUIENTE</button>
            </div>   
        </div>         
    </div>
  )
}
