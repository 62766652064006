import React,{useState,useEffect,useRef} from 'react'
import SelectSearch, { fuzzySearch } from 'react-select-search';
import Axios from 'axios';

//Icons
import {MdModeEdit,MdDelete} from 'react-icons/md'

//Component
import CardInfo from '../otorgarObjects/reviewCardInfo'

//Utils
import {getUrlApi} from '../../../../Utils/getUrl&Key'
import {checkmail} from '../../../../Utils/checkmail'
import PhoneInput from '../../../../Utils/phoneInput'
import AddMiPersona from '../../../../Utils/addMiPersona'

let colored = {border:"0.1rem solid red"}
let regular = {color: "black"}
const OpPais = sessionStorage.getItem("App.userLocation")==="USA"?[    
    {name:"Australia",value:"AUS"},
    {name:"Canada",value:"CAN"},
    {name: "España",value:"ESP"},
    {name:"Estados Unidos", value: "USA"},
    {name:"Francia",value:"FRA"},
    {name:"Japón",value:"JPN"},
    {name:"México",value:"MEX"},
    {name:"Nueva Zelanda",value:"NZL"}    
  ]:[{name:"Perú",value:"PER"}]

 


export default function FormPod({navigation}) {    
    const [complete,setComplete] = useState(true)
    const [errormsg,setErrormsg] = useState("")   

    const [poderdantes, setPoderdantes] = useState([]);    
    const [showForm, setShowForm] = useState(false)   
    const [onEdit, setOnEdit] = useState(false);     
    const [states,setStates] = useState([])  

    
    const [documento, setDocumento] = useState("");
    const [docnumero, setDocnumero] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellidop, setApellidop] = useState("");
    const [apellidom, setApellidom] = useState("");
    const [pais, setPais] = useState("");
    const [estado, setEstado] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [codigopostal, setCodigopostal] = useState("");
    const [direccion, setDireccion] = useState("");    
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [estadocivil, setEstadocivil] = useState("");
    const [ocupacion, setOcupacion] = useState("");

    const [listaPersonas, setlistaPersonas] = useState([]);

    const formRef = useRef(null)

    const ubicacion = sessionStorage.getItem("App.userLocation")  
    const user = JSON.parse(localStorage.getItem("App.login"))
    const localpod = JSON.parse(localStorage.getItem(`App.poderdante.${ubicacion}`))
    const localapo = JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`))
    const localper = JSON.parse(localStorage.getItem("App.personas"))
    
    
    useEffect(()=>{

        getPersonas()
        if(localStorage.getItem(`App.poderdante.${ubicacion}`)){
            setPoderdantes(JSON.parse(localStorage.getItem(`App.poderdante.${ubicacion}`)))            
        }
        peruPrefix()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])    

    useEffect(() => {
        if(pais!==""){
        getStates(pais)}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pais]);
    
    useEffect(()=>{        
        if(poderdantes.length===0){
            setShowForm(true)
        }else{
            setShowForm(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[poderdantes])

    useEffect(()=>{
        let timer
        const formSavedCards =  document.getElementsByClassName("formSavedCards")
        if(formRef.current && formSavedCards.length){            
            const podCardSize = formSavedCards[0].getBoundingClientRect().height
            const totalSize =  poderdantes.length * podCardSize
            timer = setTimeout(() => {
                window.scrollTo(0,totalSize+190)           
            }, 200);
        }
        return()=>{
            clearTimeout(timer)
        }
      },[showForm])


    async function getPersonas(){
        if(user){
            const url = getUrlApi() + `/common/perfil/${user.id}`
            await Axios.get(url).then(res=>{
                const data = res.data
                if(data.length){
                    const personasPod = data.filter(persona=> persona.pais==ubicacion||(persona.pais!=='PER'&& ubicacion=='USA'))
                    
                    setlistaPersonas(personasPod)
                }else{
                    setlistaPersonas([])
                }
            })
        }        
    }
    
    
    async function getStates(country){
        const Url = getUrlApi() + `/common/estados/${country}`     
        let data
        let orderedStates = []
        try{       
          await Axios.get(Url)
          .then( res=>{
              if (res.status===200){ 
                  data = res.data
                  orderedStates = []
                  data.forEach(item => {
                    orderedStates.push({name:item.estado,value:item.id})                    
                    })
                  setStates(orderedStates)
              }
          })
        }
        catch(error){
          console.log(error)
        }    
      }

    function peruPrefix() {        
        if(ubicacion==="PER"){
            setPais("PER")
            setTelefono("51        ")
        }
    }

    function justnumber(event){      
        if (Number.isNaN(parseInt(event.key))){
            event.preventDefault()
        }            
    }

    function addPoderdante(){        
        let newpods = poderdantes
        const PodData = {
            documento,
            docnumero,
            nombre:nombre.toLocaleUpperCase(),
            apellidop:apellidop.toLocaleUpperCase(),
            apellidom:apellidom.toLocaleUpperCase(),
            pais,
            estado,
            ciudad:ciudad.toLocaleUpperCase(),
            codigopostal,
            direccion:direccion.toLocaleUpperCase(),
            telefono,
            email:email.toLocaleUpperCase(),
            estadocivil,
            ocupacion:ocupacion.toLocaleUpperCase()
        }
        newpods.push(PodData)        
        setPoderdantes(newpods)
    }

    function editPoderdante(documento,docnumero){
        const index = poderdantes.findIndex(pod=>pod.documento===documento&&pod.docnumero===docnumero)
        setShowForm(true)
        setOnEdit(true)        
        setDocumento(poderdantes[index].documento)
        setDocnumero(poderdantes[index].docnumero)
        setNombre(poderdantes[index].nombre.toLocaleUpperCase())
        setApellidop(poderdantes[index].apellidop.toLocaleUpperCase())
        setApellidom(poderdantes[index].apellidom.toLocaleUpperCase())
        setPais(poderdantes[index].pais)
        setEstado(poderdantes[index].estado)
        setCiudad(poderdantes[index].ciudad.toLocaleUpperCase())
        setCodigopostal(poderdantes[index].codigopostal)
        setDireccion(poderdantes[index].direccion.toLocaleUpperCase())        
        setTelefono(poderdantes[index].telefono)
        setEmail(poderdantes[index].email.toLocaleUpperCase())
        setEstadocivil(poderdantes[index].estadocivil)
        setOcupacion(poderdantes[index].ocupacion.toLocaleUpperCase())
        
    }

    function updatePoderdante(){
        const index = poderdantes.findIndex(pod=>pod.documento===documento&&pod.docnumero===docnumero)        
        poderdantes[index].nombre=nombre.toLocaleUpperCase()
        poderdantes[index].apellidop=apellidop.toLocaleUpperCase()
        poderdantes[index].apellidom=apellidom.toLocaleUpperCase()
        poderdantes[index].pais=pais
        poderdantes[index].ciudad=ciudad.toLocaleUpperCase()
        poderdantes[index].estado=estado
        poderdantes[index].codigopostal=codigopostal
        poderdantes[index].direccion=direccion.toLocaleUpperCase()
        poderdantes[index].telefono=telefono
        poderdantes[index].email=email.toLocaleUpperCase()
        poderdantes[index].estadocivil=estadocivil
        poderdantes[index].ocupacion=ocupacion.toLocaleUpperCase()       
    }


    function deletePoderdante(documento,docnumero){
        if(!onEdit){
            let newPods = poderdantes.filter(pod=>pod.documento+pod.docnumero!==documento+docnumero)
            setPoderdantes(newPods)
            if(newPods.length>0){
                localStorage.setItem(`App.poderdante.${ubicacion}`,JSON.stringify(newPods))
            }else{
                localStorage.removeItem(`App.poderdante.${ubicacion}`)
                peruPrefix()
            }
        }
    }

    /* function loadMiPersona (value){
        
        const persona = localper.filter(per=>per.documento+per.docnumero===documento+value)
        
        if(persona.length>0){                       
            setNombre(persona[0].nombre)
            setApellidop(persona[0].apellidop)
            setApellidom(persona[0].apellidom)
            setPais(persona[0].pais)
            setEstado(persona[0].estado)
            setCiudad(persona[0].ciudad)
            setCodigopostal(persona[0].codigopostal)
            setDireccion(persona[0].direccion)            
            setTelefono(persona[0].telefono)
            setEmail(persona[0].email)
            setEstadocivil(persona[0].estadocivil)
            setOcupacion(persona[0].ocupacion)            
        }
    } */

    function cleanForm(){
        setDocumento("")
        setDocnumero("")
        setNombre("")
        setApellidop("")
        setApellidom("")
        setPais("")
        setEstado("")
        setCiudad("")
        setCodigopostal("")
        setDireccion("")
        
        setTelefono("")
        setEmail("")
        setEstadocivil("")
        setOcupacion("")
        setComplete(true)
        setErrormsg("")
    }

    function checkdocnumero(event){
        if(documento==="D"){      
            if (Number.isNaN(parseInt(event.key))){
                event.preventDefault()
            }           
        }else if(documento==="PS"){
          var regex = new RegExp("^[0-9a-zA-Z]+$");
          if(!regex.test(event.key)){
            event.preventDefault()
          }
        } 
      }

    
    function revisar(){
        let errorCount = 0
        const PodData = [
            documento,docnumero,nombre,apellidop,apellidom,
            pais,estado,ciudad,codigopostal,direccion,
            telefono,email,estadocivil,ocupacion]

        const isComplete = PodData.every(item=>item!=="")        
        if(!isComplete){
            errorCount += 1
            setComplete(false)
            setErrormsg("Completar todos los datos")
        }

            //Se verifica si el poderdante no esta repetido
        if(localpod){ //Solo se reliza la verificacion de localpod existe (si exite la data en el local storage)
            const existe = localpod.filter(pod=> pod.documento+pod.docnumero === documento+docnumero)
            if(existe.length>0&&!onEdit){            
            setErrormsg("El poderdante ya esta registrado")
            errorCount+=1
            }        
        }

        if(localapo){
            const existe = localapo.filter(apo=> apo.documento+apo.docnumero === documento+docnumero)
            if(existe.length>0){
            setErrormsg("Ya esta registrado como Apoderado")
            errorCount+=1
            }
        }

        if(docnumero.length!==8&&documento==="D"){
            setErrormsg("El DNI debe contener 8 digitos")
            errorCount+=1
          }

        if(!checkmail(email)){
            setErrormsg("Formato de Correo no Valido")
            errorCount+=1
        }
        
            
        if(errorCount===0){
            if(!onEdit){
                addPoderdante()
            }else{
                updatePoderdante()
            }
            setShowForm(false)
            setOnEdit(false)            
            cleanForm()
            
            localStorage.setItem(`App.poderdante.${ubicacion}`,JSON.stringify(poderdantes))                        
        }
        
    }


  return (
    <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
        <div style={{width:"30rem",maxWidth:"95%"}}>
            <div>
                <div className='serviceTittle'>Otorgamiento de Poder</div>
                <hr/>
            </div>
            <div className='whiteCard StolzlMedium'>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>
                        <h5>¿Quién otorga el poder?</h5>                                              
                    </div>
                    <div>                        
                        <AddMiPersona formType='pod' listaPersonas={listaPersonas} updateList={setPoderdantes}/>
                    </div>
                </div>               
                
                <div>
                    <div style={{width:"100%"}}>
                        
                        {poderdantes.length>0?poderdantes.map(pod=>{return(
                            <div key={pod.docnumero} className='customBoxShadow formSavedCards'>
                                <div className='d-flex'>
                                    <div style={{width:"85%"}}>{pod.nombre} {pod.apellidop}</div>
                                    <div style={{width:"15%",fontSize:"1.2rem",display:"flex",justifyContent:"space-between"}}>
                                        <MdModeEdit onClick={()=>editPoderdante(pod.documento,pod.docnumero)} style={{color:"navy", cursor:"pointer"}}/>                                        
                                        <MdDelete  onClick={()=>deletePoderdante(pod.documento,pod.docnumero)} style={{color:!onEdit?"darkred":"gray",cursor:"pointer"}}/>
                                    </div>
                                </div>
                                <CardInfo personType="POD" data={pod} noEdit={true}/>                                
                            </div>
                            )}
                        ):null}
                        
                        {poderdantes.length>=1&&!showForm?
                            <div className='d-flex formSavedCards customBoxShadow justify-content-between'>
                                <div className='d-grid align-content-center'>
                                    <span>Agregar poderdante adicional:</span>
                                </div>
                                <div className='circle-medium red-button' onClick={()=>{
                                    setShowForm(true)                                    
                                    peruPrefix()
                                    }
                                }>+</div>
                            </div>                           
                        :null}
                    </div>
                    {showForm?
                        <div ref={formRef} className='customBoxShadow' style={{margin:".5rem 1rem",padding:"1rem",borderRadius:"0.5rem"}}>
                            
                            Datos Personales
                            <hr style={{margin:"0 0 0.5rem"}}/>
                            <div className="form-floating" >
                                <select
                                    disabled = {onEdit} 
                                    className="form-select form-select-md mb-3"
                                    id="floatingInput" 
                                    placeholder="documento" 
                                    name="documento" 
                                    onChange={(e)=>setDocumento(e.target.value)} 
                                    value={documento}            
                                    style={complete? regular:documento===""? colored:regular}
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="D">DNI</option>
                                    <option value="PS">Pasaporte</option>            
                                </select>
                                <label htmlFor="floatingInput">Documento</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    disabled = {documento===""||onEdit} 
                                    type='text'
                                    className="form-control mb-3"
                                    id="floatingInput"
                                    name="docnumero"
                                    placeholder="Numero"
                                    onKeyPress={(e)=>checkdocnumero(e)}
                                    onChange={e=>{
                                        setDocnumero(e.target.value)
                                        /* if(localper){loadMiPersona(e.target.value)} */
                                    }}
                                    value={docnumero}
                                    style={complete ? regular:docnumero===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Número de Documento</label>
                                </div>

                            <div className="form-floating">
                                <input 
                                    type='text' 
                                    className="form-control mb-3 uppercase-input" 
                                    id="floatingInput" name="nombre" 
                                    placeholder="Nombres" 
                                    onChange={e=>setNombre(e.target.value)}
                                    value={nombre}
                                    style={complete ? regular:nombre===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Nombres</label>
                            </div>

                            <div className="form-floating">
                                <input 
                                    type='text' 
                                    className="form-control mb-3 uppercase-input" 
                                    id="floatingInput" name="apellidop" 
                                    placeholder="Apellido Paterno" 
                                    onChange={e=>setApellidop(e.target.value)}
                                    value={apellidop}
                                    style={complete ? regular:apellidop===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Apellido Paterno</label>
                            </div>

                            <div className="form-floating">
                                <input 
                                    type='text' 
                                    className="form-control mb-3 uppercase-input" 
                                    id="floatingInput" 
                                    name="apellidom" 
                                    placeholder="Apellido Materno" 
                                    onChange={e=>setApellidom(e.target.value)}
                                    value={apellidom}
                                    style={complete ? regular:apellidom===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Apellido Materno</label>
                            </div>
                            Dirección
                            <hr style={{margin:"0 0 .5rem"}}/>    
                            <div className="mb-3 GraphikR">
                                <SelectSearch 
                                options={OpPais} 
                                search
                                disabled={ubicacion==="PER"}
                                value={pais} 
                                filterOptions={fuzzySearch} 
                                autoComplete="new-password"
                                placeholder="Seleccionar País" 
                                onChange={(value)=>{
                                    setPais(value)
                                    setEstado("")
                                }}
                                />            
                            </div>

                            <div className="mb-3 GraphikR">
                                <SelectSearch  options={states} search filterOptions={fuzzySearch} autoComplete="new-password"
                                placeholder={ubicacion==="PER"?"Departamento":"Estado"} value={estado} 
                                onChange={(value)=>setEstado(value)}/>            
                            </div>

                            <div className="form-floating">
                                <input type='text' className="form-control mb-3 uppercase-input" id="floatingInput" 
                                name="ciudad" placeholder="ciudad" onChange={e=>setCiudad(e.target.value)} value={ciudad}
                                style={complete ? regular:ciudad===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Ciudad</label>
                            </div>

                            <div className="form-floating">
                                <input type='text' className="form-control mb-3" id="floatingInput" onKeyPress={e=>justnumber(e)} onPaste={e=>e.preventDefault()}
                                name="codigopostal" placeholder="codPostal" onChange={e=>setCodigopostal(e.target.value)} value={codigopostal} autoComplete="off"
                                style={complete ? regular:codigopostal===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Código Postal</label>
                            </div>


                            <div className="form-floating">
                                <input type='text' className="form-control mb-3 uppercase-input" id="floatingInput" 
                                name="direccion" placeholder="direccion" onChange={e=>setDireccion(e.target.value)} value={direccion}
                                style={complete ? regular:direccion===""? colored:regular}
                                />
                                <label htmlFor="floatingInput">Dirección</label>
                            </div>

                            Contacto                            
                            <hr style={{margin:"0 0 .5rem"}}/>                            
                            <PhoneInput saveValue={setTelefono} currentValue={telefono} disabled={ubicacion==="PER"}/>
                            <div className="form-floating">        
                                <input 
                                type='text' 
                                className="form-control mb-3 uppercase-input" 
                                id="floatingInput" 
                                name="email" 
                                placeholder="Email"                                
                                onChange={e=>setEmail(e.target.value)} 
                                value={email}

                                style={!complete&&email===""?colored:regular}/>
                                <label htmlFor="floatingInput">Correo</label>
                            </div>

                            <div className="form-floating">
                                <select 
                                className="form-select form-select-md mb-3" id="floatingInput" 
                                placeholder="Estado Civil" name="estadocivil" 
                                onChange={e=>setEstadocivil(e.target.value)} value={estadocivil}
                                style={(!complete&&estadocivil==="")?colored:regular}>
                                    <option value="">Seleccionar</option>
                                    <option value="07">SOLTERO(A)</option>
                                    <option value="08">CASADO(A)</option>
                                    <option value="09">VIUDO(A)</option>
                                    <option value="10">DIVORCIADO(A)</option>
                                    
                                </select>
                                <label htmlFor="floatingInput">Estado Civil</label>
                            </div>

                            <div className="form-floating">
                                <input 
                                type='text' 
                                className="form-control mb-3 uppercase-input" 
                                id="floatingInput" 
                                name="ocupacion" 
                                placeholder="Ocupación" 
                                onChange={e=>setOcupacion(e.target.value)} 
                                value={ocupacion}
                                style={(!complete&&ocupacion==="")?colored:regular}/>
                                <label htmlFor="floatingInput">Ocupación</label>
                            </div>                                                         
                            <div style={{textAlign:"center",color:"red"}}><p>{errormsg}</p></div>  
                            <div style={{display:"flex",justifyContent:"space-around"}}>
                                <button className='btn red-button'
                                    onClick={()=>{
                                        setShowForm(false)
                                        setOnEdit(false)
                                        cleanForm()                                            
                                        }
                                    }
                                    disabled={poderdantes.length===0}
                                >Cancelar</button>
                                <button className='btn blue-button' 
                                    onClick={()=>{
                                            revisar()                                            
                                        }
                                    }
                                >Guardar</button>                                    
                            </div>
                        </div>                       
                        :<></>}                                        
                </div>                
            </div>
            <div style={{display:"flex",justifyContent:"center",marginBottom:"1rem"}}>                            
                <button disabled={poderdantes.length===0} className='btn green-button' onClick={()=>navigation.next()}>SIGUIENTE</button>    
            </div>
        </div>
    </div>
  )
}
