import React,{useState} from 'react';
import {useHistory,useLocation} from 'react-router-dom';
import {Form,FloatingLabel} from 'react-bootstrap';

//Assets
import Patterm from './../../../Assets/BackgroundImages/WhitePattermSmall.svg';

//Util
import encript from '../../../Utils/encript'
import axios from 'axios';
import {getUrlApi} from '../../../Utils/getUrl&Key';



const StaticPath = "/recovery/change/"
export default function LoginRecoveryChange() {
  const location = useLocation()
  const history = useHistory()
  const [password,setPassword] = useState()
  const [cpassword,setCpassword] = useState()
  const path = location.pathname
  let buffer = path.slice(StaticPath.length)
  let string = Buffer.from(buffer,"base64").toString()  
  const mail = string.split(",")[0]
  const token = string.split(",")[1]  


  async function handleUpdatePassword(){
    if(password===cpassword){
      if(password.length>7){
        const Url = getUrlApi() + "/users/registrarContrasena"
        const data = {token:token,password:encript(password)}
        
        try{
          await axios.post(Url,data).then(res=>{
            alert(res.data.message)
            if(res.status===201){              
              history.push("/login")
            }
          })
        }catch(error){
          alert(`Ha ocurrido un error. Porfavor intentar nuevamente en unos minutos\n${error}`)
        }
      }else{alert("La contraseña debe tener minimo 8 caracteres")}
    }else{alert("Las Contraseñas no coinciden")}
  }

  const handleSubmit = e => {
    e.preventDefault();       
  }
  return (
    <div 
      style={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        backgroundColor:"var(--red-Color)",
        height:"100vh",
      }}
    >
        <div className='fade-in'
          style={{
            width:"27.5rem",
            height:"20rem",
            backgroundColor:"white",
            padding:"1.5rem 2.5rem",
            borderRadius:"0.5rem",
            position:"relative",
            zIndex:"2",
          }}
        >
          <div className='mb-3'>
            <div>Restablecimiento de contraseña</div>            
          </div>
          <Form onSubmit={handleSubmit}>
            <FloatingLabel label="Usuario" className='mb-3'>
            <Form.Control value={mail} placeholder="Usuario" readOnly/>
            </FloatingLabel>
            <FloatingLabel label="Contraseña" className='mb-2'>
              <Form.Control type="password" placeholder='Contraseña' onChange={e=>setPassword(e.target.value)}></Form.Control>
            </FloatingLabel>
            <FloatingLabel label="Confirmar Contraseña" className='mb-4'>
              <Form.Control type="password" placeholder="Confirmar Contraseña" onChange={e=>setCpassword(e.target.value)}></Form.Control>
            </FloatingLabel>
            <div>
              <button className='btn red-button' onClick={()=>handleUpdatePassword()}>Confirmar</button>
            </div>
          </Form>
        </div>
        <div style={{
                position:"fixed",
                top:"0",
                right: "0",
                zIndex: "1"}}
                className = "fade-in"
            >
                <img 
                    src={Patterm}
                    alt="background_patter"                    
                    style={{
                        height:"100vh",
                        opacity:"0.4"
                    }}
                ></img>
        </div>          
    </div>
  )
}
