import React,{useState} from 'react'
import {FloatingLabel, Form, FormLabel, Button} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'

//Icons
import {AiFillEyeInvisible,AiFillEye} from 'react-icons/ai';

//Utils
import encript from './../../Utils/encript'
import {getUrlApi} from '../../Utils/getUrl&Key'

export default function Logintramitec({navigation,SESSION_KEY,redirect}) {
    const history = useHistory();
    const [user,setUser] = useState("");
    const [pswd,setPswd] = useState ("");
    const [loged,setLoged] = useState("");
    const [showpsw,setShowpsw] = useState(false);    

    const handleSubmit = e => {
        e.preventDefault();       
    }

    const logInPost = async () =>{
        if (user === ""||pswd  === ""){
            setLoged("Ingrese Usuario y Contraseña")
        }
        else {            
            try{
                const Url = getUrlApi() + "/users/login"        
                await Axios.post(Url,{"username":user,"password":encript(pswd)})
                .then( res=>{
                    
                    if (res.status===200){
                        const {id,name,token,username,rol} = res.data
                        
                        if(rol){
                            localStorage.setItem(SESSION_KEY,JSON.stringify({name:name, username:username,id:id,token:token,AccType:"tramitec",rol:rol}))
                        }else{
                            localStorage.setItem(SESSION_KEY,JSON.stringify({name:name, username:username,id:id,token:token,AccType:"tramitec"}))
                        }
                        history.replace(redirect)
                    }else{setLoged(res.data.message)}
                })
            }
            catch(error){
                if(error.response){
                    setLoged(error.response.data.message)
                }else{
                    alert("Ha ocurrido un problema, por favor intentelo más tarde")
                }                            
                
            }
        }
    }

    return (
        <Form onSubmit={handleSubmit}>                    
        <FloatingLabel label="Usuario" className="mb-2">
            <Form.Control name="user" type="text" placeholder="Usuario" onChange={(e)=>setUser(e.target.value)}/>
        </FloatingLabel>
        <FloatingLabel label="Contraseña" className="mb-3">
            <Form.Control name="pswd" type={showpsw?"text":"password"} placeholder= "Contraseña" onChange={(e)=>setPswd(e.target.value)}/>
            <div 
                style={{position:"absolute",top:"25%",right:"4.5%",cursor:"pointer"}}
            >
                {showpsw?
                    <AiFillEyeInvisible 
                        style={{fontSize:"1.5rem",color:"gray"}}
                        onClick={()=>setShowpsw(false)}/>:
                    <AiFillEye 
                        style={{fontSize:"1.5rem",color:"black"}}
                        onClick={()=>setShowpsw(true)}/>
                }
            </div>
        </FloatingLabel>

        <div style={{marginBottom:"1.2rem"}}>
            <div style={{display:"flex",justifyContent:"center"}}>                    
            <Button 
                variant="none" 
                className="red-button mb-2 GraphikSB"
                style={{width:"100%"}} 
                type="submit" 
                onClick={logInPost}
            >Iniciar Sesión</Button>
            </div>
            <div style={{display:"flex",justifyContent:"center"}}>
            <Button                
                variant="none"
                className="blue-button GraphikSB"
                style={{width:"100%"}}
                onClick={()=>history.push({pathname:"/registrarme"})}
            >Registrarme</Button>
            </div>
            
        </div>
        <div style={{textAlign:"center", marginTop:"0.3rem"}}>            
            <div className="falseLink" onClick={()=>history.push("/recovery")}>¿Olvidaste tu contraseña?</div>
        </div>
        <div style={{cursor:"pointer",marginTop:"2rem"}} onClick={()=>navigation.previous()}>Volver</div>
        <div style={{display:"flex", justifyContent:"center"}} className="mt-3">
            <FormLabel style={{marginLeft:"0.5rem", color:"red"}}>{loged}</FormLabel>
        </div>
    </Form>
    )
}
