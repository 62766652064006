import ReactDOM from 'react-dom'
import React from 'react'
import {Alert} from 'react-bootstrap'

const DualAlert = (header="Alerta!",message="Mensaje de Alerta!",option1="Option1",callback1=()=>{},option2="Option2",callback2=()=>{}) => {
    const parentElement = document.getElementById("App")
    const container = document.createElement("div")

    container.className = "customAlertContainer"    
    parentElement.appendChild(container)
 
    function handleConfirm1(){
        callback1()
        handleClose()
    }

    function handleConfirm2(){
        callback2()
        handleClose()
    }

    function handleClose(){
        ReactDOM.unmountComponentAtNode(container)
        container.parentNode.removeChild(container)        
    }
    
    return ReactDOM.render(
        <Alert variant="light" className="customAlert mx-auto"  onClose={()=>handleClose()} dismissible>
            <Alert.Heading>{header}</Alert.Heading>
            {message.split("\n").map(line=><p key={line[1]+"L"+line[0]}>{line}</p>)}
            <hr/>
            <div className="d-flex justify-content-end">
                <button style={{marginRight:"0.2rem"}} className='btn red-button' onClick={()=>handleConfirm1()}>{option1}</button>
                <button className='btn green-button' onClick={()=>handleConfirm2()}>{option2}</button>
            </div>
        </Alert>,container
    )
    
}
export default DualAlert