import React, {useState,useEffect,useRef} from 'react'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'

import SelectSearch, { fuzzySearch } from 'react-select-search';

import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

//Components
import InsSteps from '../../../Utils/InsSteps'
import InsPdf from '../servInscribir/InsPdf' 

//Icons
import {MdCloudUpload,MdDelete,MdOutlinePreview} from 'react-icons/md'

//Utils
import loading from '../../../Utils/loading'
import {getUrlApi} from '../../../Utils/getUrl&Key'
import getStateName from '../../../Utils/getStateName'
import {moneyFormat} from '../../../Utils/numberFormat'
import LoginRequired from '../../../Utils/loginRequired'

const maxFilesSize = 10485760
export default function InsApostilla() {
    const history = useHistory()
    const inputRef = useRef(null)

    const [docsApostilla, setdocsApostilla] = useState([])
    const [moneda, setMoneda] = useState("");
    const [file,setFile] = useState(null)
    const [fileError1,setFileError1] = useState({message:"",onError:true})        
    const [viewfile,setViewFile] = useState(null)
    const [tipoApoDoc,setTipoApoDoc] = useState(null)
    const [precioApoDoc,setPrecioApoDoc] = useState(0)    
    const [states, setStates] = useState([])
    const [remision, setRemision] = useState("T")
    const [rpais, setRpais] = useState("")
    const [restado, setRestado] = useState("")
    const [rciudad, setRciudad] = useState("")
    const [rcodPostal, setRcodPostal] = useState("")
    const [rdireccion, setRdireccion] = useState("")
    const [receptor,setReceptor]=useState("")
    const [observaciones, setObservaciones] = useState("")
    
    const [precioCourrier, setPrecioCourrier] = useState(0)    

    const steplist = [
        {step:"Registra tu solicitud.",xtraInfo:"Si el documento a apostillar no se encuentra listado, deberás escanearlo junto con tu solicitud para que sea revisado y aprobado."},
        {step:"TRAMITEC aprueba tu Solicitud.",xtraInfo:"Las solicitudes de documentos que aparecen en el listado son aprobados de manera inmediata. Si el documento que requieres apostillar no se encuentra listado, deberás enviarlo escaneado junto con tu solicitud para su revisión y aprobación."},
        {step:"Realiza el Pago del Servicio.",xtraInfo:""},
        {step:"Envía el Documento Original.",xtraInfo:"Envía el documento original a nuestras oficinas en: Calle Las Palomas 523, Surquillo, Lima. Código Postal: 15047."},
        {step:"Espera mientras llevamos a cabo tu solicitud.",xtraInfo:""},
        {step:"Recoge o recibe tu Documento Apostillado.",xtraInfo:"Si al momento de ingresar la solicitud nos indicaste que el documento sea enviado a tu domicilio, se te notificará de su salida y un número de rastreo.       Si solicitaste recoger el documento de nuestras oficinas, te notificaremos al momento en que puedas pasar por él. "}
    ]
    useEffect(()=>{   
        
        getDocApostilla()
        window.scrollTo(0,0)        
    },[])

    useEffect(()=>{
        if(rpais!==""){        
        getStates()
        getPrecioCurrier()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[rpais])

    useEffect(()=>{
        if(remision==="T"){
            setPrecioCourrier(0)
        }else{
            if(sessionStorage.getItem("App.userLocation")==="PER"){
                setRpais("PER")               
            }
            getPrecioCurrier()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[remision])

    function justnumber(event){      
        if (Number.isNaN(parseInt(event.key))){
            event.preventDefault()
        }            
    } 

    const session = JSON.parse(localStorage.getItem("App.login"))
    let userId
    if(session){
        userId = session.id
    }

    async function getDocApostilla(){
        const headers = {"ubicacion":sessionStorage.getItem("App.userLocation")}
        const Url = getUrlApi() + `/apostilla/tramites`
        try {
            await Axios.get(Url,{headers:headers}).then(res=>{
                setdocsApostilla(res.data)
                setMoneda(res.data[0].moneda)
            })
        } catch (error) {            
        }
    }
    

    async function getStates(){
        const Url = getUrlApi()+ `/common/estados/${rpais}`  
        let data
        let orderedStates = []
        try{            
          await Axios.get(Url)
          .then( res=>{
              if (res.status===200){ 
                  data = res.data
                  orderedStates = []
                  data.map(item => {
                    orderedStates.push({name:item.estado,value:item.id})
                    return null
                    })
                  setStates(orderedStates)
              }
          })
        }
        catch(error){

        }    
      }

    async function getPrecioCurrier(){
        const Url = getUrlApi() + `/apostilla/courrier/${rpais}`
        const ubicacion = sessionStorage.getItem("App.userLocation")
        const headers = {"ubicacion":ubicacion}
        try{
            await Axios.get(Url,{headers:headers}).then(res=>{
                if(res.status===200){
                    setPrecioCourrier(res.data.precio)
                    
                }
            })
        }
        catch(error){

        }
    }

    function handleFileChange(file){
        if(file.size>maxFilesSize){
            alert("El archivo ha superado el limite maximo de peso")
        }else{
            if(file.type==="application/pdf"||file.type.split('/')[0]==="image"){
                const noError = {message:"",onError:false}               
                setFile(file)
                setFileError1(noError)
                if(inputRef.current){
                    inputRef.current.value = ''
                }
            }else{
                const newError = {message:"Se requiere un archivo imagen o archivo pdf",onError:true}                                      
                setFileError1(newError)
            }
        }        
    }

    function handleFileDelete(){
        const noFileError = {message:"",onError:true}                               
            setFile(null)
            setFileError1(noFileError)
            
    }

    function aviableSend(){
        const files = [file]
        let compApostilla //Vericar si la selecion de tipo de documento esta completo
        let compRemision //Verficar si le direcion de remision esta completo
        if(session){
            const filterArray = files.filter(file => file!=null)            
            if(tipoApoDoc===null){                               
                compApostilla = false
            }else{
                if(tipoApoDoc===0)
                    if(filterArray.length>0){
                        compApostilla = true
                    }else{
                        compApostilla = false
                }else{
                    compApostilla = true
                }
            }
            if(remision==="T"){
                compRemision = true
            }else{
                if(rpais!==""&restado!==""&rciudad!==""&rcodPostal!==""&rdireccion!==""){
                    compRemision = true
                }else{
                    compRemision = false
                }

            }            
            return (compApostilla&compRemision)            
        }else{            
            return false
        }
    }

    


    async function sendFile(){
        const header = {
            "Content-Type":"application/json",
            "authorization": JSON.parse(localStorage.getItem("App.login")).token,
            "ubicacion":sessionStorage.getItem("App.userLocation")
        }
        loading()
        const filesRaw = [file]
        const filesClean = filesRaw.filter(item => item!=null)        
        const Url = getUrlApi() + "/apostilla"

        const promisesB64 = filesClean.map(async item=>{
            console.log(item);
            const extension = item.type.split('/')[1]
            const name = item.name.split('.')[0]
            const base64 = await convertBase64(item)
            
            return {file:base64,name,ext:extension}
        })       

        const adjuntos = await Promise.all(promisesB64)
        
        let data
        if (tipoApoDoc===0){            
            if(remision==="T"){                
                data = {
                    userid:userId,
                    tipo:tipoApoDoc,
                    remitente:remision,
                    adjunto:adjuntos,
                    obs:observaciones                
                }
            }else{
                data = {
                    userid:userId,
                    tipo:tipoApoDoc,
                    remitente:remision,
                    adjunto:adjuntos,
                    obs:observaciones,
                    receptor:receptor.toLocaleUpperCase(),
                    ubicacion:{
                        pais:rpais,
                        estado:restado,
                        ciudad:rciudad.toLocaleUpperCase(),
                        codigopostal:rcodPostal,
                        direccion:rdireccion.toLocaleUpperCase(),
                    }                
                }
            }
        }else{
            if(remision==="T"){
                data = {
                    userid:userId,
                    tipo:tipoApoDoc,
                    remitente:remision
                }
            }else{
                data = {
                    userid:userId,
                    tipo:tipoApoDoc,
                    remitente:remision,
                    receptor:receptor.toLocaleUpperCase(),                    
                    ubicacion:{
                        pais:rpais,
                        estado:restado,
                        ciudad:rciudad.toLocaleUpperCase(),
                        codigopostal:rcodPostal,
                        direccion:rdireccion.toLocaleUpperCase(),
                    }                
                }
            }
        }

         
        try{            
            await Axios.post(Url,data,{headers:header}).then(res=>{                              
                if(res.status===201){    
                    const resData = res.data
                    loading(undefined,true)
                    history.push('/main/servicios/registrado',{email:resData.email,tramiteId:resData.id,servicio:"APO",apoType:tipoApoDoc})
                }else{
                    alert("Ha ocurrido un problema, por favor intentelo más tarde.")
                    loading(undefined,true)
                }                
            })
        }catch(error){
            const response = error.response            
            if(response){
                if(response.status===403){
                loading(undefined,true)
                alert("Su sesión ha caducado")
                history.replace("/logout")
                }else{
                    alert(`Ha ocurrido un problema, por favor intentelo más tarde.\n${response.data.message}`)
                    loading(undefined,true)        
                }
            }else{
                alert(`Ha ocurrido un problema, por favor intentelo más tarde.\n${error}`)
                loading(undefined,true)
            }
        }
    
    }

    function convertBase64(file){        
        return new Promise(async (resolve,reject)=>{            
            const ab = await file.arrayBuffer()
            const base64 = Buffer.from(ab).toString('base64')
            resolve(base64)
        })
    }
    

    function renderpdf(){
        if(viewfile){
            if(viewfile.type==="application/pdf"){
                return (<InsPdf file={viewfile}></InsPdf>)
            }
            else{
                const urlfile = URL.createObjectURL(viewfile)
                return (<img style={{maxWidth:"98%",maxHeight:"98%",marginTop:"1%"}} alt='Vista previa documento' src={urlfile}></img>)
            }
        }else{
            return (<div style={{                    
                    width:"max-content",
                    height:"min-content",
                    backgroundColor:"white",
                    padding:"0.4rem",
                    border: "0.2rem dashed lightgray",
                    borderTop:"none",
                    }}>
                        No hay vista previa disponible.
                    </div>)
        }
    }

    /* -------------------Renderizacion de Remision de Documentos------------------------ */
    function renderDireccion(){
        if(remision==="O"){
            return(
                <div style={{marginBottom:"1rem"}}>
                    <div>
                        <div style={{margin:"1rem 0"}}>
                            <FloatingLabel label="Nombres y Apellidos">
                                <Form.Control
                                    placeholder='Nombres y Apellidos'
                                    className="uppercase-input"
                                    value={receptor}
                                    onChange={e=>setReceptor(e.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="form-floating">
                                <select
                                    disabled={(sessionStorage.getItem("App.userLocation")==="PER")} 
                                    className="form-select form-select-md mb-3" 
                                    id="tipotramitec" 
                                    placeholder="País" 
                                    name="tramdoc" 
                                    value={rpais}
                                    onChange={(e)=>{
                                        setRpais(e.target.value)                                   
                                        setRestado("")                                        
                                    }}                                    
                                >
                                    <option>Seleccionar Pais</option>
                                    <option value="AUS">Australia</option>
                                    <option value="CAN">Canada</option>                                    
                                    <option value="ESP">España</option>
                                    <option value="USA">Estados Unidos</option>
                                    <option value="FRA">Francia</option>                                   
                                    <option value="JPN">Japón</option>
                                    <option value="MEX">México</option>
                                    <option value="NZL">Nueva Zelanda</option>                                    
                                    <option value="PER">Perú</option>                                    
                                </select>
                                <label htmlFor="floatingInput">Pais</label>
                        </div>
                        <div className="mb-3 GraphikR" style={{borderRadius:"0.25rem"}}>
                            <SelectSearch  
                                options={states} 
                                search 
                                filterOptions={fuzzySearch} 
                                autoComplete="new-password"
                                placeholder="Estado / Departamento" 
                                value={restado}
                                onChange={(value)=>{
                                    setRestado(value)
                                    getStateName(rpais,value)                        
                                }} 
                            />
                        </div>
                        <FloatingLabel label="Ciudad" className='mb-2'>
                            <Form.Control 
                                placeholder="Ciudad"
                                className="uppercase-input"
                                value={rciudad} 
                                onChange={e=>{setRciudad(e.target.value)}}                                
                                />
                        </FloatingLabel>
                        <FloatingLabel label="Código Postal" className='mb-2'>
                            <Form.Control 
                                placeholder='Código Postal'
                                value={rcodPostal} 
                                onChange={e=>{setRcodPostal(e.target.value)}} 
                                onKeyPress={(e)=>justnumber(e)}                                
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Dirección">
                            <Form.Control 
                                placeholder="Dirección"
                                className="uppercase-input"
                                value={rdireccion} 
                                onChange={e=>{setRdireccion(e.target.value)}}                                
                                />
                        </FloatingLabel>

                    </div>
                </div>
            )
        }else return null
    }

    /* -------------------------------------------------------------------------------------- */

  return (
    <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>
            <div style={{width:"30rem",maxWidth:"95%"}}>
                <InsSteps list={steplist}/>
                <div>
                    <div className='serviceTittle'>Apostillado de Documentos</div>
                    <hr/>
                    <LoginRequired/>
                </div>
                <div className="whiteCard">
                    <div style={{marginTop:"1rem",textAlign:"center"}}>¿Qué tipo de documento desea apostillar?</div>
                        <div style={{display:"flex",justifyContent:"center",marginTop:"0.8rem",marginBottom:"0.8rem" }}>                        
                            <div key="radio">                            
                                {docsApostilla.map(doc=>{ return <div key={`radio-${doc.id}`} style={{display:"flex", fontSize:"1rem",justifyContent:"space-between"}}>
                                
                                <Form.Check
                                    type="radio"
                                    name="group1"                                    
                                    label={doc.tramite}
                                    id={`radio-${doc.id}`}
                                    onChange={()=>{
                                        setTipoApoDoc(doc.id)
                                        setPrecioApoDoc(doc.precio)
                                    }}                            
                                />                                
                                </div>})}
                            </div>
                        </div>
                        
                        {tipoApoDoc===0?<>
                        <div style={{display:"flex",justifyContent:"space-around"}}>
                            <div style={{display:"flex",justifyContent:"flex-start",marginBottom:"0.3rem"}}>
                                <label htmlFor="file-upload1" className="btn red-button"><MdCloudUpload/> Adjuntar archivo</label>
                                <input 
                                    key="f1" 
                                    type="file" 
                                    id="file-upload1" 
                                    ref={inputRef}
                                    accept='.pdf, .jpg, .png'
                                    style={{display:"none"}} 
                                    onChange={e=>handleFileChange(e.target.files[0],"file")}
                                />
                            </div>                                                  
                        </div>
                        {file?
                        <div className='whiteCard StolzlMedium'>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div>{file.name.length>25?file.name.slice(0,25)+"...":file.name}</div>
                                <div>                        
                                    <MdOutlinePreview style={{color:"green",fontSize:"1.5rem",cursor:"pointer"}} onClick={()=>setViewFile(file)}/>
                                    <MdDelete style={{color:"darkred",fontSize:"1.5rem",cursor:"pointer"}} onClick={()=>handleFileDelete("file")}/>
                                </div>
                            </div>
                        </div>:""}
                        <div style={{color:"red"}}>{fileError1.message}</div>
                        
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <div style={{fontSize:"0.8rem",width:"80%", textAlign:"justify"}}>
                                <p>Puedes enviar el documento que deseas apostillar en cualquiera de los siguientes formatos: *.pdf, *.jpg, *.png. <strong>Maxino 10MB.</strong></p>
                                
                            </div>
                        </div>
                        
                        <div style={{margin:"1rem 0"}}>
                            <FloatingLabel label="Información adicional de su Documento">
                            <Form.Control 
                                as="textarea"
                                rows={5}
                                maxLength="600"
                                value={observaciones}
                                onChange={e=>setObservaciones(e.target.value)}
                                placeholder="Información adicional de su Documento"
                                className='customTextArea'    
                            />
                            </FloatingLabel>
                            <div style={{fontSize:"0.7rem",color:"gray", textAlign:"end"}}>{`${observaciones.length}/600`}</div>
                        </div>
                        </>:null}

                        <div className="form-floating" >
                                    <select 
                                        className="form-select form-select-md mb-3" 
                                        id="remision" 
                                        placeholder="¿Dónde remitiremos el documento?" 
                                        name="remision" 
                                        onChange={(e)=>{
                                            setRemision(e.target.value)                                        
                                        }} 
                                        value={remision}                                               
                                    >
                                        <option value="T">Tramitec</option>
                                        <option value="O">Otra Dirección</option>                                    
                                    </select>
                                    <label htmlFor="floatingInput">¿Dónde remitiremos el documento?</label>
                        </div>
                        
                        {renderDireccion()}                    
                                           
                        <div style={{display:"flex",justifyContent:"center"}} >
                            <div id="pdfContainer" className='insPdfContainer customBoxShadow'
                                style={viewfile?
                                    {display:"flex"}:{display:"none"}
                                }>
                                    <div className='insPdfClose' onClick={()=>setViewFile(null)}>X</div>
                                    {renderpdf()}
                            </div>
                        </div>               
                </div>
                <div className='whiteCard StolzlMedium'>
                    Detalle de Precios
                    <div className='redCard'>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Precio Base:</div><div className="StolzlMedium">{tipoApoDoc!==0?`${precioApoDoc} ${moneda}`:"Por definir"}</div></div>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Costo de Envio:</div><div className="StolzlMedium">{`${precioCourrier} ${moneda}`}</div></div>
                        <hr/>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium"><div>Costo Total:</div><div>{tipoApoDoc!==0?`${moneyFormat(Number(precioApoDoc)+Number(precioCourrier))} ${moneda}`:"Por definir"}</div></div>
                    </div>

                </div>
                <div style={{display:"flex",justifyContent:"center", marginBottom:"0.5rem"}}>
                    <button disabled={!aviableSend()} className='btn green-button' onClick={()=>sendFile()}>Enviar Solicitud</button>
                </div>                              
            </div>
        </div>
  )
}
