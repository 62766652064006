import React from 'react'
import {useHistory} from 'react-router-dom'

import standarMiniature from '../../Assets/Logos/tramitecIconLogo.jpg'

//Utils
import {getStandarDateFormat} from '../../Utils/getDates'

export default function NavMiniBlogEntry({blog,onSearch=false}) {
    const history = useHistory()

    const keygen = () => {
        const min = 1;
        const max = 100;
        const random = Math.floor(Math.random() * (max - min + 1)) + min;
        return random;
    };   
    if(blog.estado=="V"||onSearch){
    return (
        <div 
            key={`${keygen()}blog${blog.id}`} 
            className='d-flex miniBlogEntry kenburnsLow' 
            onClick={()=>{
                history.push(`/main/blog/entry=${blog.id}`)
            }}
        >
            <div className='miniBlogSection mbsLeft'>
                <div style={{textAlign:"center"}}><img alt="Miniatura" src={blog.fminiatura===""?standarMiniature:blog.fminiatura} className='miniBlogMiniatura'/></div>
                <div className='d-flex justify-content-center'>
                    <div className='BlogCategoria'>{blog.categoria}</div>                
                </div>
            </div>
            <div className='miniBlogSection mbsRight' >
                <h5 className='miniBlogTitle'>{blog.titulo}</h5>
                <p className='miniBlogResume'>{blog.resumen}</p>
                <div className='miniBlogFooter'>
                    <div>Autor: {blog.autor}</div>
                    <div>Publicado: {getStandarDateFormat(blog.fecha)}</div>
                </div>
            </div>
        </div>    
    )}
    else{
        return (
            <div 
                style={{
                    backgroundImage:`url(${blog.fcabecera})`,
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    width:"100%",
                    borderRadius:"1rem"
                }} 
                className='fade-in'
                onClick={()=>{
                    history.push(`/main/blog/entry=${blog.id}`)
                }}
            >
                <div style={{backgroundColor:"rgba(0,0,0,.4)",height:"15rem",padding:"1rem",borderRadius:"1rem", display:"grid",alignContent:"space-between"}}>
                    <div className='StolzlMedium' style={{color:"white",fontSize:"1.25rem"}}>
                        <span>{blog.titulo}</span>
                        <span className='BlogCategoria'>{blog.categoria}</span>
                    </div>
                    <div style={{color:"white",overflow:"auto"}}>
                        <p>{blog.resumen}</p>
                    </div>
                    <div className='miniBlogFooter' style={{color:"white"}}>
                        <div>Autor: {blog.autor}</div>
                        <div>Publicado: {getStandarDateFormat(blog.fecha)}</div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
