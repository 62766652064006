import React,{useState,useEffect,useRef} from 'react'
import {useHistory} from 'react-router-dom'
import InsPdf from './InsPdf' 
import Axios from 'axios'

import {FloatingLabel,Form,ProgressBar} from 'react-bootstrap'

//Icons
import {MdCloudUpload,MdDelete,MdOutlinePreview} from 'react-icons/md'

//Utils
import loading from '../../../Utils/loading'
import {getUrlApi} from '../../../Utils/getUrl&Key'
import {checkmail} from '../../../Utils/checkmail'
import LoginRequired from '../../../Utils/loginRequired'

const maxFilesSize = 10485760
export default function ServInscribir() {

    const history = useHistory()   
    const inputRef = useRef(null)
    const [files, setFiles] = useState([]);
    const [reload, setReload] = useState(0);
    const [viewfile,setViewFile] = useState(null)
    const [email, setEmail] = useState("");
    const [fileSize, setFileSize] = useState(0);

    
    const session = JSON.parse(localStorage.getItem("App.login"))
    const ubicacion = sessionStorage.getItem("App.userLocation")
    let userId
    if(session){
        userId = session.id
    }
    
    useEffect(()=>{
        let timer
        if(ubicacion==="PER"){
            timer = setTimeout(()=>{
                alert("Este servicio no esta disponible en Perú, se le redirecionará a la página de inicio")
                history.push("/")
            },300)
            
        }
        window.scrollTo(0,0)
        return ()=>{
            clearTimeout(timer)
        }
    },[])
   

    function handleFileChange(file){
            
            if(file.size>maxFilesSize||fileSize+file.size>maxFilesSize){
                alert("Ha superado el limite maximo de peso de Archivo(s)")
            }else{
                if(file.type==="application/pdf"||file.type.split('/')[0]==="image"){
                    let newfiles = files                
                    if(files.length===0||!files.filter(item=>item.name===file.name).length>0){                    
                        newfiles.unshift(file)
                        setFiles(newfiles)
                    }
                    else{
                        alert("El archivo ya esta cargado.")
                    }
                }else{
                    alert("Se requiere un archivo imagen o archivo pdf.")                                
                }
                
            }
            if(inputRef.current){
                inputRef.current.value = ''
            }
            
            setFileSize(files.reduce((total,file)=>total+file.size,0))
            setReload(reload+1)        
    }

    function handleFileDelete(file){
        const newFiles = files.filter(item=>item.name!==file.name)
        
        if(newFiles.length>0){
            setFileSize(newFiles.reduce((total,file)=>total+file.size,0))
        } 
        else{
            setFileSize(0)
        }
        setFiles(newFiles)
        setReload(reload+1)
    }

    function aviableSend(){        
        if(session&&files.length>0&&(email===""||checkmail(email))){            
            return true
        }else{
            return false
        }
    }

    async function sendFile(){
        const header = {
            "Content-Type":"application/json",
            "authorization": JSON.parse(localStorage.getItem("App.login")).token,
            "ubicacion": sessionStorage.getItem("App.userLocation")
        }
        loading()              
        const Url = getUrlApi() + "/inscripcion"

        const promisesB64 = files.map(async item=>{
            const extension = item.type.split('/')[1]
            const name = item.name.split('.')[0]
            const base64 = await convertBase64(item)
            
            return {file:base64,name,ext:extension}
        })       

        const adjuntos = await Promise.all(promisesB64)
        
        const data =    {
                userid:userId,
                adjuntos:adjuntos,
                email                
            }

        
        try{
            await Axios.post(Url,data,{headers:header}).then(res=>{                
                if(res.status===201){                    
                    const resData = res.data
                    loading(undefined,true)
                    history.push('/main/servicios/registrado',{email:[resData.email,email],tramiteId:resData.tramiteid,servicio:"P2"})
                }else{
                    alert("Ha ocurrido un problema, por favor intentelo más tarde.")
                    loading(undefined,true)
                }                
            })
        }catch(error){            
            const response = error.response
            if(response){
                if(response.status===403){
                loading(undefined,true)
                alert("Su sesión ha caducado")
                history.replace("/logout")
                }else{
                    alert(`Ha ocurrido un problema, por favor intentelo más tarde.\n${response.data.message}`)
                    loading(undefined,true)        
                }
            }else{
                alert(`Ha ocurrido un problema, por favor intentelo más tarde.\n${error}`)
                loading(undefined,true)
            }
        }
    
    }

    function convertBase64(file){
        return new Promise(async (resolve,reject)=>{            
            const ab = await file.arrayBuffer()
            const base64 = Buffer.from(ab).toString('base64')
            resolve(base64)
        })
    }
    

    function renderpdf(){        
        if(viewfile){
            if(viewfile.type==="application/pdf"){
                return (<InsPdf file={viewfile}></InsPdf>)
            }
            else{
                const urlfile = URL.createObjectURL(viewfile)
                return (<img style={{maxWidth:"98%",maxHeight:"98%",marginTop:"1%"}} alt={"La vista previa de su archivo no se pudo cargar"} src={urlfile}></img>)
            }
        }else{
            return (<div style={{                    
                    width:"max-content",
                    height:"min-content",
                    backgroundColor:"white",
                    padding:"0.4rem",
                    border: "0.2rem dashed lightgray",
                    borderTop:"none",
                    }}>
                        No hay vista previa disponible.
                    </div>)
        }
    }

    return (
        <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
            <div style={{width:"30rem",maxWidth:"95%"}}>
                <div>
                    <div className='serviceTittle'>Inscripción de Poder</div>
                    <hr/>
                </div>
                <LoginRequired/>
                <div className='whiteCard'>                        
                        <div style={{textAlign:"justify",margin:"0.5rem"}}>
                            Por favor, adjunta tu poder otorgado ante el Cónsul peruano en tu país de residencia y otros archivos necesarios para su inscripción.
                        </div>
                        <div style={{display:"none"}}>{reload}</div>

                        
                        <div style={{display:"flex",justifyContent:"center"}}>                    
                        </div>
                        {/*-----------------------------------Botones de Carga----------------------------------*/}
                        <div style={{display:"flex",justifyContent:"space-around"}}>
                            <div style={{display:"flex",justifyContent:"flex-start",marginBottom:"0.3rem"}}>
                                <label htmlFor="file-upload1" className={`btn ${files.length>=10?"disabled-button":"red-button"}`}><MdCloudUpload style={{marginRight:".3rem"}}/>Adjuntar archivo</label>
                                <input 
                                    ref={inputRef}
                                    disabled={files.length>=10}  
                                    type="file" 
                                    accept='.pdf, .jpg, .png' 
                                    id="file-upload1" 
                                    style={{display:"none"}} 
                                    onChange={e=>{
                                        handleFileChange(e.target.files[0])                                
                                    }}
                                />                                            
                            </div>                                                
                        </div>
                        {fileSize>0?<ProgressBar variant={(fileSize*100/maxFilesSize)>90?"danger":(fileSize*100/maxFilesSize)>60?"warning":""} now={fileSize*100/maxFilesSize} label={`${(fileSize/(1024*1024)).toFixed(2)}MB`}/>:null}
                        {files.length>0?files.map(file=>{                        
                            return(
                                <div key={`${file.name}`} className='whiteCard StolzlMedium'>
                                    <div style={{display:"flex",justifyContent:"space-between"}}>
                                        <div><span>{file.name.length>25?file.name.slice(0,25)+"...":file.name}</span></div>
                                        <div>                        
                                            <MdOutlinePreview style={{color:"green",fontSize:"1.5rem",cursor:"pointer"}} onClick={()=>setViewFile(file)}/>
                                            <MdDelete style={{color:"darkred",fontSize:"1.5rem",cursor:"pointer"}} onClick={()=>handleFileDelete(file)}/>
                                        </div>
                                    </div>
                                </div>
                            )}
                        ):null}                    

                        {/*--------------------------------Fin de Botones de Carga--------------------------------*/}                        
                        
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <div style={{fontSize:"0.8rem",width:"80%", textAlign:"justify"}}>
                                Puedes enviarnos tus archivos en alguno de los siguientes formatos: *.pdf, *.jpg, *.png. <strong>Maximo 10MB.</strong>
                            </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"center",marginBottom:"1rem"}} >
                            <div id="pdfContainer" className='insPdfContainer customBoxShadow'
                                style={viewfile?
                                    {display:"flex"}:{display:"none"}
                                }>
                                    <div className='insPdfClose' onClick={()=>setViewFile(null)}>X</div>
                                    {renderpdf()}
                            </div>
                        </div>                    
                </div>
                
                <div className='whiteCard'>
                    <span>Puede registrar una dirección de correo adicional para recibir su inscripción de poder.</span>
                    <FloatingLabel label="Correo" className='mt-2'>
                        <Form.Control type='email' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Correo'/>
                    </FloatingLabel>
                </div>
                <div style={{display:"flex",justifyContent:"center", marginBottom:"0.5rem"}}>
                    <button disabled={!aviableSend()} className='btn green-button' onClick={()=>sendFile()}>Enviar Solicitud</button>
                </div>
            </div>            
        </div>
    )
}
