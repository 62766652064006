import React,{useState,useRef,useEffect} from 'react'

export default function AddMiPersona({formType="pod",listaPersonas=[],updateList=()=>{}}) {    
    const ubicacion = sessionStorage.getItem("App.userLocation")    
    
    const localpod = JSON.parse(localStorage.getItem(`App.poderdante.${ubicacion}`))
    const localapo = JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`))
    
    const [showDrop,setShowDrop] = useState(false)

    const customDrpRef = useRef(null)
    useOutsideAlerter(customDrpRef,handleClose)

    function changeShowDrop(){        
        setShowDrop(!showDrop)
    }

    function handleClose(){
        setShowDrop(false)
    }

    function handleSelect(persona){
        let errorCount = 0

        if(localpod){ 
            const existe = localpod.filter(pod=> pod.documento+pod.docnumero === persona.documento+persona.docnumero)
            if(existe.length>0){            
            alert("La Persona ya esta registrada como Poderdante")
            errorCount+=1
            }        
        }

        if(localapo){
            const existe = localapo.filter(apo=> apo.documento+apo.docnumero === persona.documento+persona.docnumero)
            
            if(existe.length>0){
            alert("La Persona ya esta registrado como Apoderado")
            errorCount+=1
            }
        }
        if(errorCount===0){
            if(formType==="pod"){
                let newpods = []
                if (localpod) {
                    newpods = localpod                    
                }                   
                newpods.push(persona)
                updateList(newpods)
                localStorage.setItem(`App.poderdante.${ubicacion}`,JSON.stringify(newpods))
                                      
                
            }
            else if (formType==="apo"){
                let newapos = []
                const newpersona = 
                    {
                        documento:persona.documento,
                        docnumero:persona.docnumero,
                        nombre:persona.nombre,
                        apellidop:persona.apellidop,
                        apellidom:persona.apellidom
                    }

                if (localapo){
                    newapos = localapo
                }
                newapos.push(newpersona)
                updateList(newapos)
                localStorage.setItem(`App.apoderado.${ubicacion}`,JSON.stringify(newapos))
            }
        }
       
        setShowDrop(false)
    }
    if(listaPersonas.length){
    return(
        <div ref={customDrpRef} style={{fontSize:"0.8rem"}}>            
            <div key="box" className='btn green-button' style={{display:"flex",fontSize:"0.9rem"}} onClick={()=>changeShowDrop()}>                
                <div style={{display:"grid",alignItems:"center"}}>Mis Personas</div>                                        
            </div>
            <div key="drop" style={showDrop?{position:"absolute",height:"max-content",maxHeight:"7.5rem",overflow:"auto",zIndex:"1",backgroundColor:"white",padding:"0.5rem",border:"1px solid #ced4da"}:{display:"none"}}>
                {/* formType==="pod"?personasParaPod.map(option=>                                    
                    <div key={option.nombre} className='customDropOptions mb-1' onClick={()=>handleSelect(option)}>                        
                        <div>{option.nombre + " " + option.apellidop}</div>                        
                    </div>
                ): */
                listaPersonas.map(option=>                                    
                    <div key={option.nombre} className='customDropOptions mb-1' onClick={()=>handleSelect(option)}>                        
                        <div>{option.nombre + " " + option.apellidop}</div>                        
                    </div>)
                }
            </div>
        </div>
    )}
    else{
        return(
            <></>)
    }

}


function useOutsideAlerter(ref,callback=()=>{}) {
    useEffect(() => {       
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {                                              
                callback() 
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}