import React,{useState,useEffect} from 'react'
import {Form,FloatingLabel,Col, Button} from 'react-bootstrap'
import SelectSearch from 'react-select-search'

export default function CertificadoReg({options,mayus,editing,solicitudes,guardarSolicitud}) {
    const [oficinaReg, setOficinaReg] = useState("");
    const [partida, setPartida] = useState("");

    useEffect(()=>{
        if(editing.onEdit){
          const solicitud = editing.item          
          setOficinaReg(solicitud.oficinaReg)
          setPartida(solicitud.partida)
          
        }
      },[editing])
      
  
        function cleanForm(){          
          setOficinaReg("")
          setPartida("")
          
        }
  
        async function handleGuardar(){
            if(editing.onEdit){
              const updateIndex = solicitudes.findIndex(sol=>sol===editing.item)
              const newData = solicitudes[updateIndex]
              let newArray = [...solicitudes]

              newData.oficinaReg = oficinaReg
              newData.partida = partida
              newArray[updateIndex] = newData
              
              guardarSolicitud(newArray)            
              cleanForm()
            }
            else{
              const solicitud = {
                  tipo:"CRI",                  
                  oficinaReg,
                  partida                  
              }
              guardarSolicitud((PrevLog)=>[...PrevLog,solicitud])            
              cleanForm()
            }               
        }


  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12}>
        <SelectSearch  
            options={options}
            placeholder='Oficina Registral' 
            value={oficinaReg}
            onChange={(value)=>{
                setOficinaReg(value)                        
            }} 
        />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
            <FloatingLabel label="Partida" className='p-0'>
                <Form.Control maxLength={9} value={partida} onInput={mayus} onChange={e=>{setPartida(e.target.value)}} placeholder='Partida'/>
            </FloatingLabel>
        </Col>
        <Col className='d-flex justify-content-center'>            
            <Button 
                variant='light' 
                className='blue-button StolzlMedium' 
                disabled={!(oficinaReg!==""&&partida!=="")}
                onClick={()=>handleGuardar()}>{`${editing.onEdit?"Actualizar":"Añadir"} ${solicitudes.length>0&&!editing.onEdit?"otra":""} partida`}</Button>            
        </Col>
    </>
  )
}
