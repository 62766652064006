import React, {useState,useEffect} from "react";
import {Container, Nav, Navbar, NavDropdown, NavItem} from "react-bootstrap";
import {useHistory,Link,useLocation} from 'react-router-dom';


//Icons
import {GiHamburgerMenu} from 'react-icons/gi'
import {CgClose} from 'react-icons/cg'
import {FaUserCircle} from 'react-icons/fa'
import { AiOutlineGlobal } from "react-icons/ai";
import {MdSearch} from 'react-icons/md'

//Assets
import Logo from "../Assets/Logos/WhiteLogo.svg"

//Components
import NavBuscar from './navBuscar'
import NavLoginTimer from './navLoginTimer'

//Utils
import customAlert from './../Utils/alert'

const SESSION_KEY = "App.login"  
function NaviBar ({userLocation,alterLocation}) {
  const history = useHistory()
  const location = useLocation()
  
  const [user,setUser] = useState({id:-1,username:null,name:null,token:"",AccType:"none"})   
  const [showEx,setShowEx] = useState(false)
  const [showSearch, setShowSearch] = useState(false);
  
  const logindata = JSON.parse(localStorage.getItem(SESSION_KEY))
  const adminlogin = [
    {option:'FAQ',link:'preguntasfrecuentes'},
    {option:'Contáctenos',link:'Contactenos'},
    {option:"Blog",link:"blog"},
    {option:'Admin',link:"admin"}    
  ]
  const clientlogin = [    
    {option:'FAQ',link:'preguntasfrecuentes'},
    {option:'Contáctenos',link:'Contactenos'},    
    /* {option:"Blog",link:"blog"} */
  ]
  
  const redireCode = Buffer.from(location.pathname).toString('base64')
  
  useEffect(()=>{            
    if(location.state){
      if(location.state.scrollgo){        
        window.scrollTo(0,location.state.scrollgo)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(localStorage.getItem(SESSION_KEY)){      
      setUser(logindata)                  
    }
    return ()=>{
      setUser({id:-1,username:null,name:null,token:"",AccType:"none"})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps              
  },[localStorage.getItem(SESSION_KEY)])

  function singOut (){
    history.replace({pathname:"/logout"})    
  }
  
  function NavBarOption(navOption) {
    return <Nav.Item key={navOption.link}>
              <Link
                to={{
                  pathname: `/main/${navOption.link.replace(/ /g,'').toLowerCase()}`                  
                }}                                              
                className="nav-link GraphikSB"
                >{navOption.option}</Link>
            </Nav.Item>
  }  

  function NavBarEXOption(navOption){
    return <div key={navOption.link} className="navbarExButton">
              <Link
                to={{
                  pathname: `/main/${navOption.link.replace(/ /g,'').toLowerCase()}`
                }}                                              
                className="nav-link GraphikSB"
                style={{padding:"0.5rem 0.25rem"}}
              >{navOption.option}</Link>
    </div>
  }



  function handleMenu(){
    setShowEx(!showEx)    
  }

  function handleServRedirect(){    
    if(location.pathname==="/"){
      window.scrollTo(0,window.innerHeight)
    }else{
      history.push({pathname:"/",state:{scrollgo:window.innerHeight}})
    }

  }

  function resetLocation(){
    alterLocation(null)
    sessionStorage.removeItem("App.userLocation")
    window.location.reload()
  }

    
  return (<>
  <Navbar sticky="top" bg="dark" >    
    <NavLoginTimer user={user}/>
    <Container fluid={true}> 
      <Nav className="burgerButton" onClick={()=>handleMenu()}>
          {!showEx?
          <GiHamburgerMenu style={{color:"white"}}/>:
          <CgClose style={{color:"white",fontWeight:"bold"}} ></CgClose>
          }
      </Nav>
      <Navbar.Brand style={{cursor:"pointer"}}>        
          <img
            style = {{height:"2rem"}}
            alt = 'Home'
            src= {Logo}
            onClick={()=>history.push("/")}          
          />        
      </Navbar.Brand>
          
      <Nav className="nav-linksContainer">
        <Nav.Item className="nav-link GraphikSB" style={{cursor:"pointer"}} onClick={()=>handleServRedirect()}>          
            Servicios
        </Nav.Item>
        {user.rol? adminlogin.map(nav => NavBarOption(nav)):clientlogin.map(nav => NavBarOption(nav))}        
      </Nav>      
      
      <Navbar.Collapse className="justify-content-end">
          
          <MdSearch style={{color:"white",fontSize:"1.5rem", cursor:"pointer"}} onClick={()=>{
              setShowSearch(true)
            }}/>
          {userLocation?
            <div>              
              <div className="Mytooltip">
                <AiOutlineGlobal style={{color:"white",fontSize:"1.5rem"}} 
                  onClick={()=>resetLocation()}/>
                <span className="Mytooltiptext">Cambiar localizacion</span></div>              
            </div>
          :null}
          <Nav>                        
            { 
              user.username!=null ? (
                <NavDropdown title={<>
                  <div className="userDisplayName" style={{display:"contents"}}>{user.name}</div>
                  <div className="userDisplayIcon" style={{display:"contents"}}>
                    <FaUserCircle style={{fontSize:"1.65rem"}}/>                    
                  </div></>
                  } align='end'>
                  <NavDropdown.Item onClick={()=>{
                    history.push("/main/usuario")
                  }}
                  >Mi Usuario</NavDropdown.Item>
                  {user.AccType==="tramitec"?<><NavDropdown.Divider/>
                  <NavDropdown.Item onClick={()=>{
                    history.push("/main/updatePassword")
                  }}>
                      Cambiar Clave
                  </NavDropdown.Item></>:null}
                  <NavDropdown.Divider/>                  
                  <NavDropdown.Item onClick={()=>
                    customAlert(singOut,"Cerrando Sesion","¿Esta seguro que desea cerrar sesion?")}
                  >Cerrar Sesión</NavDropdown.Item>

                </NavDropdown>
                ):(
                <NavItem>
                 <Link to= {redireCode==="Lw=="?"/login":`/login/r==${redireCode}`}  className="nav-link">Iniciar Sesión</Link></NavItem>)
                 
            }
          </Nav>
      </Navbar.Collapse>          
    </Container>        
  </Navbar>

  {showSearch?
    <NavBuscar setShowSearch={setShowSearch}/>
  :null}

  <div className={showEx? "navbarExtension slide-in-top":"navbarExtension  slide-out-top"}>
    <div style={{display:"flex", justifyContent:"space-between",width:"95%",flexWrap:"wrap"}}>
      <div key="servicio" className="navbarExButton">
            <div                  
              onClick={()=>handleServRedirect()}                                             
              className="nav-link GraphikSB"
              style={{padding:"0.5rem 0.25rem"}}
            >Servicios</div>
      </div>
      {user.rol? adminlogin.map(link => NavBarEXOption(link)):clientlogin.map(link => NavBarEXOption(link))}
    </div>
  </div>
  </>
)}

export default NaviBar
