import React, {useState, useEffect} from "react";
import Axios from 'axios'
import {useHistory} from 'react-router-dom';

//Utils
import CustomAlert from '../../../../Utils/alert'
import {getUrlApi} from "../../../../Utils/getUrl&Key";
import {moneyFormat} from '../../../../Utils/numberFormat';




export default function FormFac ({navigation }) {
  const history = useHistory()
  const contacText = "No encontré una facultad que se adecua a lo que solicito,\nnecesito un poder para:\n"  
  
  const [facultades, setFacultades] = useState([{id:0,facultad:"Cargando Facultades...",estado:"A"}])
  const [tag, setTag] = useState(0)
  const [precioFacs,setPrecioFacs] = useState(0)
  const [precioBase,setPrecioBase] = useState(0)
  const [precioPerFac,setPrecioPerFac] = useState(0)
  const [moneda, setMoneda] = useState("");

  const ubicacion = sessionStorage.getItem("App.userLocation")
  
  let complete = true
  useEffect(() => {    
    getCostosPoder()
    loadStoredFacs()    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  useEffect(()=>{
    if(tag<5){
      setPrecioFacs(precioPerFac*tag)
    }
    else{
      setPrecioFacs(precioPerFac*5)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tag,precioPerFac])

  async function loadStoredFacs(){
    const storedFacultades = JSON.parse(localStorage.getItem(`App.facultad.${ubicacion}`));
    const Url = getUrlApi() + "/common/facultades"   
    await Axios.get(Url)
    .then(res => {      
      if (storedFacultades) {        
        let UpdateFacs = res.data
        setTag(storedFacultades.length)
       
        storedFacultades.map(storedFac=>{
          const facIndex = UpdateFacs.findIndex((item => item.id === storedFac.id))        
          UpdateFacs[facIndex].estado="S"
          return null      
        })
        setFacultades(UpdateFacs)  
      }else{
        setFacultades(res.data)
      }    
    })  
  }

  async function getCostosPoder(){
    const poderdantes = JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`)).length
    const location = sessionStorage.getItem("App.userLocation")
    const headers = {"ubicacion":location}
    const Url = getUrlApi()+ `/common/inscripcioncosto/${poderdantes}`

    await Axios.get(Url,{headers:headers}).then(res=>{
      const data = res.data      
      setPrecioBase(data.costobase)
      setMoneda(data.moneda)
      setPrecioPerFac(data.costoxfac)
    })    
  }
  
  function enviarDataPdf(){
    const poderdantes = JSON.parse(localStorage.getItem(`App.poderdante.${ubicacion}`))
    const apoderados = JSON.parse(localStorage.getItem(`App.apoderado.${ubicacion}`))
    complete = facultades.filter(fac=>fac.estado==="S").length>0
    
    if(complete){
      if(poderdantes&&apoderados){      
          navigation.next()      
      }else{
        if(!poderdantes){
          alert("No hay poderdantes registrados")
        }
        if(!apoderados){
          alert("No hay apoderados registrados")
        }
      }
    }
    else{
      alert("Seleccione almenos una Facultad")
    }   
  }

  

  function handletoogle (index) {
    let UpdateFacs = facultades          
    UpdateFacs.map(item => {
      if(item.id === index){
        if(item.estado==="A"){
          Object.assign(item,{estado:"S"})          
          setTag(tag+1)
          
        }
        else if (item.estado ==="S"){
          Object.assign(item,{estado:"A"})
          setTag(tag-1)
          
        }
      }
      return null
    })
    
    setFacultades(UpdateFacs)
    const newfacultades = UpdateFacs.filter(fc => fc.estado === "S")
    if(newfacultades.length>0){
    localStorage.setItem(`App.facultad.${ubicacion}`, JSON.stringify(newfacultades))
    }else{
      localStorage.removeItem(`App.facultad.${ubicacion}`)
    }          
  }

  return (
    <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>
        <div style={{width:"30rem",maxWidth:"95%"}}>
          <div className="whiteCard StolzlMedium">
            <div><h5>¿Qué facultades desea otorgar?</h5></div>           

            <div className="opodfacListContainer">
              <div className="opodfacListContent customBoxShadow">            
                  <div className="mb-2">Listado de Precios</div>              
                  <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Precio Base:</div><div className="StolzlMedium">{`${moneyFormat(precioBase)} ${moneda}`}</div></div>
                  <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Facultades:</div><div className="StolzlMedium">{`${moneyFormat(precioFacs)} ${moneda}`}</div></div>
                  <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>{`Inscripción:`}</div><div className="StolzlMedium">{"-"}</div></div>
                  <hr/>
                  <div className="StolzlMedium" style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Sub Total:</div><div>{`${moneyFormat(precioFacs+precioBase)} ${moneda}`}</div></div>
              </div>
              <div style={{fontSize:"0.7rem",marginTop:"0.5rem"}}>{`Cada facultad seleccionada tiene un costo unitario de ${precioPerFac} ${moneda}.`}</div>
              <div style={{fontSize:"0.7rem"}}>A partir de la 6ta facultad no se agregará costos adicionales. </div>
            </div>

          

          <div className="customScroll opodfacFaculOptionsContainer">
            {facultades.map((Item)=>{
                if(Item.estado==="A"||Item.estado==="S"){
                  return (
                    <div key={"Obtion"+Item.id} style = {{margin:"0.3rem 0.2rem 0.3rem 0.2rem",display:"flex"}}>
                      <input 
                        type="checkbox"
                        className="btn-check"
                        id={Item.id}
                        onChange ={()=>handletoogle(Item.id)}                       
                        checked={Item.estado==="S"}                                              
                        />
                      <label className={"btn w-100 btn-secondary p-1"} htmlFor= {Item.id}>                      
                        {Item.facultad}                                           
                      </label>
                      <div style={{display:"grid",alignItems:"center"}}>
                        <div
                          className="rounded-circle Amaranth-Italic iconInfo-black"
                          onClick={()=>CustomAlert(undefined,Item.facultad,Item.informacion)}
                        >i</div>
                      </div>
                    </div>
                    )
                }
            })}
          </div>        
        </div>
        <div key="costos" className="whiteCard StolzlMedium poderListaPrecio">
                <div>Detalle de Precios</div>
                <div className='redCard'>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Precio Base:</div><div className="StolzlMedium">{`${moneyFormat(precioBase)} ${moneda}`}</div></div>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Facultades:</div><div className="StolzlMedium">{`${moneyFormat(precioFacs)} ${moneda}`}</div></div>                    
                    <hr/>
                    <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}} className="StolzlMedium"><div>Sub Total:</div><div>{`${moneyFormat(precioFacs+precioBase)} ${moneda}`}</div></div>
                </div>
                <div style={{fontSize:".7rem"}}>{`Cada facultad seleccionada tiene un costo unitario de ${moneyFormat(precioPerFac)} ${moneda}. A partir de la 6ta facultad no se agregará costos adicionales.`}</div>
                
            </div>          
        <div style={{display:"flex", justifyContent:"space-around", marginTop: "1rem" }}>          
          <button              
              className="btn red-button"
              onClick={() => navigation.previous()}
          >
            REGRESAR
          </button>
          <button              
              className="btn green-button"
              onClick={() => enviarDataPdf()}
          >
            SIGUIENTE
          </button>
        </div>

        <div style={{textAlign:"center", marginTop:".6rem"}}>
            <div>
                ¿No encuentra una facultad que se ajusta a sus necesidades?
            </div>
            <div>
                Haga clic <span className="falseLink" onClick={()=>history.push("/main/contactenos",contacText)}>Aquí</span>
            </div>
        </div>
      </div>           
    </div>
  );
};



