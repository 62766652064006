import React,{useState} from 'react'
import {Button,Form,FloatingLabel} from 'react-bootstrap'
import Axios from 'axios'

//Utils
import encript from '../Utils/encript'
import {getUrlApi} from '../Utils/getUrl&Key'

export default function UpdatePsw() {
    const [actualpsw,setActualspw] = useState("")
    const [newpsw,setNewpsw] = useState("")
    const [confirmpsw,setConfirmpsw] = useState("")
    const [error,setError] = useState("")
    

 
 
    async function handleConfirm(){
        if(actualpsw===""||newpsw===""||confirmpsw===""){
            setError("Completar todos los datos")
        }else{
            if(newpsw===confirmpsw){
                if(newpsw.length>7){
                const userdata  = JSON.parse(localStorage.getItem("App.login"))
                const URL = getUrlApi() + "/users/cambiarContrasena"
                const headers = {"Content-Type": "application/json","token":userdata.token }
                const data = {username:userdata.username,password:encript(actualpsw),newpassword:encript(newpsw)}
                
                await Axios.post(URL,data,{headers:headers}).then(res=>{
                    if(res.status===201){
                        alert(res.data.message)
                    }else{
                        alert("Ha ocurrido un error. Porfavor intentar nuevamente en unos minutos.")
                    }
                })
                }else{
                    setError("La contraseña debe tener almenos 8 caracteres")
                }                
            }else{
                setError("Las contraseñas no coinciden")
            }
        }

    }
    const handleSubmit = e => {
        e.preventDefault();       
    }
    
    
    return (
        <div className='d-flex justify-content-center'>
            <div className='whiteCard' style={{width:"25rem",maxWidth:"95%"}}>                
                <Form onSubmit={e=>handleSubmit(e)} style={{width:"25rem",maxWidth:"98%"}}>
                    <FloatingLabel label="Contraseña Actual" className='mb-4' value={actualpsw} onChange={e=> setActualspw(e.target.value)}>
                        <Form.Control type='password' placeholder='Contraseña Actual' />
                    </FloatingLabel>
                    <FloatingLabel label="Nueva Contraseña" className='mb-4' value={newpsw} onChange={e=> setNewpsw(e.target.value)}>
                        <Form.Control type='password' placeholder='Nueva Contraseña'/>
                    </FloatingLabel>
                    <FloatingLabel label="Confirmar Contraseña" className='mb-4' value={confirmpsw} onChange={e=> setConfirmpsw(e.target.value)}>
                        <Form.Control type='password' placeholder='Confirmar Contraseña' />
                    </FloatingLabel>
                    <div>{error}</div>
                    <div className="d-flex justify-content-end">
                    <Button variant="outline-success" onClick={()=>handleConfirm()}>Aceptar</Button>
                    </div>
                </Form>
            </div>
        </div>
    )    
}
