import React,{useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Form,FloatingLabel} from 'react-bootstrap'
import Axios from 'axios'
import SelectSearch, { fuzzySearch } from 'react-select-search';

//Utils
import CustomAlert from '../../../Utils/alert'
import loading from '../../../Utils/loading'
import {getUrlApi} from '../../../Utils/getUrl&Key'
import InsSteps from '../../../Utils/InsSteps'
import LoginRequired from '../../../Utils/loginRequired'

//Assets
import getStateName from '../../../Utils/getStateName';

export default function ServTramDoc() {
    const history = useHistory()

    let colored = {border:"0.1rem solid red"}
    let regular = {color: "black"}    

    const [documento, setdocumento] = useState("")
    const [numerodoc, setNumerodoc] = useState("")
    const [nombre, setNombre] = useState("")
    const [apellidop, setApellidop] = useState("")
    const [apellidom, setApellidom] = useState("")    
    const [tipoTramDoc, setTipoTramDoc] = useState("")
    const [tramEsp, setTramEsp] = useState("")    
    const [errormsg, setErrormsg] = useState("")
    const [textreq, setTextreq] = useState("")
    const [partida, setPartida] = useState("")
    const [apostillado, setApostillado] = useState("N")
    const [oficinaReg, setOficinaReg] = useState("")
    
    const [states, setStates] = useState([])
    const [remision, setRemision] = useState("T")
    const [rpais, setRpais] = useState("PER")
    const [restado, setRestado] = useState("")
    const [rciudad, setRciudad] = useState("")
    const [rcodPostal, setRcodPostal] = useState("")
    const [rdireccion, setRdireccion] = useState("")
    const [receptor,setReceptor]=useState("")

    const [formComplete, setFormComplete] = useState(true)
    const [options, setOptions] = useState([])
    const [precioCourrier, setPrecioCourrier] = useState(0)
    const [monedaCourrier, setMonedaCourrier] = useState("");
    const loged = JSON.parse(localStorage.getItem("App.login"))

    const steplist = [
        {step:"Registra tu solicitud.",xtraInfo:""},
        {step:"TRAMITEC aprueba tu solicitud.",xtraInfo:""},
        {step:"Realiza el Pago del Servicio.",xtraInfo:""},        
        {step:"Tramitaremos su Documento.",xtraInfo:""},
        {step:"Recoge o recibe tu documento apostillado.",xtraInfo:"Si al momento de ingresar la solicitud nos indicaste que el documento sea enviado a tu domicilio, se te notificará de su salida y un número de rastreo. Si solicitaste recoger el documento de nuestras oficinas, te notificaremos al momento en que puedas pasar por él."}
    ]
    
    useEffect(()=>{                   
        const stored = JSON.parse(localStorage.getItem("App.tramitacion"))
        window.scrollTo(0,0)
        getOficinaReg()
        getStates() 
        getPrecioCurrier()
        if(stored){
            setdocumento(stored.tramitador.documento)
            setNumerodoc(stored.tramitador.docnumero)
            setNombre(stored.tramitador.nombre)
            setApellidop(stored.tramitador.apellidop)
            setApellidom(stored.tramitador.apellidom)            
            setTipoTramDoc(stored.tramitetipo)
            setTramEsp(stored.tramitedoc)                
            setTextreq(stored.textreq)
            setPartida(stored.partida)
            setApostillado(stored.apostillado)
            setOficinaReg(stored.oficinareg)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]) 

    useEffect(()=>{
        getStates() 
        getPrecioCurrier() 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[rpais])

    
    async function getPrecioCurrier(){
        const Url = getUrlApi() + `/apostilla/courrier/${rpais}`
        const ubicacion = sessionStorage.getItem("App.userLocation")
        const headers = {"ubicacion":ubicacion}
        try{
            await Axios.get(Url,{headers:headers}).then(res=>{
                if(res.status===200){
                    setPrecioCourrier(res.data.precio)
                    setMonedaCourrier(res.data.moneda)  
                }
            })
        }
        catch(error){

        }
    }
  
    async function getOficinaReg(){
      const Url = getUrlApi()+"/common/oficinareg"      
      let data
      let orderedStates = []
      try{               
        await Axios.get(Url)
        .then( res=>{
            if (res.status===200){ 
                data = res.data
                orderedStates = []
                data.map(item => {
                    orderedStates.push({name:item.oficina,value:item.id})
                    return null
                })
                setOptions(orderedStates)
            }
        })
      }
      catch(error){
        console.log(error.response.data.message)
      }    
    }

    async function getStates(){
        const Url = getUrlApi()+ `/common/estados/${rpais}`  
        let data
        let orderedStates = []
        try{            
          await Axios.get(Url)
          .then( res=>{
              if (res.status===200){ 
                  data = res.data
                  orderedStates = []
                  data.map(item => {
                    orderedStates.push({name:item.estado,value:item.id})
                    return null
                    })
                  setStates(orderedStates)
              }
          })
        }
        catch(error){
          console.log(error)
        }    
      }
  

    const handleSubmit = e => {
        e.preventDefault();       
    }

    function justnumber(event){      
        if (Number.isNaN(parseInt(event.key))){
            event.preventDefault()
        }            
    }

    async function registrarTramite(data){
        
        const header = {
            "Content-Type":"application/json",
            "authorization": JSON.parse(localStorage.getItem("App.login")).token,
            "ubicacion":sessionStorage.getItem("App.userLocation")
        }
        const Url = getUrlApi() + "/tramitacion"        
        localStorage.removeItem("App.tramitacion")
        try{        
            await Axios.post(Url,data,{headers:header}).then(res=>{                               
                if(res.status===201){
                    const resData = res.data
                    loading(undefined,true)
                    history.push('/main/servicios/registrado',{tramiteId:resData.tramiteid,servicio:"TRAM",email:[resData.email]})
                }else{
                    alert(`Ha ocurrido un problema, por favor intentelo más tarde.`)
                    loading(undefined,true)
                }
                
            })
        }catch(error){
            if(error.response){
                alert(`Ha ocurrido un problema, por favor intentelo más tarde.\n${error.response.message}`)
                loading(undefined,true)
            }
        }
    }

    function revisar(){
        setErrormsg("")
        let errorCounter = 0

        const inputData = [documento,numerodoc,nombre,apellidop,apellidom]
        
        if(tipoTramDoc==="RC"||tipoTramDoc==="SCTS"){
            if(!inputData.every(inputs=>inputs!=="")){
                errorCounter += 1
                setFormComplete(false)
                setErrormsg("Por favor completar todos los datos")
            }
        }

        if(tipoTramDoc==="O"){
            if(textreq===""){
                errorCounter += 1
                setFormComplete(false)
                setErrormsg("Por favor completar todos los datos")
            }
        }

        if(tipoTramDoc==="PRP"&&oficinaReg===""){
            errorCounter += 1
            setFormComplete(false)
            setErrormsg("Por favor completar todos los datos")
        }

        if(tramEsp===""&&(tipoTramDoc==="RC"||tipoTramDoc==="PRP")){
            errorCounter += 1
            setFormComplete(false)
            setErrormsg("Por favor completar todos los datos")
        }
        
        if(remision==="O"){
            if(restado===""||rciudad===""||rcodPostal===""||rdireccion===""||receptor===""){
                errorCounter += 1
                setFormComplete(false)
                setErrormsg("Por favor completar todos los datos")
            }
        }
             
        

        if(errorCounter===0){
            loading()            
            registrarTramite({
                userid:loged.id,
                tramitetipo:tipoTramDoc,
                tramitedoc:tramEsp,
                oficinareg:oficinaReg,
                partida,
                apostillado,
                datos:textreq,
                tramitador:[
                    {
                        documento,
                        docnumero:numerodoc,
                        nombre:nombre.toLocaleUpperCase(),
                        apellidop:apellidop.toLocaleUpperCase(),
                        apellidom:apellidom.toLocaleUpperCase()
                    }
                ],
                remision,
                receptor:receptor.toLocaleUpperCase(),
                ubicacion:{
                    pais:rpais,
                    estado:restado,
                    ciudad:rciudad.toLocaleUpperCase(),
                    codigopostal:rcodPostal,
                    direccion:rdireccion.toLocaleUpperCase()
                }

            })
        }
    }
    /* ---------------------Renderizar Combo-box de Documentos------------------------- */
    function renderSecondCombo(){
        if(tipoTramDoc==="RC"){
            return (
            <div className="form-floating" >
                <select 
                    className="form-select form-select-md mb-3" 
                    id="doctram" 
                    placeholder="Documento a tramitar" 
                    name="doctram" 
                    value={tramEsp}
                    onChange={(e)=>{setTramEsp(e.target.value)}}
                    style={formComplete ? regular:tramEsp===""? colored:regular}>
                    <option value="">Seleccionar</option>
                    <option value="PN">Partida de Nacimiento</option>
                    <option value="PD">Partida de Defunción</option>
                    <option value="PM">Partida de Matrimonio</option>                    
                </select>
                <label htmlFor="doctram">Documento a tramitar</label>
            </div>)       
        }
        else if(tipoTramDoc==="PRP"){
            return (<>
            <div className="form-floating" >
                <select 
                    className="form-select form-select-md mb-3" 
                    id="doctram" 
                    placeholder="Documento a tramitar" 
                    name="doctram" 
                    value={tramEsp}
                    onChange={(e)=>{
                        setTramEsp(e.target.value)                                                
                    }}
                    style={formComplete ? regular:tramEsp===""? colored:regular}>
                    <option value="">Seleccionar</option>
                    <option value="RPI">Registro de Propiedad Inmueble</option>
                    <option value="PN">Personas Naturales</option>
                    <option value="PJ">Personas Juridicas</option>
                    <option value="BM">Bienes Muebles</option>
                    {/* <option value="P">Predios</option> */}
                    <option value="CRI">Certificado Registral Inmobiliario</option>
                    <option value="VP">Vigencia de Poderes</option>
                </select>
                <label htmlFor="doctram">Documento a tramitar</label>
            </div>

            <div className="mb-3 GraphikR" style={{...formComplete?regular:oficinaReg===""? colored:regular,borderRadius:"0.25rem"}}>
                <SelectSearch  
                    options={options} 
                    search 
                    filterOptions={fuzzySearch} 
                    autoComplete="new-password"
                    placeholder="Seleccionar Oficina Registral" 
                    value={oficinaReg}
                    onChange={(value)=>{
                        setOficinaReg(value)                        
                    }} 
                />
            </div>

            <FloatingLabel label="Ficha o Partida Registral" className='mb-2'>
            <Form.Control                 
                value={partida}
                className="uppercase-input"
                onChange={e=>{setPartida(e.target.value)}}
                placeholder='Ficha o Partida Registral'                
                style={formComplete ? regular:partida===""? colored:regular}
            />                    
            </FloatingLabel>
            </>
            )
        }
    }
    /* ---------------------------------------------------------------------------------- */

    /* -------------------Renderizacion de Remision de Documentos------------------------ */
    function renderDireccion(){
        if(remision==="O"){
            return(
                <div>
                    <div>
                        <div style={{margin:"1rem 0"}}>
                            <FloatingLabel label="Nombre Completo">
                                <Form.Control
                                    className='uppercase-input'
                                    placeholder='Nombre Completo'
                                    value={receptor}
                                    onChange={e=>setReceptor(e.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="form-floating">
                                <select 
                                    className="form-select form-select-md mb-3" 
                                    disabled = {(sessionStorage.getItem("App.userLocation"))==="PER"}
                                    id="tipotramitec" 
                                    placeholder="País" 
                                    name="tramdoc" 
                                    value={rpais}
                                    onChange={(e)=>{
                                        setRpais(e.target.value)                                   
                                        setRestado("")                                                                                
                                    }}
                                    style={formComplete ? regular:rpais===""? colored:regular}
                                >
                                    <option value="AUS">Australia</option>
                                    <option value="CAN">Canada</option>                                    
                                    <option value="ESP">España</option>
                                    <option value="USA">Estados Unidos</option>
                                    <option value="FRA">Francia</option>                                   
                                    <option value="JPN">Japón</option>
                                    <option value="MEX">México</option>
                                    <option value="NZL">Nueva Zelanda</option>                                    
                                    <option value="PER">Perú</option>
                                    
                                </select>
                                <label htmlFor="floatingInput">Pais</label>
                        </div>
                        <div className="mb-3 GraphikR" style={{...formComplete?regular:restado===""? colored:regular,borderRadius:"0.25rem"}}>
                            <SelectSearch  
                                options={states} 
                                search 
                                filterOptions={fuzzySearch} 
                                autoComplete="new-password"
                                placeholder="Estado / Departamento" 
                                value={restado}
                                onChange={(value)=>{
                                    setRestado(value)
                                    getStateName(rpais,value)                        
                                }} 
                            />
                        </div>
                        <FloatingLabel label="Ciudad" className='mb-2'>
                            <Form.Control 
                                className='uppercase-input'
                                placeholder="Ciudad" 
                                onChange={e=>{setRciudad(e.target.value)}}
                                style={formComplete? regular:rciudad===""? colored:regular}
                                />
                        </FloatingLabel>
                        <FloatingLabel label="Código Postal" className='mb-2'>
                            <Form.Control 
                                placeholder='Código Postal' 
                                onChange={e=>{setRcodPostal(e.target.value)}} 
                                onKeyPress={(e)=>justnumber(e)}
                                style={formComplete? regular:rcodPostal===""? colored:regular}
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Dirección">
                            <Form.Control
                                className='uppercase-input' 
                                placeholder="Dirección" 
                                onChange={e=>{setRdireccion(e.target.value)}}
                                style={formComplete? regular:rdireccion===""? colored:regular}
                                />
                        </FloatingLabel>
                    </div>
                </div>
            )
        }else return null
    }

    /* -------------------------------------------------------------------------------------- */

  return (
    <div style={{height:"max-content",display:"flex",justifyContent:"center"}}>        
        <div style={{width:"30rem",maxWidth:"95%"}}>
            <InsSteps list={steplist}/>
            <div>
                <div className='serviceTittle'>Tramitación de Documentos</div>
                <hr/>
                <LoginRequired/>
            </div>
            
                <div className="whiteCard">
                    <Form onSubmit={e=>handleSubmit(e)}>
                        <div><h5>¿Qué documento desea solicitar?</h5></div>
                        <div className="form-floating">
                                <select 
                                    className="form-select form-select-md mb-3" 
                                    id="tipotramitec" 
                                    placeholder="Tipo de Trámite Documentario" 
                                    name="tramdoc" 
                                    value={tipoTramDoc}
                                    onChange={(e)=>{
                                        setTipoTramDoc(e.target.value)                                    
                                        setTramEsp("")                                        
                                    }}
                                    style={formComplete ? regular:tipoTramDoc===""? colored:regular}>
                                    <option value="">Seleccionar</option>
                                    <option value="RC">Registros Civiles</option>
                                    {/* <option value="PRP">Partida de Registro Públicos</option> */}
                                    <option value="SCTS">Certificaciones de títulos ante SUNEDU</option>
                                    <option value="O">Otros trámites/consultas</option>
                                </select>
                                <label htmlFor="floatingInput">Tipo de Trámite Documentario</label>
                        </div>
                        {renderSecondCombo()}
                        {tipoTramDoc==="O"?
                        <FloatingLabel label="Datos necesarios para su requerimiento" className='mb-2'>
                            <Form.Control 
                                as={"textarea"}
                                rows={5}
                                maxLength="600"
                                value={textreq}
                                onChange={e=>{
                                    setTextreq(e.target.value)                                    
                                }}
                                placeholder='Datos necesarios para su requerimiento'
                                className='customTextArea'
                                style={formComplete ? regular:textreq===""? colored:regular}
                            />
                            <div style={{fontSize:"0.7rem",color:"gray", textAlign:"end"}}>{`${textreq.length}/600`}</div>                    
                        </FloatingLabel>:null}
                        {tipoTramDoc!==""&&tipoTramDoc!=="O"&&tipoTramDoc!=="PRP"?<>
                        <div><h5>¿De quién requiere el documento?</h5></div>
                        <div className="form-floating" >
                                <select 
                                    className="form-select form-select-md mb-3" 
                                    id="floatingInput" 
                                    placeholder="documento" 
                                    name="rdocumento" 
                                    onChange={(e)=>{
                                        setdocumento(e.target.value)                                        
                                    }} 
                                    value={documento}                                               
                                    style={formComplete ? regular:documento===""? colored:regular} >
                                    <option value="">Seleccionar</option>
                                    <option value="D">DNI</option>
                                    <option value="PS">Pasaporte</option>
                                    <option value="CE">Carnet Extranjero</option>
                                </select>
                                <label htmlFor="floatingInput">Documento</label>
                        </div>
                        <FloatingLabel label= "Documento de Identidad"  className='mb-2'>
                            <Form.Control 
                                name="numeroDoc" 
                                type="text" 
                                placeholder="Documento de Identidad" 
                                value={numerodoc}                                
                                onKeyPress={e=>justnumber(e)}                         
                                onChange={(e)=>{
                                    setNumerodoc(e.target.value)
                                }}                    
                                style={formComplete? regular:numerodoc===""? colored:regular}
                            />        
                        </FloatingLabel>

                        <FloatingLabel label="Nombre" className='mb-2'>
                            <Form.Control 
                                name="nombre"
                                placeholder='Nombre'
                                value={nombre}                                
                                className="uppercase-input"
                                onChange={e=>{
                                    setNombre(e.target.value)
                                }}
                                style={formComplete? regular:nombre===""? colored:regular}
                            />
                        </FloatingLabel>
                        <div style={{display:"flex",justifyContent:"space-between"}} className='mb-2'>
                            <FloatingLabel label="Apellido Paterno" style={{width:"49%"}}>
                                <Form.Control 
                                    name="apellidop" 
                                    placeholder='Apellido Paterno'
                                    value={apellidop}
                                    className="uppercase-input"
                                    onChange={e=>{
                                        setApellidop(e.target.value)
                                    }}
                                    style={formComplete? regular:apellidop===""? colored:regular}
                                />
                            </FloatingLabel>
                            <FloatingLabel label="Apellido Materno" style={{width:"49%"}}>
                                <Form.Control 
                                    name="apellidom" 
                                    placeholder='Apellido Materno'
                                    value={apellidom}
                                    className="uppercase-input"    
                                    onChange={e=>{
                                        setApellidom(e.target.value)
                                    }}
                                    style={formComplete? regular:apellidom===""? colored:regular}
                                />
                                    
                            </FloatingLabel>
                        </div>
                        </>:null}
                        {tipoTramDoc!==""?<>                        
                        <div className="form-floating" >
                                <select 
                                    className="form-select form-select-md mb-3" 
                                    id="remision" 
                                    placeholder="¿Dónde remitiremos el documento?" 
                                    name="remision" 
                                    onChange={(e)=>{
                                        setRemision(e.target.value)                                        
                                    }} 
                                    value={remision}                                               
                                    style={formComplete ? regular:remision===""? colored:regular} >
                                    <option value="T">Tramitec</option>
                                    <option value="O">Otra Dirección</option>                                    
                                </select>
                                <label htmlFor="floatingInput">¿Dónde remitiremos el documento?</label>
                        </div>
                        {renderDireccion()}
                        <div style={{display:"flex"}} className="mt-3 mb-3">
                            <div>
                                <input 
                                    id="chkapostillado" 
                                    type="checkbox"                                
                                    onChange={(e)=>{
                                        if(e.target.checked){
                                            setApostillado("S")
                                        }else{
                                            setApostillado("N")
                                        }
                                        
                                    }}
                                />
                                <label 
                                    htmlFor='chkapostillado' 
                                    style={{
                                        marginLeft:"0.3rem",                                                                    
                                    }}
                                >
                                    ¿Requiere Apostillado?
                                </label>
                            </div>
                            <div
                                className="rounded-circle Amaranth-Italic iconInfo-black"
                                onClick={()=>CustomAlert(undefined,"Apostilla:","Certificación que permite utilizar documentos oficiales en el extranjero")}
                            >i</div>                            
                        </div>                        
                        </>:null}

                        <div style={{textAlign:"center",color:"red",marginTop:"0.2rem"}}>{errormsg}</div>
                    </Form>                    
                </div>
                {remision==="O"?<div className="whiteCard StolzlMedium">
                    Detalle de Precios
                    <div className='redCard'>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"0.6rem"}}><div>Costo de Envio:</div><div className="StolzlMedium">{`${precioCourrier} ${monedaCourrier}`}</div></div>
                    </div>                                    
                </div>:null}
                <div style={{display:"flex",justifyContent:"center"}}>
                    <button className='btn green-button' disabled={!loged} onClick={()=>revisar()}>Enviar Solicitud</button>
                </div>             
        </div>    
    </div>
  )
}
