import React,{useState,useEffect,useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {Alert} from "react-bootstrap";
import Axios from 'axios'

//Components
import MiniBlogEntry from './blog/miniBlogEntry'

//Utils
import {getUrlApi} from '../Utils/getUrl&Key'

//Samples
import searchInWeb from '../samples/searchWeb.json'



export default function NavBuscar({setShowSearch}) {    
    const history = useHistory()
    const [blogs, setBlogs] = useState([]);
    const [searchActive,setSearchActive] = useState(1)

    const [input, setInput] = useState("");    
    const [blogFound, setBlogFound] = useState([]);
    const [webFound, setWebFound] = useState([]);

    const inputref = useRef()

    useEffect(() => {
      inputref.current.focus()
      getListBlogs()      
    }, []);

    async function getListBlogs(){
      const Url = getUrlApi() + "/blog/listado"
      await Axios.get(Url).then(res=>{            
          setBlogs(res.data.respuesta2.filter(blog=>blog.estado!=="NV"))          
      })
    }


    function handleSearch(){
        const keywords = input.split(" ")
        setWebFound([])
        searchInWeb.forEach(page=>{          
          let contador = 0
          keywords.forEach(key=>{
            if(page.claves.filter(item=>item.includes(keywords[0])).length>0||page.titulo.toLowerCase().includes(key.toLowerCase())){
              contador += 1
            }
          })
            
          if(contador > 0){
            setWebFound((prevLog)=>[...prevLog,page])
          }
        })                
    }

    function handleSearchBlog(){
      const keywords = input.split(" ")
      setBlogFound([])
      blogs.forEach(blog=>{
          let contador = 0
            
          keywords.forEach(key=>{                              
          if(blog.pclaves.split(",").includes(key.toLowerCase())||blog.categoria.toLowerCase().includes(key.toLowerCase())||blog.titulo.toLowerCase().includes(key.toLowerCase())||blog.autor.toLowerCase().includes(key.toLowerCase())){
              contador += 1
          }
          })
            
          if(contador > 0){
              setBlogFound((prevLog)=>[...prevLog,blog])              
          }
      })                
  }

  function handleTriggerSearch(){    
    if(searchActive===1){
      handleSearch()
    }else if(searchActive===2){
      handleSearchBlog()
    }
  }
    
  return (
    <div className="position-fixed" style={{width:"100%",height:"calc(100vh - 3.675rem)",background:"rgba(100,100,100,.4)",zIndex:"5"}}>
      <div className=" d-flex  justify-content-center mt-2">
          <Alert variant="light" onClose={()=>setShowSearch(false)} dismissible className='searchPage customScroll'>
            <Alert.Heading>                
                  <input
                    id="searchInput"
                    ref={inputref} 
                    type="text" 
                    className="form-control" 
                    value={input}
                    onChange={e=>{
                        setInput(e.target.value)
                        handleTriggerSearch()
                    }}                    
                    placeholder="Buscar"                     
                    />
            </Alert.Heading>
            <div className='d-flex justify-content-center'>
              {
                Array.from(["WEB"],(section,index)=>(
                    <div 
                      key={`sect-${index}`}
                      className={index+1===searchActive?"searchSection-Active":"searchSection"}
                      onClick={()=>{
                        setSearchActive(index+1)
                        handleTriggerSearch()
                      }}
                    >
                      {section}
                    </div>
                ))
              }
            </div>
            {searchActive==1?
            input===""?
            <div className='m-2' style={{color:"gray"}}>Encuentre servicios u otras paginas en nuestro sitio web.</div>:
            <div>{webFound.map((page,index)=>{
              return(
                <div key={'webEntry'+index} className='webEntry' onClick={()=>{
                    page.state? history.push({pathname:page.link,state:page.state}):history.push(page.link)
                    setShowSearch(false)
                  }}>
                  <h4>{page.titulo}</h4>
                  <p>{page.resumen}</p>
                </div>
                
              )
            })
            }</div>    
            :
            input===""?
              <div className='m-2' style={{color:"gray"}}>Introduzca un término de búsqueda para encontrar un artículo de su interés.</div>:
              <div className='mt-2'>{blogFound.map(blog=>{
                  return(
                    <div key={`blog-${blog.id}`} className='d-flex justify-content-center'  onClick={()=>setShowSearch(false)}>                    
                        <MiniBlogEntry blog={blog} onSearch={true} />
                    </div>
                  )}
                )}
              </div>    
            }            
          </Alert>
      </div>        
  </div>
  )
}
