import React,{useEffect} from 'react';
import KRGlue from '@lyracom/embedded-form-glue';
import Axios from 'axios'
import {useLocation} from 'react-router-dom'
//Assets
import Logo from './../../Assets/Logos/ColorfulLogo.svg';
import IziLogo from './../../Assets/Logos/izipaylogo.png';

//Utils
import {getIziKey,getUrlApi} from '../../Utils/getUrl&Key'

export default function PaymentIzy({formToken,handleComplete,loading,moneda}) {
    
    const location = useLocation()
    const paymentFormData = JSON.parse(localStorage.getItem("App.payment"))
    const ubicacion = sessionStorage.getItem("App.userLocation")
    
    useEffect(()=>{     
         const timeOutLoad = setTimeout(()=>onLoadError(),20000)
         loadLibrary(timeOutLoad)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]) 
  

    function loadLibrary(timeOut){        
        KRGlue.loadLibrary("https://api.micuentaweb.pe",getIziKey())            
            .then(
                ({KR})=>KR.setFormConfig({formToken,'kr-language':'es-pe'})
                )            
            .then(
                ({KR})=>KR.onSubmit(
                    res=>{                    
                        const paymentMethodToken = res.clientAnswer.transactions[0].paymentMethodToken
                        const card = res.clientAnswer.transactions[0].transactionDetails.cardDetails.pan
                                
                        if(handleComplete){                        
                            handleComplete(card,paymentMethodToken)
                        }
                    })                        
                )
            .then(
                ({KR})=>KR.onError(
                    async res=>{
                        const url = getUrlApi() + "/common/pagoerror"                    
                        try {
                            await Axios.post(url,{...res,...location.state,...paymentFormData},{headers:{ubicacion}})
                        } catch (error) {
                            console.log(error)
                        }
                })
            )
            .then(({KR})=>KR.onFormCreated(()=>{
                loading("",true)
                clearTimeout(timeOut)
            })
            )        
            .then(({KR})=>KR.closePopin())
            
            .then(({KR})=>{                
                !KR.ready && console.log("Fallo al comunicarse con la pasarela de pago")                
            }) 
    }

    function onLoadError(){
        alert("Fallo al comunicarse con la pasarela de pago, de clic en aceptar para internar nuevamente")
        window.location.reload()
    }
    
    return (        
        <div>
            {/* ---payment form--- */}
            
            <div className="kr-embedded"
                kr-popin="true"                                                                                                        
                /* kr-form-token = {formToken}   */             
            >                   
                {/* ---payment form fields--- */}
                <div style={{position:"absolute",top:"2rem",left:"calc(50% - 6.5rem)"}}><img src={Logo} alt="logo" style={{width:"13rem"}}></img></div>                

                <div>
                    <div className="kr-pan"></div>
                </div>

                <div style={{display:"flex"}}>
                    <div className="kr-expiry"></div>
                    <div className="kr-security-code"></div>
                </div>

                <div style={{display:"none"}}>
                    <div className='kr-first-installment-delay'></div>
                </div>
                <div style={{width:"100%"}}>
                    <div className='kr-installment-number'></div>
                </div>
                {/* ---payment form register checkbox--- */}        
                <div style={{display:"none"}}>
                    <input  className='kr-do-register'></input>
                </div>
                
                {/* ---payment form submit button--- */}
                <div style={{width:"100%"}}>
                <button className="kr-payment-button"></button>
                </div>

                {/* ---error zone--- */}
                <div style={{width:"100%"}}>
                    <div className="kr-form-error"></div>
                </div>

                {/*---Footer--*/}
                <div style={{width:"100%", height:"2.8rem"}}>
                <div 
                    style={{
                        position:"absolute",
                        bottom: "0",
                        left:"0",                                                
                        display:"flex",
                        justifyContent:"center",
                        backgroundColor:"var(--iziRed-Color)",
                        padding:"0.3rem 0",
                        borderRadius:"0 0 0.9rem 0.9rem",
                        width:"100%"
                        }}
                >
                    <div style={{display:"grid", marginRight:"0.7rem", color:"white"}}>
                        <div className='poweredText'>Powered</div>
                        <div className='poweredText' style={{textAlign:"end"}}>by</div>
                    </div>
                    <div><img src={IziLogo} alt="Logo Izipay" style={{height:"2rem"}}/></div>
                </div>
                </div>               
            </div>                     
        </div>       
    )
}
