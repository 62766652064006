import React,{useState} from 'react'
import {FloatingLabel, Form} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import Axios from 'axios'
//Assets
import Logo from "../Assets/Logos/ColorfulLogo.svg"
import Patterm from '../Assets/BackgroundImages/WhitePattermLarge.svg'

//Utils
import {checkmail} from '../Utils/checkmail'
import encript from '../Utils/encript'
import {getUrlApi} from '../Utils/getUrl&Key'


const SESSION_KEY = "App.login" 
export default function UserRegistration() {
    const history = useHistory()

    const [user,setUser] = useState("");
    const [pswd,setPswd] = useState ("");
    const [pswdCon,setPswdCon] = useState("");
    

    

    const handleSubmit = e => {
        e.preventDefault();       
    }

    async function registerMe(){        
        if (user===""||pswd===""||pswdCon===""){            
            alert("Complete todos los datos")            
        }
        else {
            if(checkmail(user)){    
                if (pswdCon === pswd){
                    if(pswd.length>7){
                        const Url = getUrlApi() + "/users/"
                        try{
                            await Axios.post(Url,{"username":user,"password":encript(pswd)})
                            .then(res=>{
                                if(res.status===201){
                                    const {id,name,token,username} = res.data
                                    localStorage.setItem(SESSION_KEY,JSON.stringify({name:name, username:username,id:id,token:token,googleAcc:false}))                                    
                                    alert(res.data.message)
                                    setTimeout(()=>{history.push({pathname: "/"})
                                    },1000)
                                }else if(res.status===500){                            
                                    alert(res.data.message)
                                }
                            })
                        }
                        catch(error){                                                        
                            alert(error.response.data.message)        
                        }
                    }
                    else{
                        alert("La contraseña debe terner minimo 8 caracteres")
                    }
                }
                else{                    
                    alert("Ambas contraseñas deben Coincidir")
                }
            }
            else{
                alert("Formato de Correo no Valido")
            }
        }
    }
    const volver = "Volver"
    return (
        <div className="outerContainer">
            <div style={{
                position:"fixed",
                top:"0",
                right: "0",
                zIndex: "1"}}
                className = "fade-in"
            >
                <img 
                    src={Patterm}
                    alt="background_patter"                    
                    style={{
                        height:"100vh",
                        opacity:"0.4"
                    }}
                ></img>
            </div>            
            <div className="shadow rounded-1_5rem loginContainer fade-in">                
            <Form onSubmit={handleSubmit}>
                    <div style={{textAlign:"center",cursor:"pointer"}} className="mb-3">
                        <img className="loginLogo" src={Logo} alt="logo" onClick={()=>history.push("/")}></img>
                    </div>                    
                    <FloatingLabel label="Correo" className="mb-3">
                        <Form.Control name="user" type="email" placeholder="Correo" onChange={(e)=>setUser(e.target.value)}/>
                    </FloatingLabel>
                    <FloatingLabel label="Contraseña" className="mb-2">
                        <Form.Control name="pswd" type="password" placeholder= "Contraseña" onChange={(e)=>setPswd(e.target.value)}/>
                    </FloatingLabel>
                    <FloatingLabel label="Confirme Contraseña" className="mb-3">
                        <Form.Control name="pswd" type="password" placeholder= "Confirmar Contraseña" onChange={(e)=>setPswdCon(e.target.value)}/>
                    </FloatingLabel>

                    <div>                    
                    <button
                        className="btn red-button GraphikSB mb-2"
                        style={{width:"100%"}}
                        onClick={()=>registerMe()}
                    >Crear Cuenta</button>                    
                    
                    <div style={{cursor:"pointer",marginTop:"3.7rem"}} onClick={()=>history.push({pathname:"/login",state:{step:1}})}>{volver}</div>
                    </div>                    
                                        
            </Form>
            </div>
        </div>
    )
}
