import React,{useState,useEffect} from 'react'
import {MdModeEditOutline} from 'react-icons/md'
import getStateName from '../../../../Utils/getStateName'

//para eliminar del css => opodresCardData  opodresUpdateModal
export default function ReviewCardInfo({personType, data,callback=()=>{},noEdit=false}) {

    const [isActive, setIsActive] = useState(false)
    const [docnumero, setDocnumero] = useState(data.docnumero)
    const [ciudad, setCiudad] = useState(data.ciudad)
    const [direccion, setDireccion] = useState(data.direccion)
    const [codigopostal, setCodigopostal] = useState(data.codigopostal)
    const [email, setEmail] = useState(data.email)
    const [telefono, setTelefono] = useState(data.telefono)
    const [estadocivil, setEstadocivil] = useState(data.estadocivil)
    const [ocupacion, setOcupacion] = useState(data.ocupacion)
    const [nombre, setNombre] = useState(data.nombre)
    const [apellidop, setApellidop] = useState(data.apellidop)
    const [apellidom, setApellidom] = useState(data.apellidom)
    const [estado, setEstado] = useState("")

    useEffect(()=>{
        getStateName(data.pais,data.estado).then(result=>{
            setEstado(result.toUpperCase())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function phonenumber(event){    
        if (Number.isNaN(parseInt(event.key))){
          event.preventDefault()
        }
    }
    
    function displayEstCivil (estado){
        switch (estado) {
            case "07":
                return "SOLTERO(A)"
                
            case "08":
                return "CASADO(A)"
                
            case "09":
                return "VIUDO(A)"
                
            case "10":
                return "DIVORCIADO(A)"
            default:
                return "Desconocido"    
        }
    }

    function displayDocumento (doc){
        switch (doc){
            case "D":
                return "DNI"
            case "CE":
                return "C. Extrangeria"
            case "PS":
                return "Pasaporte"
            case "R":
                return "RUC"
        }
    }

        
    function handleSave(person){
        
        let updateData = {docnumero,nombre,apellidop,apellidom,ciudad,direccion,codigopostal,email,telefono,estadocivil,ocupacion}
            Object.keys(updateData).forEach(key=>{
                if(!updateData[key]||updateData[key]===""){
                    delete updateData[key]
                }
            })
        
        setIsActive(false)        
        callback(data.documento,data.docnumero,person,updateData)
    }
  
  if (personType==="POD"){
  return (
    <div>
        {!noEdit?<div style={{display:"flex", justifyContent:"flex-end"}}>
            <div className='edit-button' onClick={()=>setIsActive(true)}><MdModeEditOutline/></div>
        </div>:null}
        <div >
        {/* --------------------------------------- */}
            
                <div key={data.docnumero}>
                    {isActive?<>
                        <div  className='opodresCardData'>
                            <div>Nombres:</div>
                            <input 
                                value={nombre}
                                className="uppercase-input" 
                                onChange={e=>setNombre(e.target.value)}/>
                        </div>
                        <div  className='opodresCardData'>
                            <div>Apellidos:</div>
                            <input 
                                style={{width:"7.5rem"}} 
                                placeholder='Apellido Paterno' 
                                value={apellidop}
                                className="uppercase-input" 
                                onChange={e=>setApellidop(e.target.value)}/>-
                            <input 
                                style={{width:"7.5rem"}} 
                                placeholder='Apellido Materno' 
                                value={apellidom}
                                className="uppercase-input" 
                                onChange={e=>setApellidom(e.target.value)}/>                                                        
                        </div>    
                    </>:""}                    
                    <div  className='opodresCardData'>
                        <div>Documento:</div>
                        <div>{displayDocumento(data.documento)}-{isActive?<input value={docnumero} onKeyPress={e=>phonenumber(e)} onChange={e=>setDocnumero(e.target.value)}></input>:data.docnumero}</div>
                        
                    </div>
                    <div  className='opodresCardData'>
                        <div>Ubicación:</div>
                        <div>{data.pais} - {estado}, {isActive?<input value={ciudad} className="uppercase-input" onChange={e=>setCiudad(e.target.value)} placeholder='Ciudad'></input>:data.ciudad}</div>
                    </div>
                    <div  className='opodresCardData'>
                        <div>Dirección:</div>
                        <div>{isActive?<input placeholder='Dirección' value={direccion} className="uppercase-input" onChange={e=>setDireccion(e.target.value)}></input>:data.direccion}</div>
                    </div>
                    <div  className='opodresCardData'>
                        <div>Código Postal:</div>
                        <div> {isActive?<input placeholder='Código Postal' value={codigopostal} className="uppercase-input" onChange={e=>setCodigopostal(e.target.value)}></input>:data.codigopostal}</div>
                    </div>
                    <div  className='opodresCardData'>
                        <div>Email:</div>
                        <div> {isActive?<input placeholder='Email' value={email} className="uppercase-input" onChange={e=>setEmail(e.target.value)}></input>:data.email}</div></div>
                    <div  className='opodresCardData'>
                        <div>Teléfono:</div>
                        <div> +{isActive?<input placeholder='Telefono' value={telefono} onKeyPress={e=>phonenumber(e)} onChange={e=>setTelefono(e.target.value)}/>: data.telefono}</div>
                    </div>
                    <div  className='opodresCardData'>
                        <div>Estado Civil:</div>
                        <div> {isActive?<select value={estadocivil} onChange={e=>setEstadocivil(e.target.value)}>
                            <option value="07">SOLTERO(A)</option>
                            <option value="08">CASADO(A)</option>
                            <option value="09">VIUDO(A)</option>
                            <option value="10">DIVORCIADO(A)</option>
                        </select>:displayEstCivil(data.estadocivil)}</div>
                    </div>
                    <div className='opodresCardData'>
                        <div>Ocupación:</div>
                        <div> {isActive?<input placeholder='Ocupación' value={ocupacion} className="uppercase-input" onChange={e=>setOcupacion(e.target.value)}></input>:data.ocupacion}</div>
                    </div>       
                </div>
            
        {/*--------------------------------------------*/}
        </div>
        {isActive?
        <div style={{display:"flex",justifyContent:"space-around",marginTop:"0.5rem"}}>
            <button className='btn green-button' onClick={()=>handleSave(personType)}>Guardar</button>
            <button className='btn red-button' onClick={()=>setIsActive(false)}>Cancelar</button>
        </div>:""}
    </div>
  )}
  else{
      return(
          <div>
            {!noEdit?<div style={{display:"flex", justifyContent:"flex-end"}}>
                <div className='edit-button' onClick={()=>setIsActive(true)}><MdModeEditOutline/></div>
            </div>:null}
                {isActive?<>
                        <div  className='opodresCardData'>
                            <div>Nombres:</div>
                            <input 
                                value={nombre}
                                className="uppercase-input" 
                                onChange={e=>setNombre(e.target.value)}/>
                        </div>
                        <div  className='opodresCardData'>
                            <div>Apellidos:</div>
                            <input 
                                style={{width:"7.5rem"}} 
                                placeholder='Apellido Paterno' 
                                value={apellidop}
                                className="uppercase-input" 
                                onChange={e=>setApellidop(e.target.value)}/>-
                            <input 
                                style={{width:"7.5rem"}} 
                                placeholder='Apellido Materno' 
                                value={apellidom}
                                className="uppercase-input" 
                                onChange={e=>setApellidom(e.target.value)}/>                                                        
                        </div>    
                </>:""}             
                <div  className='opodresCardData'>
                    
                    <div>Documento:</div><div>{displayDocumento(data.documento)}-{isActive?<input placeholder='DNI' value={docnumero} onKeyPress={e=>phonenumber(e)} onChange={e=>setDocnumero(e.target.value)}></input>:data.docnumero}</div>                    
                </div>
                {isActive?<div style={{display:"flex",justifyContent:"space-around",marginTop:"0.5rem"}}>
                    <button className='btn green-button' onClick={()=>handleSave(personType)}>Guardar</button>
                    <button className='btn red-button' onClick={()=>setIsActive(false)}>Cancelar</button>
                </div>:""}              
          </div>
      )
  }
}