import React from 'react'
import {Card} from 'react-bootstrap'

//Icons
import {MdPictureAsPdf} from 'react-icons/md'
import {BsFileEarmarkWordFill} from 'react-icons/bs'

export default function HistorialDownload({id,servicio,estado,ubicacion,handleDownload}) {
    
  return (
    <Card.Body className='p-0'>                                
        <div style={{border:" 3px solid var(--bs-gray-100)"}}>
            <div style={{textAlign:"center",background:"var(--bs-gray-100)",color:"black"}}>
                <span>Descargas</span>
            </div>
            <div style={{display:"flex",justifyContent:"space-around",padding:"0.5rem"}}>
                {(estado!=='R'&&servicio==="P")?<>
                <div style={{marginRight:"1rem"}}>
                    <BsFileEarmarkWordFill
                    className='downloadword'
                    onClick={()=>handleDownload(servicio,id,"PWORD")}
                    />
                    <MdPictureAsPdf
                    className='downloadpdf'
                    onClick={()=>handleDownload(servicio,id,"PPDF")}
                    />
                    <div style={{textAlign:"center",fontSize:"0.7rem"}}>Poder</div>
                </div>
                {ubicacion==="USD"?<div style={{textAlign:"center",marginRight:"1rem"}}>
                    <BsFileEarmarkWordFill
                        style={{marginBottom:"0.5rem"}}
                        className='downloadword'
                        onClick={()=>handleDownload(servicio,id,"PCARTA")}
                    />
                    <div style={{fontSize:"0.7rem"}}>Instrucción</div>
                </div>:null}
                </>:null}

                {estado==="F"?
                <div style={{textAlign:"center"}}>
                    <MdPictureAsPdf
                        className='downloadpdf'
                        onClick={()=>handleDownload(servicio,id,"IPDF")}
                    />
                    <div style={{fontSize:"0.7rem"}}>Inscripción</div>
                </div>
                :null}
            </div>
        </div>                               
    </Card.Body>
  )
}
