import React from 'react'
import {useHistory} from 'react-router-dom'

export default function LoginRequired() {
    const history = useHistory()    
    const user = JSON.parse(localStorage.getItem("App.login"))

    if(!user){
        return (    
            <div 
                className='redCard text-center kenburns user-select-none' 
                onClick={()=>{history.push(`/login/r==${btoa(history.location.pathname)}`)}}
            >
                <strong>Inicie sesión para usar este servicio</strong>
            </div>
            
        )
    }else{
        return(
            <></>
        )
    }
}
